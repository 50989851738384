import { FetchResult, QueryResult } from '@apollo/client';

export enum KickOffStatus {
  STARTED = 'Iniciado',
  INFORMATION_REGISTRATION_PENDING = 'Registro de Informações Pendente',
  INFORMATION_REGISTRATION_CONCLUDED = 'Registro de Informações Concluído',
  DIAGNOSTIC_QUIZ_RELEASED = 'Questionário Diagnóstico Liberado',
  DIAGNOSTIC_QUIZ_BEING_COMPLETED = 'Questionário Diagnóstico em Preenchimento',
  DIAGNOSTIC_QUIZ_SENT = 'Questionário Diagnóstico Submetido',
}

export enum KickOffProfileType {
  CONSERVATIVE_PLUS = 'Conservador+',
  CONSERVATIVE = 'Conservador',
  MODERATE = 'Moderato',
  BOLD = 'Arrojado',
  BOLD_PLUS = 'Arrojado+',
}

export enum KickOffManagementType {
  SIMPLIFIED = 'Simplificado',
  SOPHISTICATED = 'Sofisticado',
}

export enum KickOffProductType {
  FIXED_PRICE = 'Preço Fixo',
  GUARANTEED_DISCOUNT = 'Desconto Garantido',
}

export enum KickOffTraderType {
  RETAIL_TRADER = 'Varejista',
  WHOLESALE_TRADER = 'Atacadista',
}

export enum KickOffWarrantyType {
  REGISTRATION_AGAINST_PAYMENT = 'Registro Contra Pagamento',
  WARRANTY_INSURANCE = 'Seguro Garantia',
}

export enum KickOffQuizStatus {
  INACTIVE = 'Inativo',
  ACTIVE = 'Ativo',
}

export interface IKickOffSuggestedProduct {
  productType: keyof typeof KickOffProductType;
  traderType: keyof typeof KickOffTraderType;
  warrantyType: keyof typeof KickOffWarrantyType;
  upperFlexibility?: number;
  lowerFlexibility?: number;
  contractTermInYears?: number;
  averageConsumptionInMwm?: number;
}

export interface IKickOffDiagnosis {
  profileType?: keyof typeof KickOffProfileType;
  managementType?: keyof typeof KickOffManagementType;
  suggestedProduct?: IKickOffSuggestedProduct;
}

export interface IProjectedPercentageChangeInConsumptionPerYear {
  '2023'?: number;
  '2024'?: number;
  '2025'?: number;
}
export interface IKickOffQuizInputs {
  profileType?: keyof typeof KickOffProfileType;
  managementType?: keyof typeof KickOffManagementType;
  projectedPercentageChangeInConsumptionPerYear?: IProjectedPercentageChangeInConsumptionPerYear;
  iRecCertification?: boolean | string;
}

export interface IKickOffQuiz {
  status: keyof typeof KickOffQuizStatus;
  inputs?: IKickOffQuizInputs;
  link?: string;
  submittedAt?: string;
  emailSentAt?: string;
}

export interface IKickOffCommercialGroup {
  id: string;
  name: string;
}

export interface IKickOffUnitSummary {
  name?: string;
  averageEnergyBill?: number;
  averageConsumptionInMwm?: number;
  higherContractedDemandInKw?: number;
  edcId?: number;
  tariffModality?: string;
  tariffSubgroup?: string;
}

export interface IKickOff {
  id: string;
  status: keyof typeof KickOffStatus;
  diagnosis?: IKickOffDiagnosis;
  quiz?: IKickOffQuiz;
  commercialGroup?: IKickOffCommercialGroup;
  unitsSummary?: Array<IKickOffUnitSummary>;
  createdAt: string;
}

export interface IGetKickOffByGroupIDQueryData {
  getKickOffByGroupID: IKickOff;
}

export interface IGetKickOffByGroupIDQueryVariables {
  groupId?: string;
}

export interface ISaveKickOffQuizInputsMutationData {
  saveKickOffQuizInputs: boolean;
}

export interface ISaveKickOffQuizInputsMutationVariables {
  kickOffId: string;
  quizInputs: IKickOffQuizInputs;
}

export interface ISubmitKickOffQuizMutationData {
  submitKickOffQuiz: boolean;
}

export interface ISubmitKickOffQuizMutationVariables {
  kickOffId: string;
}

export interface ICreateKickOffDiagnosisMutationData {
  createKickOffDiagnosis: IKickOff;
}

export interface ICreateKickOffDiagnosisMutationVariables {
  kickOffId: string;
  quizInputs: IKickOffQuizInputs;
}

export interface IUpdateKickOffDiagnosisMutationData {
  updateKickOffDiagnosis: IKickOff;
}

export interface IUpdateKickOffDiagnosisMutationVariables {
  kickOffId: string;
  diagnosisData: IKickOffDiagnosis;
}

export type KickOffFlowSection = 'WELCOME' | 'QUIZ' | 'DIAGNOSIS_PREVIEW' | 'DIAGNOSIS_RESULT';

export interface IKickOffContext {
  kickOff?: IKickOff;
  loading: boolean;
  flowSection: KickOffFlowSection;
  setFlowSection: React.Dispatch<React.SetStateAction<KickOffFlowSection>>;
  setGroupId: React.Dispatch<React.SetStateAction<string | undefined>>;
  getKickOffByGroupIDHandler: () => Promise<
    QueryResult<IGetKickOffByGroupIDQueryData, IGetKickOffByGroupIDQueryVariables>
  >;
  saveKickOffQuizInputsHandler: (
    quizInputs: IKickOffQuizInputs,
  ) => Promise<FetchResult<ISaveKickOffQuizInputsMutationData>>;
  submitKickOffQuizHandler: () => Promise<FetchResult<ISubmitKickOffQuizMutationData>>;
  createKickOffDiagnosisHandler: (
    quizInputs: IKickOffQuizInputs,
  ) => Promise<FetchResult<ICreateKickOffDiagnosisMutationData>>;
  updateKickOffDiagnosisHandler: (
    diagnosisData: IKickOffDiagnosis,
  ) => Promise<FetchResult<IUpdateKickOffDiagnosisMutationData>>;
}
