import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ScreenLayout from '@components/templates/screen-layout';
import PrivateRoute from './private-route';

import {
  HOME_PATH,
  MIGRATION_REPORT_PATH,
  FILES_PATH,
  ATTENDANCE_PATH,
  NOTIFICATION_PATH,
  KICK_OFF_PATH,
  ACCUMULATED_ECONOMY_REPORTS_PATH,
  MONTHLY_ECONOMY_REPORTS_PATH,
  CONSUMPTION_DASHBOARD_PATH,
  ENERGY_PRICE,
  SUSTAINABILITY_PATH,
} from './constants';

import { GroupEconomyProvider } from '@contexts/group-economy';
import { UnitEconomyProvider } from '@contexts/units-economy';
import { NotificationsProvider } from '@contexts/notifications';
import { FilesProvider } from '@contexts/files';
import { MigrationReportProvider } from '@contexts/migration-report';
import CompilationResultProvider from '@contexts/compilation-results';

import NotFound404 from '@pages/404';
import HomePage from '@pages/home/index';
import DocumentsHub from '@pages/documents/documents-hub';
import UnitMigrationReportsHub from '@pages/migration-reports/unit-migration-report-hub';
import UnitMigrationReport from '@pages/migration-reports/unit-migration-report';
import UnitEconomyReport from '@pages/economy-reports/monthly-economy-report';
import AccumulatedEconomyReportPage from '@pages/economy-reports/accumulated-economy-report';
import KickOffHub from '@pages/kick-off/kick-off-hub';
import EnergyPricePage from '@pages/energy-price';

import AttendanceRoute from './attendance-route';
import KickOffProvider from '@contexts/kick-off';
import MonthlyEconomyReportPage from '@pages/economy-reports/monthly-economy-report';
import ConsumptionProvider from '@contexts/consumption';
import ConsumptionHub from '@pages/consumption/consumption-hub';
import SustainabilityHub from '@pages/sustainability/sustainability-hub';
import { UserInfoProvider } from '@contexts/users';

const MainRouter: React.FC = () => {
  return (
    <Routes>
      {/* ----------- Private routes ----------- */}
      <Route
        element={
          <PrivateRoute>
            <GroupEconomyProvider>
              <UserInfoProvider>
                <NotificationsProvider>
                  <ScreenLayout />
                </NotificationsProvider>
              </UserInfoProvider>
            </GroupEconomyProvider>
          </PrivateRoute>
        }
      >
        <Route
          path={HOME_PATH}
          element={
            <MigrationReportProvider>
              <GroupEconomyProvider>
                <HomePage />
              </GroupEconomyProvider>
            </MigrationReportProvider>
          }
        />
        <Route
          path={`${ACCUMULATED_ECONOMY_REPORTS_PATH}/:id`}
          element={
            <MigrationReportProvider>
              <GroupEconomyProvider>
                <UnitEconomyProvider>
                  <AccumulatedEconomyReportPage />
                </UnitEconomyProvider>
              </GroupEconomyProvider>
            </MigrationReportProvider>
          }
        />
        <Route
          path={`${MONTHLY_ECONOMY_REPORTS_PATH}/:id/:period`}
          element={
            <MigrationReportProvider>
              <GroupEconomyProvider>
                <UnitEconomyProvider>
                  <MonthlyEconomyReportPage />
                </UnitEconomyProvider>
              </GroupEconomyProvider>
            </MigrationReportProvider>
          }
        />
        <Route
          path={MIGRATION_REPORT_PATH}
          element={
            <MigrationReportProvider>
              <UnitEconomyProvider>
                <UnitMigrationReportsHub />
              </UnitEconomyProvider>
            </MigrationReportProvider>
          }
        />
        <Route
          path={`${MIGRATION_REPORT_PATH}/:unitReportId`}
          element={
            <MigrationReportProvider>
              <UnitMigrationReport />
            </MigrationReportProvider>
          }
        />
        <Route
          path={`${SUSTAINABILITY_PATH}/:id`}
          element={
            <GroupEconomyProvider>
              <UnitEconomyProvider>
                <SustainabilityHub />
              </UnitEconomyProvider>
            </GroupEconomyProvider>
          }
        />
        <Route
          path={CONSUMPTION_DASHBOARD_PATH}
          element={
            <ConsumptionProvider>
              <ConsumptionHub />
            </ConsumptionProvider>
          }
        />
        <Route
          path={FILES_PATH}
          element={
            <FilesProvider>
              <DocumentsHub />
            </FilesProvider>
          }
        />
        <Route
          path={NOTIFICATION_PATH}
          element={
            <UnitEconomyProvider>
              <UnitEconomyReport />
            </UnitEconomyProvider>
          }
        />
        <Route path={ATTENDANCE_PATH} element={<AttendanceRoute />} />

        <Route
          path={ENERGY_PRICE}
          element={
            <CompilationResultProvider>
              <EnergyPricePage />
            </CompilationResultProvider>
          }
        />
      </Route>
      <Route
        path={`${KICK_OFF_PATH}/:groupId`}
        element={
          <KickOffProvider>
            <KickOffHub />
          </KickOffProvider>
        }
      />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

export default MainRouter;
