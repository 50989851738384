import React from 'react';
import { useNavigate } from 'react-router';

interface CardProps {
  icon?: string;
  label: string;
  description: string;
  path: string;
  onCardClick: () => void;
}

const CardWithDescription: React.FC<CardProps> = ({ icon, label, description, path, onCardClick }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    onCardClick();
    navigate(path);
  };

  return (
    <div
      className="w-full md:h-[13rem] flex flex-col border-black bg-white px-6 py-6 rounded-medium shadow-3 cursor-pointer hover:bg-[#F2F2F2] focus:border-2 focus:border-primary-60 focus:border"
      onClick={handleCardClick}
    >
      <div className="mb-5 md:mb-6 w-[4rem]">
        <img src={icon} />
      </div>
      <div>
        <h1 className="mb-4 w-full text-xl font-medium text-left md:text-2xl">{label}</h1>
        <p className="w-6/7">{description}</p>
      </div>
    </div>
  );
};

export default CardWithDescription;
