import { UserInfoContext } from '@contexts/users';
import { IUserContext } from '@contexts/users/types';
import { useContextSelector } from 'use-context-selector';

export const useUserInfo = (): IUserContext => {
  const getUser = useContextSelector(UserInfoContext, (userInfo) => userInfo.getUser);
  const isFetchingUser = useContextSelector(UserInfoContext, (userInfo) => userInfo.isFetchingUser);
  const isUserLoaded = useContextSelector(UserInfoContext, (userInfo) => userInfo.isUserLoaded);
  const user = useContextSelector(UserInfoContext, (userInfo) => userInfo.user);
  const upsertGroupRelationWithUserHandler = useContextSelector(
    UserInfoContext,
    (userInfo) => userInfo.upsertGroupRelationWithUserHandler,
  );
  const isUpsertRelationSuccess = useContextSelector(UserInfoContext, (userInfo) => userInfo.isUpsertRelationSuccess);
  const groupsByUser = useContextSelector(UserInfoContext, (userInfo) => userInfo.groupsByUser);

  return {
    getUser,
    isFetchingUser,
    isUserLoaded,
    user,
    upsertGroupRelationWithUserHandler,
    isUpsertRelationSuccess,
    groupsByUser,
  };
};
