import { parseStringToDate } from '@utils/dayjs';
import { Unpacked } from '@utils/types';
import { DocumentTypeEnum, GuaranteeTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';

import {
  BidSavingsGraphQLResponse,
  BidWithSavings,
  GroupReport,
  InputPrice,
  Savings,
  SavingsCalculationResult,
  SavingsCalculationResultInput,
  TraderLogo,
  UnitReport,
} from './types';
import { formatDate } from '@utils/dayjs';

export const findProposalWithSavings = (proposals: Array<BidSavingsGraphQLResponse['proposal']>) => {
  return proposals.find(({ bids }) => bids.find(({ savings }) => savings.status === 'COMPLETED'));
};

export const formatProposal = (proposal: BidSavingsGraphQLResponse['proposal']) => {
  const startDate = parseStringToDate(proposal.createdAt);

  return {
    id: proposal.id,
    round: proposal.round,
    guaranteeType:
      proposal.guaranteeType === null ? 'NO_GUARANTEE' : (proposal.guaranteeType as keyof typeof GuaranteeTypeEnum),
    periods: [...proposal.periods],

    lowerFlexibility: proposal.lowerFlexibility,
    upperFlexibility: proposal.upperFlexibility,
    commissionModality: proposal.commissionModality,
    startDate: formatDate(startDate, 'DD/MM/YYYY'),
    units: proposal.supply.map(({ unit }) => ({ id: unit.id, name: unit.name })),
  };
};

export const formatBids = (
  bids: BidSavingsGraphQLResponse['proposal']['bids'],
  proposalType: keyof typeof ProposalTypeEnum,
): BidWithSavings[] => {
  return bids.map((rawBid) => {
    const {
      id,
      deadline,
      lowerFlexibility,
      upperFlexibility,
      guaranteeType,
      contractType,
      trader,
      periods,
      economy,
      retailService,
      coverCceeTaxes,
    } = rawBid;
    return {
      id,
      deadline: parseStringToDate(deadline),
      lowerFlexibility,
      upperFlexibility,
      proposalType,
      guaranteeType: guaranteeType === null ? 'NO_GUARANTEE' : (guaranteeType as keyof typeof GuaranteeTypeEnum),
      contractType,
      coverCceeTaxes,
      trader: {
        id: trader.id,
        name: trader.name,
        logoUri: trader.documents.length ? getTraderLogUri(trader.documents) : '',
        score: trader?.score || 0,
      },
      periods: [...periods],
      economy: economy.map((entry) => ({
        period: entry.period,
        economyPerYear: entry.economyPerYear.map(({ year, amount }) => ({ year, amount })),
      })),
      retailService,
      savings: parseBidSavings(rawBid.savings),
    };
  });
};

const getTraderLogUri = (documents: TraderLogo[]): string => {
  return documents.filter(({ docType }) => DocumentTypeEnum[docType] === DocumentTypeEnum.LOGO_IMAGE)[0].uri || '';
};

const parseBidSavings = (savings: Unpacked<BidSavingsGraphQLResponse['proposal']['bids']>['savings']): Savings => {
  const { status, isInsideScope, retailCommission, wholesaleCommission, ...rest } = savings;

  return {
    status,
    isInsideScope,
    retailCommission: retailCommission === null ? 0 : retailCommission,
    wholesaleCommission: wholesaleCommission === null ? 0 : wholesaleCommission,
    fixedPrice: parseSavingsCalculationResult('fixedPrice', rest),
    guaranteedSaving: parseSavingsCalculationResult('guaranteedSaving', rest),
  };
};

const parseSavingsCalculationResult = (
  savingType: keyof SavingsCalculationResultInput,
  input: SavingsCalculationResultInput,
): SavingsCalculationResult => {
  const result = input[savingType];
  const isResultAvailable = result !== null;

  const groupReport: GroupReport = {
    period: isResultAvailable ? result.groupReport.period : NaN,
    npv: isResultAvailable ? result.groupReport.npv : NaN,
    totalCurrencyAmount: isResultAvailable ? result.groupReport.totalCurrencyAmount : NaN,
    totalPercentageAmount: isResultAvailable ? result.groupReport.totalPercentageAmount : NaN,
    valuesPerYear: isResultAvailable
      ? result.groupReport.valuesPerYear.map(
          ({ year, energyPrice, acrCost, aclCost, management, totalSavingsAmount }) => ({
            year,
            energyPrice,
            aclCost,
            acrCost,
            management,
            totalSavingsAmount,
          }),
        )
      : [],
    initialExpenses: isResultAvailable ? result.groupReport.initialExpenses : NaN,
  };
  const inputPrices: InputPrice[] = isResultAvailable
    ? result.inputPrices.map(({ period, pricePerYear }) => ({
        period,
        pricePerYear: pricePerYear.map(({ year, value }) => ({ year, value })),
      }))
    : [];

  const unitsReports: { [name: string]: UnitReport } = {};

  if (isResultAvailable) {
    result.unitsReports.forEach(
      ({ name, period, totalCurrencyAmount, totalPercentageAmount, valuesPerYear, initialExpenses }) => {
        unitsReports[name] = {
          name,
          period,
          totalCurrencyAmount,
          totalPercentageAmount,
          valuesPerYear: valuesPerYear.map(
            ({ year, energyPrice, acrCost, aclCost, management, totalSavingsAmount }) => ({
              year,
              energyPrice,
              aclCost,
              acrCost,
              management,
              totalSavingsAmount,
            }),
          ),
          initialExpenses,
        };
      },
    );
  }
  return { groupReport, inputPrices, unitsReports };
};
