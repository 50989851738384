import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { File, Units } from './types';
import { QUERY_GET_GROUP_FILES, QUERY_GET_UNITS } from './queries';

export interface FilesContextType {
  setGroupId: React.Dispatch<React.SetStateAction<string>>;
  groupFiles: File[];
  units: Units[];
  isFetchingFiles: boolean;
}

export const FilesContext = createContext<FilesContextType>({} as FilesContextType);

export const FilesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [groupId, setGroupId] = React.useState<string>('');
  const [isFetchingFiles, setIsFetchingFiles] = React.useState<boolean>(true);
  const [units, setUnits] = React.useState<Units[]>([]);
  const [groupFiles, setGroupFiles] = React.useState<File[]>([]);

  const [GetGroupsFiles] = useLazyQuery(QUERY_GET_GROUP_FILES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const parsedFiles = data.files.map((file: File) => {
        if (!file.filePath) {
          return {
            ...file,
            filePath: `documentos/Outros/${file.id}.uuid`,
          };
        } else {
          return file;
        }
      });

      setGroupFiles(parsedFiles);
      setIsFetchingFiles(false);
    },
    onError: (e) => {
      throw `ERROR: ${e}`;
    },
  });

  const [GetUnitsFromGroup] = useLazyQuery(QUERY_GET_UNITS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setUnits(data.group.units);
      setIsFetchingFiles(false);
    },
    onError: (e) => {
      throw `ERROR: ${e}`;
    },
  });

  function getGroupsFilesHandler(commercialGroupId: string) {
    setIsFetchingFiles(true);
    return GetGroupsFiles({
      variables: {
        commercialGroupId,
      },
    });
  }

  function getUnitsFromGroupHandler(groupId: string) {
    setIsFetchingFiles(true);

    return GetUnitsFromGroup({
      variables: {
        groupId,
      },
    });
  }

  React.useEffect(() => {
    if (groupId) {
      getGroupsFilesHandler(groupId);
      getUnitsFromGroupHandler(groupId);
    }
  }, [groupId]);

  return (
    <FilesContext.Provider
      value={{
        setGroupId,
        groupFiles,
        units,
        isFetchingFiles,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};
