import { gql } from '@apollo/client';

export const GET_PROPOSAL_WITH_SAVINGS_COMPILED_BY_GROUP_ID_QUERY = gql`
  query Group($groupId: ID!) {
    group(id: $groupId) {
      proposals {
        id
        createdAt
        lowerFlexibility
        upperFlexibility
        commissionModality
        guaranteeType
        periods
        group {
          id
          name
          legalName
        }
        proposalType
        round
        supply {
          unit {
            id
            name
          }
        }
        bids {
          id
          deadline
          lowerFlexibility
          upperFlexibility
          guaranteeType
          retailService
          contractType
          coverCceeTaxes
          trader {
            id
            name
            score
            documents {
              uri
              docType
            }
          }
          periods
          economy {
            period
            economyPerYear {
              year
              amount
            }
          }
          savings {
            status
            isInsideScope
            retailCommission
            wholesaleCommission
            fixedPrice {
              inputPrices {
                period
                pricePerYear {
                  year
                  value
                }
              }
              groupReport {
                period
                npv
                totalCurrencyAmount
                totalPercentageAmount
                valuesPerYear {
                  year
                  energyPrice
                  acrCost
                  aclCost
                  management
                  totalSavingsAmount
                }
                initialExpenses
              }
              unitsReports {
                name
                period
                totalCurrencyAmount
                totalPercentageAmount
                valuesPerYear {
                  year
                  energyPrice
                  acrCost
                  aclCost
                  management
                  totalSavingsAmount
                }
                initialExpenses
              }
            }
            guaranteedSaving {
              inputPrices {
                period
                pricePerYear {
                  year
                  value
                }
              }
              groupReport {
                period
                npv
                totalCurrencyAmount
                totalPercentageAmount
                valuesPerYear {
                  year
                  energyPrice
                  acrCost
                  aclCost
                  management
                  totalSavingsAmount
                }
              }
              unitsReports {
                name
                period
                totalCurrencyAmount
                totalPercentageAmount
                valuesPerYear {
                  year
                  energyPrice
                  acrCost
                  aclCost
                  management
                  totalSavingsAmount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROCESS_BY_PROPOSAL_ID = gql`
  query GetProcessAndGroupByProposalId($proposalId: ID!) {
    getProcessByProposalId(proposalId: $proposalId) {
      error
      process {
        id
        name
      }
    }
  }
`;
