import { init, track } from '@amplitude/analytics-browser';
import config from '@src/config';

export const amplitudeInit = async (trackPlatform: boolean): Promise<boolean> => {
  if (config.AMPLITUDE_API_KEY) {
    try {
      init(config.AMPLITUDE_API_KEY, undefined, {
        trackingOptions: {
          ipAddress: false,
          language: false,
          platform: trackPlatform,
        },
      });
      return true;
    } catch (error) {
      return false;
    }
  } else {
    return false;
  }
};

export const amplitudeTrackEvent = (
  eventLabel: string,
  eventProperties?: Record<string, string | number>,
): Record<string, any> | null => {
  if (eventLabel) {
    track(eventLabel, eventProperties)
      .promise.then((response) => {
        return {
          code: response.code,
          finalEvent: response.event,
          message: response.message,
        };
      })
      .catch(() => {
        return null;
      });
  }
  return null;
};
