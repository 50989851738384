import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppState, Auth0Provider } from '@auth0/auth0-react';

import MainRouter from '@routers/main-router';
import ApolloWrapper from './ApolloWrapper';
import configuration from '@config';

function App() {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={configuration.DOMAIN}
      clientId={configuration.CLIENT_ID}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      audience={`https://${configuration.DOMAIN}/api/v2/`}
      scope="read:current_user"
      onRedirectCallback={onRedirectCallback}
    >
      <ApolloWrapper>
        <MainRouter />
      </ApolloWrapper>
    </Auth0Provider>
  );
}

export default App;
