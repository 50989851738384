import React from 'react';

import { ValuesPerYear } from '@contexts/compilation-results/types';
import numeral from 'numeral';

interface AclCostsRowProps {
  valuesPerYear: ValuesPerYear[];
  total: number;
}

const AclCostsRow: React.FC<AclCostsRowProps> = ({ valuesPerYear, total }) => {
  const className = 'py-4 font-normal text-start border-neutral-20 border-y text-paragraph-small';
  return (
    <tr>
      <td className={className}>Custo Acl</td>
      {valuesPerYear.map(({ year, aclCost, management }) => (
        <React.Fragment key={year}>
          <td className={className}>{numeral(aclCost - management).format('$ 0,0.00')}</td>
        </React.Fragment>
      ))}
      <td className={className}>{numeral(total).format('$ 0,0.00')}</td>
    </tr>
  );
};

export default AclCostsRow;
