import { ITask } from '@contexts/migration-report/types';
import React from 'react';

interface ExpandedContentProps {
  task: ITask;
}

const ExpandedContent: React.FC<ExpandedContentProps> = ({ task }) => {
  return (
    <div className="py-2 px-12">
      <div className="w-[36rem]">
        <div>
          <strong className="mb-1 font-black text-paragraph-small">Descrição:</strong>
          <p className="mb-2 font-normal text-paragraph-small">{task.description ? task.description : '-'}</p>
        </div>
        <div>
          <strong className="mb-1 font-black text-paragraph-small">Responsabilidade do cliente:</strong>
          <p className="font-normal text-paragraph-small">
            {task.customerResponsibility ? task.customerResponsibility : '-'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExpandedContent;
