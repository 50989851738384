import React from 'react';
import moment from 'moment';
import { Select } from '@clarke-energia/foton';

import { IDocumentFilters } from '@pages/documents/documents-hub';
import { MonthTranslationMapper } from '@utils/translators/proposal';

export interface IPopUpList extends React.HTMLAttributes<HTMLDivElement> {
  filters: IDocumentFilters | undefined;
  setFilters: (value: React.SetStateAction<IDocumentFilters | undefined>) => void;
  setSomeFilterUsed: (val: boolean) => void;
  show?: boolean;
}

const PopUpSetPeriod = ({ filters, setFilters, setSomeFilterUsed, show, ...attrs }: IPopUpList) => {
  const { className } = attrs;
  const [yearsRange, setYearsRange] = React.useState<number[]>([]);

  React.useMemo(() => {
    const currentYear = moment().year();
    const localYears = [];
    let startYear = 2020;
    while (startYear <= currentYear) {
      localYears.push(startYear++);
    }
    setYearsRange(localYears);
  }, []);

  return (
    <div
      className={`flex flex-col gap-2 absolute shadow-3 p-5 bg-white z-10 border-black border-[1px] rounded-medium w-fit ${className} ${
        !show && 'hidden'
      }`}
    >
      <Select
        label="Mês"
        options={Object.values(MonthTranslationMapper).map((month, index) => ({
          display: `${month.charAt(0).toUpperCase()}${month.slice(1)}`,
          value: (index + 1).toString(),
        }))}
        value={filters?.month?.toString() || 'Selecione'}
        onChange={(e) => {
          setFilters((previous) => ({ ...previous, month: parseFloat(e.target.value) }));
          setSomeFilterUsed(true);
        }}
      />
      <Select
        label="Ano"
        options={yearsRange.map((year) => ({
          display: `${year}`,
          value: `${year}`,
        }))}
        value={filters?.year?.toString() || 'Selecione'}
        onChange={(e) => {
          setFilters((previous) => ({ ...previous, year: parseFloat(e.target.value) }));
          setSomeFilterUsed(true);
        }}
      />
    </div>
  );
};

export default PopUpSetPeriod;
