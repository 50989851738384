import { ConsumptionFirstPageSection } from '@components/molecules/consumption-sections/consumption-hub-first-page-section';
import { ConsumptionSecondPageSection } from '@components/molecules/consumption-sections/consumption-hub-second-page-section';
import MessageComponent from '@components/molecules/general/message-component';
import { useConsumption } from '@hooks/use-consumption';
import { getMonthYear, getMonthYearLabel } from '@pages/consumption/helper';
import { InformationMessagesByConsumptionMoment } from '@utils/constants/common';

interface IConsumptionCompilationContent {
  barChartLabels: Array<string> | undefined;
  barChartValues: Array<number | null>;
  barChartSecondValues: Array<number> | undefined;
  barChartSecondLabels: Array<string> | undefined;
  controlDocsLength: number;
  lineChartLabels: Array<string | null>;
  lineChartValues: Array<number | null>;
  labelDate: string;
  setLabelDate: React.Dispatch<React.SetStateAction<string>>;
  setLabelMonthYear: React.Dispatch<React.SetStateAction<string>>;
}

export const ConsumptionCompilationContent = ({
  barChartLabels,
  barChartValues,
  barChartSecondValues,
  barChartSecondLabels,
  controlDocsLength,
  setLabelDate,
  lineChartLabels,
  lineChartValues,
  labelDate,
  setLabelMonthYear,
}: IConsumptionCompilationContent) => {
  const {
    monthlyControlMeasurementByUnitId,
    setMonthYear,
    consumptionProjectionByUnit,
    consumptionProjectionByGroup,
    monthlyGroupControlMeasurement,
    unitId,
  } = useConsumption();
  const unitWithDataConsumption = monthlyControlMeasurementByUnitId && monthlyControlMeasurementByUnitId?.length > 0;
  const groupWithDataConsumption = monthlyGroupControlMeasurement && monthlyGroupControlMeasurement?.length > 0;
  const consumptionData = unitId ? unitWithDataConsumption : groupWithDataConsumption;
  const unitWithDataConsumptionOrProjection = unitWithDataConsumption || consumptionProjectionByUnit;
  const groupWithDataConsumptionOrProjection = groupWithDataConsumption || consumptionProjectionByGroup;
  const consumptionOrProjectionData = unitId
    ? unitWithDataConsumptionOrProjection
    : groupWithDataConsumptionOrProjection;

  return (
    <>
      {consumptionOrProjectionData ? (
        <>
          <ConsumptionFirstPageSection
            barChartLabels={barChartLabels}
            barChartValues={barChartValues}
            barChartSecondValues={barChartSecondValues}
            barChartSecondLabels={barChartSecondLabels}
            controlDocsLength={controlDocsLength}
          />
          {consumptionData && (
            <ConsumptionSecondPageSection
              setLabelDate={setLabelDate}
              lineChartLabels={lineChartLabels}
              lineChartValues={lineChartValues}
              labelDate={labelDate}
            />
          )}
        </>
      ) : (
        <MessageComponent
          title={InformationMessagesByConsumptionMoment.CONSUMPTION_EMPTY.title}
          description={InformationMessagesByConsumptionMoment.CONSUMPTION_EMPTY.description}
          mainButtonLabel={InformationMessagesByConsumptionMoment.CONSUMPTION_EMPTY.mainButtonLabel}
          mainButtonAction={() => {
            const lastMonth = getMonthYear();
            setMonthYear(lastMonth);
            setLabelMonthYear(getMonthYearLabel(lastMonth));
          }}
        />
      )}
    </>
  );
};
