import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { FeedbackNotification } from '@clarke-energia/foton';

import useKickOff from '@hooks/use-kick-off';
import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';
import { IKickOffQuizInputs } from '@contexts/kick-off/types';

import { convertObjectValuesToNumber } from '@utils/objects';
import { INotificationFeedbackContent, KickOffNotificationFeedbackContents } from '@utils/constants/common';

import { TabContainer } from '@components/molecules/tabs/tab-container';
import { KickOffSkeleton } from '@components/molecules/skeleton/kick-off-skeleton';
import kickOffQuizValidate from '@components/molecules/kick-off/quiz/kick-off-quiz-validate';
import KickOffLayout from '@components/molecules/kick-off/kick-off-layout';
import { KickOffQuestionsContentList, KickOffQuestionsTabList } from '@components/molecules/kick-off/quiz/static-data';

const KickOffQuiz: React.FC = () => {
  const { kickOff, loading, setFlowSection, saveKickOffQuizInputsHandler, createKickOffDiagnosisHandler } =
    useKickOff();
  const [messageNotificationFeedBack, setMessageNotificationFeedBack] = React.useState<INotificationFeedbackContent>();

  const unparseKickOffQuizInputs = () => {
    const convertBooleanToString = (value?: boolean) =>
      value === true ? 'true' : value === false ? 'false' : undefined;

    const savedKickOffQuizInputs = kickOff?.quiz?.inputs;
    if (savedKickOffQuizInputs) {
      return {
        managementType: savedKickOffQuizInputs.managementType,
        profileType: savedKickOffQuizInputs.profileType,
        projectedPercentageChangeInConsumptionPerYear:
          savedKickOffQuizInputs.projectedPercentageChangeInConsumptionPerYear,
        iRecCertification: convertBooleanToString(savedKickOffQuizInputs.iRecCertification as boolean),
      };
    }
  };

  const methods = useForm<IKickOffQuizInputs>({
    defaultValues: kickOff && unparseKickOffQuizInputs(),
  });

  const parseKickOffQuizInputs = (values: Record<string, any>): IKickOffQuizInputs => {
    const convertStringToBoolean = (value?: string) =>
      value === 'true' ? true : value === 'false' ? false : undefined;
    return {
      managementType: values.managementType,
      profileType: values.profileType,
      projectedPercentageChangeInConsumptionPerYear:
        values.projectedPercentageChangeInConsumptionPerYear &&
        convertObjectValuesToNumber(values.projectedPercentageChangeInConsumptionPerYear),
      iRecCertification: convertStringToBoolean(values.iRecCertification),
    };
  };

  const handleSubmit: SubmitHandler<IKickOffQuizInputs> = async (values) => {
    const parsedKickOffQuizInputs = parseKickOffQuizInputs(values as Record<string, any>);

    if (kickOffQuizValidate(parsedKickOffQuizInputs)) {
      createKickOffDiagnosisHandler(parsedKickOffQuizInputs)
        .then((response) => {
          if (response.data?.createKickOffDiagnosis.id) setFlowSection('DIAGNOSIS_PREVIEW');
          else setMessageNotificationFeedBack(KickOffNotificationFeedbackContents.SEND_QUIZ_FAILED);
        })
        .catch(() => setMessageNotificationFeedBack(KickOffNotificationFeedbackContents.SEND_QUIZ_FAILED));
    } else {
      setMessageNotificationFeedBack(KickOffNotificationFeedbackContents.INCOMPLETED_QUIZ);
    }
  };

  const handleSaveQuizWhenChanged = () => {
    const currentKickOffQuizInputs = {
      managementType: methods.watch('managementType'),
      profileType: methods.watch('profileType'),
      projectedPercentageChangeInConsumptionPerYear: methods.watch('projectedPercentageChangeInConsumptionPerYear'),
      iRecCertification: methods.watch('iRecCertification'),
    };

    const parsedCurrentKickOffQuizInputs = parseKickOffQuizInputs(currentKickOffQuizInputs as Record<string, any>);
    saveKickOffQuizInputsHandler(parsedCurrentKickOffQuizInputs);
  };

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.PAGE_VIEWED, { page_name: 'KickOff Quiz' });
  }, []);

  return (
    <KickOffLayout>
      {loading ? (
        <KickOffSkeleton />
      ) : (
        <>
          <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
            {messageNotificationFeedBack && (
              <FeedbackNotification
                label={messageNotificationFeedBack.label}
                message={messageNotificationFeedBack.message}
                kind={messageNotificationFeedBack.kind}
                onCloseNotification={() => setMessageNotificationFeedBack(undefined)}
              />
            )}
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} className="flex flex-col gap-3">
              <TabContainer
                tabList={KickOffQuestionsTabList}
                contentList={KickOffQuestionsContentList(handleSaveQuizWhenChanged)}
                incrementalCompletion
                onNavigateBackInInitialTab={() => setFlowSection('WELCOME')}
                onNavigateForwardInFinalTab={() => methods.handleSubmit(handleSubmit)()}
              />
            </form>
          </FormProvider>
        </>
      )}
    </KickOffLayout>
  );
};

export default KickOffQuiz;
