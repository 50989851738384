import {
  CommercialGroup,
  CommercialGroupGraphQLData,
  GroupAccumulatedEconomyReportByGroupId,
  GroupAccumulatedEconomyReportByGroupIdGraphQLData,
} from './types';

export class GroupAccumulatedEconomyReportManager {
  rawData: GroupAccumulatedEconomyReportByGroupIdGraphQLData;
  groupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupId;

  constructor(rawData: GroupAccumulatedEconomyReportByGroupIdGraphQLData) {
    this.rawData = rawData;
    this.groupAccumulatedEconomyReport = rawData.getGroupAccumulatedEconomyReportByGroupID;
  }
}

export class CommercialGroupManager {
  rawData: CommercialGroupGraphQLData;
  groups: CommercialGroup[];

  constructor(rawData: CommercialGroupGraphQLData) {
    this.rawData = rawData;
    this.groups = rawData.groups.data;
  }
}
