import {
  ICreateKickOffDiagnosisMutationData,
  IGetKickOffByGroupIDQueryData,
  IKickOff,
  IUpdateKickOffDiagnosisMutationData,
} from './types';

export class GetKickOffByGroupIDManager {
  rawData: IGetKickOffByGroupIDQueryData;
  kickOff: IKickOff;

  constructor(rawData: IGetKickOffByGroupIDQueryData) {
    this.rawData = rawData;
    this.kickOff = rawData.getKickOffByGroupID;
  }
}

export class UpdateKickOffDiagnosisManager {
  rawData: IUpdateKickOffDiagnosisMutationData;
  kickOff: IKickOff;

  constructor(rawData: IUpdateKickOffDiagnosisMutationData) {
    this.rawData = rawData;
    this.kickOff = rawData.updateKickOffDiagnosis;
  }
}

export class CreateKickOffDiagnosisManager {
  rawData: ICreateKickOffDiagnosisMutationData;
  kickOff: IKickOff;

  constructor(rawData: ICreateKickOffDiagnosisMutationData) {
    this.rawData = rawData;
    this.kickOff = rawData.createKickOffDiagnosis;
  }
}
