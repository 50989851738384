import React from 'react';

import { ValuesPerYear } from '@contexts/compilation-results/types';
import numeral from 'numeral';

interface EnergyPriceRowProps {
  valuesPerYear: ValuesPerYear[];
}

const EnergyPriceRow: React.FC<EnergyPriceRowProps> = ({ valuesPerYear }) => {
  const className = 'py-4 font-normal text-start border-neutral-20 border-y text-paragraph-small';
  return (
    <tr>
      <td className={className}>Preço de energia</td>
      {valuesPerYear.map(({ year, energyPrice }) => (
        <React.Fragment key={year}>
          <td className={className}>{`${numeral(energyPrice).format('$ 0,0.00')}/MWh`}</td>
        </React.Fragment>
      ))}
      <td className={className}>-</td>
    </tr>
  );
};

export default EnergyPriceRow;
