import { NotificationsContext, NotificationsContextType } from '@contexts/notifications/index';
import { useContextSelector } from 'use-context-selector';

export const useNotifications = (): NotificationsContextType => {
  const notifications = useContextSelector(NotificationsContext, (notifications) => notifications.notifications);
  const setUserId = useContextSelector(NotificationsContext, (notifications) => notifications.setUserId);
  const loading = useContextSelector(NotificationsContext, (notifications) => notifications.loading);
  const openNotificationSlideOver = useContextSelector(
    NotificationsContext,
    (notifications) => notifications.openNotificationSlideOver,
  );
  const setOpenNotificationSlideOver = useContextSelector(
    NotificationsContext,
    (notifications) => notifications.setOpenNotificationSlideOver,
  );
  const updateBatchNotificationsReadStatusHandler = useContextSelector(
    NotificationsContext,
    (notifications) => notifications.updateBatchNotificationsReadStatusHandler,
  );
  const getNotificationsByUserIdHandler = useContextSelector(
    NotificationsContext,
    (notifications) => notifications.getNotificationsByUserIdHandler,
  );

  return {
    setUserId,
    notifications,
    loading,
    setOpenNotificationSlideOver,
    openNotificationSlideOver,
    updateBatchNotificationsReadStatusHandler,
    getNotificationsByUserIdHandler,
  };
};
