import { useLazyQuery } from '@apollo/client';
import { getActualMonth, getMonthYear, transformMonthYearToDate } from '@pages/consumption/helper';
import { useAuth } from '@src/ApolloWrapper';
import React, { useEffect, useMemo, useState } from 'react';
import { createContext } from 'use-context-selector';

import {
  AllTotalConsumptionByGroupIdManager,
  AllTotalConsumptionByUnitIdManager,
  ConsolidatedMeasurementByIdManager,
  ConsumptionProjectionByGroupIdManager,
  ConsumptionProjectionByUnitIdManager,
  GetEconomyReportByGroupAndDateManager,
  GroupConsolidatedMeasurementByIdManager,
  MonthlyControlMeasurementByUnitIdManager,
  MonthlyGroupControlMeasurementManager,
  UnitConsumptionMeasuresPerHourManager,
  UnitsManager,
  UnitsWithMeasuringPointManager,
} from './manager';
import {
  GET_ALL_TOTAL_MONTHLY_CONSUMPTION_BY_GROUP_ID_QUERY,
  GET_ALL_TOTAL_MONTHLY_CONSUMPTION_BY_UNIT_ID_QUERY,
  GET_CONSOLIDATED_MEASUREMENT_BY_ID_QUERY,
  GET_CONSUMPTION_PROJECTION_BY_UNIT_ID_QUERY,
  GET_ECONOMY_REPORT_BY_GROUP_AND_DATE_QUERY,
  GET_GROUP_CONSOLIDATED_MEASUREMENTS_BY_ID_QUERY,
  GET_GROUP_CONSUMPTION_PROJECTION_QUERY,
  GET_MEASURING_POINT_BY_GROUP_ID_QUERY,
  GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY,
  GET_MONTHLY_GROUP_CONTROL_MEASUREMENTS_QUERY,
  GET_UNITS_QUERY,
  GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY,
} from './queries';
import {
  ConsolidatedMeasurement,
  ConsumptionContextType,
  GroupConsolidatedMeasurement,
  IConsumptionProjection,
  IGroupConsumptionProjection,
  ITotalMonthlyConsumption,
  MonthlyControlMeasurementByUnitId,
  MonthlyGroupControlMeasurement,
  UnitConsumptionMeasuresPerHour,
  Units,
  UnitsIdWithMeasuringPoint,
} from './types';

export * from './types';

export const ConsumptionContext = createContext({} as ConsumptionContextType);

interface Provider {
  children: React.ReactNode;
}

const ConsumptionProvider: React.FC<Provider> = ({ children }: Provider) => {
  const {
    authStatus: { accessToken },
  } = useAuth();

  const [consolidatedMeasurementDocId, setConsolidatedMeasurementDocId] = useState<string | undefined>('');
  const [groupConsolidatedMeasurementDocId, setGroupConsolidatedMeasurementDocId] = useState<string | undefined>('');
  const [unitId, setUnitId] = useState<string | undefined>();
  const [units, setUnits] = useState<Units[] | undefined>();
  const [groupId, setGroupId] = useState<string | undefined>();
  const [monthYear, setMonthYear] = useState<string | undefined>();
  const [monthlyControlMeasurementByUnitId, setMonthlyControlMeasurementByUnitId] =
    useState<Array<MonthlyControlMeasurementByUnitId>>();
  const [monthlyGroupControlMeasurement, setMonthlyGroupControlMeasurement] =
    useState<Array<MonthlyGroupControlMeasurement>>();
  const [unitConsumptionMeasuresPerHour, setUnitConsumptionMeasuresPerHour] =
    useState<Array<UnitConsumptionMeasuresPerHour>>();
  const [consolidatedMeasurementById, setConsolidatedMeasurementById] = useState<ConsolidatedMeasurement | undefined>();
  const [groupConsolidatedMeasurementById, setGroupConsolidatedMeasurementById] = useState<
    GroupConsolidatedMeasurement | undefined
  >();
  const [openNotificationFeedBack, setOpenNotificationFeedBack] = useState<boolean>(false);
  const [openNotificationFeedBackSync, setOpenNotificationFeedBackSync] = useState<boolean>(false);
  const [unitsIdWithMeasuringPoints, setUnitsIdWithMeasuringPoints] = useState<
    UnitsIdWithMeasuringPoint[] | undefined
  >();
  const [allUnitsFromGroup, setAllUnitsFromGroup] = useState<Units[] | undefined>();
  const [consumptionProjectionByUnit, setConsumptionProjectionByUnit] = useState<IConsumptionProjection | undefined>();
  const [consumptionProjectionByGroup, setConsumptionProjectionByGroup] = useState<
    IGroupConsumptionProjection | undefined
  >();
  const [totalMonthlyConsumptionByUnit, setTotalMonthlyConsumptionByUnit] = useState<
    ITotalMonthlyConsumption[] | undefined
  >();
  const [totalMonthlyConsumptionByGroup, setTotalMonthlyConsumptionByGroup] = useState<
    ITotalMonthlyConsumption[] | undefined
  >();
  const [invoicePreviewOfUnit, setInvoicePreviewOfUnit] = useState<number | undefined>();
  const [invoicePreviewOfGroup, setInvoicePreviewOfGroup] = useState<number | undefined>();
  const [pastInvoiceGroup, setPastInvoiceGroup] = useState<number | undefined>();
  const [pastInvoiceUnit, setPastInvoiceUnit] = useState<number | undefined>();

  const [getConsolidatedMeasurementById] = useLazyQuery(GET_CONSOLIDATED_MEASUREMENT_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new ConsolidatedMeasurementByIdManager(data);
      setConsolidatedMeasurementById(manager.consolidateMeasurementById);
    },
    onError: () => {
      setConsolidatedMeasurementById({} as ConsolidatedMeasurement);
    },
    variables: {
      getConsolidatedMeasurementByIdId: consolidatedMeasurementDocId as string,
    },
  });

  const [getGroupConsolidatedMeasurementById] = useLazyQuery(GET_GROUP_CONSOLIDATED_MEASUREMENTS_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new GroupConsolidatedMeasurementByIdManager(data);
      setGroupConsolidatedMeasurementById(manager.getGroupConsolidatedMeasurementById);
    },
    onError: () => {
      setGroupConsolidatedMeasurementById({} as GroupConsolidatedMeasurement);
    },
    variables: {
      id: groupConsolidatedMeasurementDocId as string,
    },
  });

  const [getMonthlyControlMeasurementByUnitId, { loading: loadingGetMonthlyControlMeasurementByUnitId }] = useLazyQuery(
    GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new MonthlyControlMeasurementByUnitIdManager(data);
        setMonthlyControlMeasurementByUnitId(manager.monthlyControlMeasurementByUnitId);
      },
      onError: () => {
        setMonthlyControlMeasurementByUnitId([]);
      },
      variables: {
        input: {
          unitId: unitId as string,
          monthYear: monthYear as string,
        },
      },
    },
  );

  const [getMonthlyGroupControlMeasurement, { loading: loadingGetMonthlyGroupControlMeasurement }] = useLazyQuery(
    GET_MONTHLY_GROUP_CONTROL_MEASUREMENTS_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new MonthlyGroupControlMeasurementManager(data);
        setMonthlyGroupControlMeasurement(manager.getMonthlyControlMeasurementByGroupId);
      },
      onError: () => {
        setMonthlyGroupControlMeasurement([]);
      },
    },
  );

  const [getUnitConsumptionMeasuresPerHour, { loading: loadingGetUnitConsumptionMeasuresPerHour }] = useLazyQuery(
    GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new UnitConsumptionMeasuresPerHourManager(data);
        setUnitConsumptionMeasuresPerHour(manager.unitConsumptionMeasuresPerHour);
      },
      onError: () => {
        setUnitConsumptionMeasuresPerHour([]);
      },
    },
  );

  const [getGroup, { loading: loadingGetGroup }] = useLazyQuery(GET_UNITS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new UnitsManager(data);
      setAllUnitsFromGroup(manager.units);
    },
    onError: () => {
      setAllUnitsFromGroup([]);
    },
  });

  const [getMeasuringPointByGroupId, { loading: loadingGetMeasuringPointByGroupId }] = useLazyQuery(
    GET_MEASURING_POINT_BY_GROUP_ID_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new UnitsWithMeasuringPointManager(data);
        setUnitsIdWithMeasuringPoints(manager.getMeasuringPointByGroupId);
      },
      onError: () => {
        setUnitsIdWithMeasuringPoints([]);
      },
    },
  );

  const [getConsumptionProjectionByUnitId, { loading: loadingConsumptionProjectionByUnitId }] = useLazyQuery(
    GET_CONSUMPTION_PROJECTION_BY_UNIT_ID_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new ConsumptionProjectionByUnitIdManager(data);
        if (monthYear === getMonthYear(getActualMonth(groupId))) {
          setConsumptionProjectionByUnit(manager.getMonthlyConsumptionProjectionByUnitId);
        } else {
          setConsumptionProjectionByUnit(undefined);
        }
      },
      onError: (e) => {
        setConsumptionProjectionByUnit(undefined);
      },
    },
  );

  const [getConsumptionProjectionByGroupId, { loading: loadingConsumptionProjectionByGroupId }] = useLazyQuery(
    GET_GROUP_CONSUMPTION_PROJECTION_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new ConsumptionProjectionByGroupIdManager(data);
        if (monthYear === getMonthYear(getActualMonth(groupId))) {
          setConsumptionProjectionByGroup(manager.getMonthlyGroupConsumptionProjection);
        } else {
          setConsumptionProjectionByGroup(undefined);
        }
      },
      onError: (e) => {
        setConsumptionProjectionByGroup(undefined);
      },
    },
  );

  const [getAllTotalConsumptionMeasuresPerUnit, { loading: loadingGetAllTotalConsumptionMeasuresPerUnit }] =
    useLazyQuery(GET_ALL_TOTAL_MONTHLY_CONSUMPTION_BY_UNIT_ID_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new AllTotalConsumptionByUnitIdManager(data);
        setTotalMonthlyConsumptionByUnit(manager.getAllTotalConsumptionMeasuresPerUnit);
      },
      onError: (e) => {
        setTotalMonthlyConsumptionByUnit(undefined);
      },
    });

  const [getAllTotalConsumptionMeasuresPerGroup, { loading: loadingGetAllTotalConsumptionMeasuresPerGroup }] =
    useLazyQuery(GET_ALL_TOTAL_MONTHLY_CONSUMPTION_BY_GROUP_ID_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new AllTotalConsumptionByGroupIdManager(data);
        setTotalMonthlyConsumptionByGroup(manager.getAllTotalConsumptionMeasuresPerGroupByMonthYear);
      },
      onError: (e) => {
        setTotalMonthlyConsumptionByGroup(undefined);
      },
    });

  const [getEconomyReportByGroupAndDate, { loading: loadingGetEconomyReportByGroupAndDate }] = useLazyQuery(
    GET_ECONOMY_REPORT_BY_GROUP_AND_DATE_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new GetEconomyReportByGroupAndDateManager(data, unitId, monthYear);
        if (unitId) {
          monthYear !== getMonthYear(getActualMonth(groupId))
            ? setPastInvoiceUnit(manager.unitInvoice)
            : setInvoicePreviewOfUnit(manager.unitInvoice);
        } else {
          monthYear !== getMonthYear(getActualMonth(groupId))
            ? setPastInvoiceGroup(manager.groupInvoice)
            : setInvoicePreviewOfGroup(manager.groupInvoice);
        }
      },
      onError: (e) => {
        setPastInvoiceGroup(undefined);
        setPastInvoiceUnit(undefined);
      },
    },
  );

  const getMonthlyControlMeasurementByUnitIdHandler = () => {
    return getMonthlyControlMeasurementByUnitId();
  };

  const getMonthlyGroupControlMeasurementHandler = () => {
    return getMonthlyGroupControlMeasurement({
      variables: {
        input: {
          groupId: groupId,
          monthYear: monthYear as string,
        },
      },
    });
  };

  const getUnitConsumptionMeasuresPerHourHandler = () => {
    return getUnitConsumptionMeasuresPerHour({
      variables: {
        input: {
          unitId: unitId as string,
          monthYear: monthYear as string,
        },
      },
    });
  };

  const getMeasuringPointByGroupIdHandler = () => {
    return getMeasuringPointByGroupId({
      variables: {
        groupId: groupId,
      },
    });
  };

  const getConsumptionProjectionByGroupIdHandler = () => {
    return getConsumptionProjectionByGroupId({
      variables: {
        input: {
          groupId: groupId,
          monthYear: monthYear as string,
        },
      },
    });
  };

  const getAllTotalConsumptionMeasuresPerGroupHandler = () => {
    return getAllTotalConsumptionMeasuresPerGroup({
      variables: {
        groupId: groupId as string,
      },
    });
  };

  const getGroupUnitsHandler = () => {
    return getGroup({
      variables: {
        groupId: groupId as string,
      },
    });
  };

  const getConsolidatedMeasurementByIdHandler = () => {
    return getConsolidatedMeasurementById();
  };

  const getGroupConsolidatedMeasurementByIdHandler = () => {
    return getGroupConsolidatedMeasurementById();
  };

  const getEconomyReportByGroupAndDateHandler = () => {
    return getEconomyReportByGroupAndDate({
      variables: {
        groupId: groupId as string,
        date: transformMonthYearToDate(monthYear) as string,
      },
    });
  };

  const getConsumptionProjectionByUnitIdHandler = () => {
    return getConsumptionProjectionByUnitId({
      variables: {
        input: {
          unitId: unitId as string,
          monthYear: monthYear as string,
        },
      },
    });
  };

  const getAllTotalMonthlyConsumptionHandler = () => {
    return getAllTotalConsumptionMeasuresPerUnit({
      variables: {
        unitId: unitId as string,
      },
    });
  };

  useEffect(() => {
    getConsolidatedMeasurementByIdHandler();
  }, [consolidatedMeasurementDocId]);

  useEffect(() => {
    getGroupConsolidatedMeasurementByIdHandler();
  }, [groupConsolidatedMeasurementDocId]);

  useEffect(() => {
    if (groupId) {
      getMeasuringPointByGroupIdHandler();
      getAllTotalConsumptionMeasuresPerGroupHandler();
      getGroupUnitsHandler();
    }
  }, [groupId]);

  useEffect(() => {
    if (groupId && monthYear) {
      getConsumptionProjectionByGroupIdHandler();
      getMonthlyGroupControlMeasurementHandler();
      getEconomyReportByGroupAndDateHandler();
    }
  }, [groupId, monthYear]);

  useEffect(() => {
    if (unitId && monthYear) {
      getUnitConsumptionMeasuresPerHourHandler();
      getMonthlyControlMeasurementByUnitIdHandler();
      getConsumptionProjectionByUnitIdHandler();
      getAllTotalMonthlyConsumptionHandler();
      getEconomyReportByGroupAndDateHandler();
    }
  }, [unitId, monthYear]);

  useEffect(() => {
    if (monthYear !== getMonthYear(getActualMonth(groupId))) {
      setConsumptionProjectionByGroup(undefined);
      setConsumptionProjectionByUnit(undefined);
      setInvoicePreviewOfUnit(undefined);
      setInvoicePreviewOfGroup(undefined);
    }
  }, [monthYear]);

  useMemo(() => {
    if (unitsIdWithMeasuringPoints?.length && allUnitsFromGroup) {
      const commonIds = allUnitsFromGroup.filter((unit) =>
        unitsIdWithMeasuringPoints.some((measuringPoint) => unit.id === measuringPoint.unitId),
      );
      setUnits(commonIds);
    }
  }, [unitsIdWithMeasuringPoints, allUnitsFromGroup]);

  return (
    <ConsumptionContext.Provider
      value={{
        setGroupId,
        unitId,
        setUnitId,
        units,
        monthYear,
        setMonthYear,
        setMonthlyControlMeasurementByUnitId,
        monthlyGroupControlMeasurement,
        setMonthlyGroupControlMeasurement,
        monthlyControlMeasurementByUnitId,
        unitConsumptionMeasuresPerHour,
        getMonthlyControlMeasurementByUnitIdHandler,
        setConsolidatedMeasurementDocId,
        setGroupConsolidatedMeasurementDocId,
        consolidatedMeasurementById,
        groupConsolidatedMeasurementById,
        openNotificationFeedBack,
        setOpenNotificationFeedBack,
        openNotificationFeedBackSync,
        setOpenNotificationFeedBackSync,
        consumptionProjectionByUnit,
        consumptionProjectionByGroup,
        totalMonthlyConsumptionByGroup,
        totalMonthlyConsumptionByUnit,
        invoicePreviewOfUnit,
        invoicePreviewOfGroup,
        pastInvoiceGroup,
        pastInvoiceUnit,
        loading:
          loadingGetMonthlyGroupControlMeasurement ||
          loadingConsumptionProjectionByGroupId ||
          loadingGetMonthlyControlMeasurementByUnitId ||
          loadingGetGroup ||
          loadingGetMeasuringPointByGroupId ||
          loadingGetUnitConsumptionMeasuresPerHour ||
          loadingConsumptionProjectionByUnitId ||
          loadingGetAllTotalConsumptionMeasuresPerUnit ||
          loadingGetAllTotalConsumptionMeasuresPerGroup ||
          loadingGetEconomyReportByGroupAndDate,
      }}
    >
      {children}
    </ConsumptionContext.Provider>
  );
};

export default ConsumptionProvider;
