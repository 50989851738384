import numeral from 'numeral';

import { convertAverageConsumptionFromMwmToMwh } from '@utils/energy';

export const parseAverageConsumptionValue = (averageConsumptionInMwm?: number) => {
  if (!averageConsumptionInMwm) return '';

  const parsedAverageConsumptionInMwm = numeral(averageConsumptionInMwm).format('0.0000').concat(' MWm');
  const parsedAverageConsumptionInMwh = numeral(convertAverageConsumptionFromMwmToMwh(averageConsumptionInMwm))
    .format('0.00')
    .concat(' MWh');

  return `${parsedAverageConsumptionInMwm} (${parsedAverageConsumptionInMwh})`;
};
