import React from 'react';
import { FetchResult, LazyQueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { MUTATION_UPDATE_NOTIFICATION_READ_STATUS } from './mutations';
import { QUERY_GET_NOTIFICATIONS_BY_USER_ID } from './queries';
import { INotification } from './types';

export interface NotificationsContextType {
  notifications: Array<INotification> | undefined;
  setUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
  loading: boolean;
  openNotificationSlideOver: boolean;
  setOpenNotificationSlideOver: React.Dispatch<React.SetStateAction<boolean>>;
  getNotificationsByUserIdHandler: () => Promise<LazyQueryResult<any, { userId: string | undefined }>>;
  updateBatchNotificationsReadStatusHandler: (
    notificationsIdsList: Array<string>,
    read: boolean,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
}

export const NotificationsContext = createContext<NotificationsContextType>({} as NotificationsContextType);

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userId, setUserId] = React.useState<string>();
  const [notifications, setNotifications] = React.useState<Array<INotification>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openNotificationSlideOver, setOpenNotificationSlideOver] = React.useState(false);

  const [updateBatchNotificationsReadStatus, { loading: loadingUpdateBatchNotificationsReadStatus }] = useMutation(
    MUTATION_UPDATE_NOTIFICATION_READ_STATUS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (e) => {
        throw `ERROR: ${e}`;
      },
    },
  );

  const [getNotificationsByUserId, { loading: loadingGetNotificationsByUserId }] = useLazyQuery(
    QUERY_GET_NOTIFICATIONS_BY_USER_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setNotifications(data.getNotificationsFromUserId as Array<INotification>);
      },
      onError: (e) => {
        throw `ERROR: ${e}`;
      },
      notifyOnNetworkStatusChange: true,
      variables: {
        userId,
      },
    },
  );

  function getNotificationsByUserIdHandler() {
    return getNotificationsByUserId();
  }

  function updateBatchNotificationsReadStatusHandler(notificationsIdsList: Array<string>, read: boolean) {
    return updateBatchNotificationsReadStatus({
      variables: {
        input: {
          notificationsIdsList,
          read,
        },
      },
    });
  }

  React.useEffect(() => {
    if (userId) {
      getNotificationsByUserIdHandler();
    }
  }, [userId, openNotificationSlideOver]);

  React.useEffect(() => {
    setLoading(loadingGetNotificationsByUserId || loadingUpdateBatchNotificationsReadStatus);
  }, [loadingGetNotificationsByUserId, loadingUpdateBatchNotificationsReadStatus]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        loading,
        openNotificationSlideOver,
        setUserId,
        setOpenNotificationSlideOver,
        updateBatchNotificationsReadStatusHandler,
        getNotificationsByUserIdHandler,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
