import React from 'react';
import { Modal } from '@clarke-energia/foton';
import { Proposal } from '@contexts/compilation-results/types';
import { CommissionModalityTypeEnum, GuaranteeTypeEnum } from '@utils/translators/proposal';

import ContentModal from '@components/atoms/energy-compilation/content-modal';

import { contentModal } from './helper';

interface ProcessInfoDisplayProps {
  proposal: Proposal;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProcessInfoDisplay: React.FC<ProcessInfoDisplayProps> = ({ proposal, showModal, setShowModal }) => {
  const flexibility = `-${proposal.lowerFlexibility}% / +${proposal.upperFlexibility}%`;
  const guarantee = GuaranteeTypeEnum[proposal.guaranteeType];
  const commission = CommissionModalityTypeEnum[proposal.commissionModality];
  const duration = proposal.periods.map((year) => `${year} anos`).join(', ');

  return (
    <>
      <Modal
        open={showModal}
        setOpen={() => setShowModal(!showModal)}
        title="Escopo Solicitado"
        subtitle={`Processo iniciado em ${proposal.startDate}`}
        className="py-7 pt-7 pb-0"
      >
        <div className="-mt-5 -mb-7">
          <p className="mb-6 text-paragraph-medium w-[80%]">
            Essas são as características solicitadas do seu contrato de energia. Elas foram definidas em conjunto e
            foram apresentadas para a coleta das propostas.
          </p>
          {contentModal(flexibility, guarantee, commission, duration).map(({ icon, title, value }) => (
            <ContentModal key={title} title={title} iconName={icon} value={value} />
          ))}
        </div>
      </Modal>
    </>
  );
};
export default ProcessInfoDisplay;
