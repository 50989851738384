import React from 'react';
import { createContext } from 'use-context-selector';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  ICreateKickOffDiagnosisMutationData,
  ICreateKickOffDiagnosisMutationVariables,
  IGetKickOffByGroupIDQueryData,
  IGetKickOffByGroupIDQueryVariables,
  IKickOff,
  IKickOffContext,
  IKickOffDiagnosis,
  IKickOffQuizInputs,
  ISaveKickOffQuizInputsMutationData,
  ISaveKickOffQuizInputsMutationVariables,
  ISubmitKickOffQuizMutationData,
  ISubmitKickOffQuizMutationVariables,
  IUpdateKickOffDiagnosisMutationData,
  IUpdateKickOffDiagnosisMutationVariables,
  KickOffFlowSection,
} from './types';
import { CreateKickOffDiagnosisManager, GetKickOffByGroupIDManager, UpdateKickOffDiagnosisManager } from './manager';
import { GET_KICK_OFF_BY_GROUP_ID_QUERY } from './queries';
import {
  CREATE_KICK_OFF_DIAGNOSIS_MUTATION,
  SAVE_KICK_OFF_QUIZ_INPUTS_MUTATION,
  SUBMIT_KICK_OFF_QUIZ_MUTATION,
  UPDATE_KICK_OFF_DIAGNOSIS_MUTATION,
} from './mutations';

export const KickOffContext = createContext({} as IKickOffContext);

interface Provider {
  children: React.ReactNode;
}

const KickOffProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [groupId, setGroupId] = React.useState<string>();
  const [kickOff, setKickOff] = React.useState<IKickOff>();
  const [flowSection, setFlowSection] = React.useState<KickOffFlowSection>('WELCOME');

  const [getKickOffByGroupID, { loading: loadingGetKickOffByGroupID }] = useLazyQuery<
    IGetKickOffByGroupIDQueryData,
    IGetKickOffByGroupIDQueryVariables
  >(GET_KICK_OFF_BY_GROUP_ID_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new GetKickOffByGroupIDManager(data);
      setKickOff(manager.kickOff);
    },
  });

  const [saveKickOffQuizInputs] = useMutation<
    ISaveKickOffQuizInputsMutationData,
    ISaveKickOffQuizInputsMutationVariables
  >(SAVE_KICK_OFF_QUIZ_INPUTS_MUTATION, {
    fetchPolicy: 'network-only',
  });

  const [submitKickOffQuiz, { loading: loadingSubmitKickOffQuiz }] = useMutation<
    ISubmitKickOffQuizMutationData,
    ISubmitKickOffQuizMutationVariables
  >(SUBMIT_KICK_OFF_QUIZ_MUTATION, {
    fetchPolicy: 'network-only',
  });

  const [createKickOffDiagnosis, { loading: loadingCreateKickOffDiagnosis }] = useMutation<
    ICreateKickOffDiagnosisMutationData,
    ICreateKickOffDiagnosisMutationVariables
  >(CREATE_KICK_OFF_DIAGNOSIS_MUTATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new CreateKickOffDiagnosisManager(data);
      setKickOff(manager.kickOff);
    },
  });

  const [updateKickOffDiagnosis, { loading: loadingUpdateKickOffDiagnosis }] = useMutation<
    IUpdateKickOffDiagnosisMutationData,
    IUpdateKickOffDiagnosisMutationVariables
  >(UPDATE_KICK_OFF_DIAGNOSIS_MUTATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new UpdateKickOffDiagnosisManager(data);
      setKickOff(manager.kickOff);
    },
  });

  function getKickOffByGroupIDHandler() {
    return getKickOffByGroupID({
      variables: {
        groupId,
      },
    });
  }

  function saveKickOffQuizInputsHandler(quizInputs: IKickOffQuizInputs) {
    return saveKickOffQuizInputs({
      variables: {
        kickOffId: kickOff?.id as string,
        quizInputs,
      },
    });
  }

  function submitKickOffQuizHandler() {
    return submitKickOffQuiz({
      variables: {
        kickOffId: kickOff?.id as string,
      },
    });
  }

  function createKickOffDiagnosisHandler(quizInputs: IKickOffQuizInputs) {
    return createKickOffDiagnosis({
      variables: {
        kickOffId: kickOff?.id as string,
        quizInputs,
      },
    });
  }

  function updateKickOffDiagnosisHandler(diagnosisData: IKickOffDiagnosis) {
    return updateKickOffDiagnosis({
      variables: {
        kickOffId: kickOff?.id as string,
        diagnosisData,
      },
    });
  }

  React.useEffect(() => {
    if (groupId) getKickOffByGroupIDHandler();
  }, [groupId]);

  return (
    <KickOffContext.Provider
      value={{
        kickOff,
        loading:
          loadingGetKickOffByGroupID ||
          loadingCreateKickOffDiagnosis ||
          loadingUpdateKickOffDiagnosis ||
          loadingSubmitKickOffQuiz,
        flowSection,
        setFlowSection,
        setGroupId,
        getKickOffByGroupIDHandler,
        saveKickOffQuizInputsHandler,
        submitKickOffQuizHandler,
        createKickOffDiagnosisHandler,
        updateKickOffDiagnosisHandler,
      }}
    >
      {children}
    </KickOffContext.Provider>
  );
};

export default KickOffProvider;
