import React from 'react';
import { IconProps, HeroIcon } from '@clarke-energia/foton';

interface ContentModalProps {
  title: string;
  value: string;
  iconName: IconProps['icon'];
}

const ContentModal: React.FC<ContentModalProps> = ({ title, value, iconName }) => {
  return (
    <div className="flex gap-4 items-center mb-5 last:mb-0">
      <div className="p-4 w-7 h-7 bg-primary-10 rounded-medium">
        <HeroIcon icon={iconName} />
      </div>
      <div className="flex flex-col gap-0">
        <div className="text-neutral-50 text-paragraph-small truncate">{title}</div>
        <div className="flex flex-col gap-4 min-[300px]:flex-row">
          <div className="truncate text-paragraph-medium">{value}</div>
        </div>
      </div>
    </div>
  );
};

export default ContentModal;
