import React from 'react';
import { useParams } from 'react-router-dom';

import useKickOff from '@hooks/use-kick-off';
import { KickOffFlowSection } from '@contexts/kick-off/types';

import NotFound404 from '@pages/404';
import LoadingPage from '@components/templates/loading-page';

import KickOffWelcome from './kick-off-welcome';
import KickOffQuiz from './kick-off-quiz';
import KickOffDiagnosisPreview from './kick-off-diagnosis-preview';
import KickOffDiagnosisResult from './kick-off-diagnosis-result';

const KickOffFlowSectionIndexer: Record<KickOffFlowSection, JSX.Element> = {
  WELCOME: <KickOffWelcome />,
  QUIZ: <KickOffQuiz />,
  DIAGNOSIS_PREVIEW: <KickOffDiagnosisPreview />,
  DIAGNOSIS_RESULT: <KickOffDiagnosisResult />,
};

const KickOffHub: React.FC = () => {
  const { groupId } = useParams();
  const { kickOff, flowSection, setGroupId } = useKickOff();

  React.useEffect(() => {
    if (groupId) setGroupId(groupId);
  }, [groupId]);

  const isQuizSubmitted = kickOff?.quiz?.submittedAt;
  const isQuizActive = kickOff?.quiz?.status === 'ACTIVE';

  if (!kickOff) return <LoadingPage />;

  if (isQuizSubmitted) {
    return KickOffFlowSectionIndexer.DIAGNOSIS_RESULT;
  } else {
    if (isQuizActive) {
      return KickOffFlowSectionIndexer[flowSection];
    } else {
      return <NotFound404 />;
    }
  }
};

export default KickOffHub;
