import { ISustainabilityChartInformation, ISustainabilityHistoryChartTitle } from '@contexts/group-economy/types';
import { triggerAmplitudeButtonClickedEvent } from '@services/event-tracking/events-trigger';

export const sustainabilityChartsInformation: Record<
  ISustainabilityHistoryChartTitle,
  ISustainabilityChartInformation
> = {
  CO2AvoidedHistory: {
    title: 'Histórico de CO2 evitados (toneladas)',
    tooltipContent:
      'Consumir energia no mercado livre com a Clarke é utilizar somente energia limpa e renovável como eólica, solar, biomassa ou de pequenas centrais hidrelétricas. Desse modo temos o impacto ambiental reduzido ao poupar emissão de CO2.',
    highlightChart: true,
  },
  treesSavedHistory: {
    title: 'Histórico do equivalente em árvores',
    tooltipContent:
      'Estudos mostram que uma árvore pode capturar 15,6kg (0,0156 t) de CO2 por ano, desse modo conseguimos descobrir quantas árvores são equivalentes à quantidade de CO2 evitado.',
    highlightChart: true,
  },
};

export const triggerButtonEvent = (buttonText: string, destinationPath?: string) => {
  return triggerAmplitudeButtonClickedEvent('Sustentabilidade', buttonText, destinationPath);
};
