import { gql } from '@apollo/client';

export const QUERY_GET_NOTIFICATIONS_BY_USER_ID = gql`
  query GetNotificationsFromUserId($userId: String) {
    getNotificationsFromUserId(userId: $userId) {
      id
      title
      createdAt
      description
      read
      targetUsersIds
      topic
      type
    }
  }
`;
