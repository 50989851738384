import React from 'react';

import { HeroIcon, IconProps } from '@clarke-energia/foton';

interface IEmojiStatus {
  icon: IconProps['icon'];
  backgroundColorClassName: string;
}

const EmojiStatus = ({ icon, backgroundColorClassName }: IEmojiStatus) => {
  return (
    <div className={`flex w-8 h-8 p-4 rounded-medium ${backgroundColorClassName}`}>
      <HeroIcon icon={icon} className="flex" />
    </div>
  );
};
export default EmojiStatus;
