export const LOGIN_PATH = '/login';

// Private paths
export const HOME_PATH = '/';
export const ECONOMY_REPORTS_PATH = '/relatorios';
export const ACCUMULATED_ECONOMY_REPORTS_PATH = '/relatorio-acumulado';
export const MONTHLY_ECONOMY_REPORTS_PATH = '/relatorio-mensal';
export const UNIT_ECONOMY_REPORTS_PATH = '/relatorios-unidades';
export const UNIT_PATH = '/unidade';
export const MIGRATION_REPORT_PATH = '/migracao';
export const CONSUMPTION_DASHBOARD_PATH = '/consumo';
export const FILES_PATH = '/documentos';
export const ENERGY_PRICE = '/cotacao';
export const NOTIFICATION_PATH = '?notification=open';
export const ATTENDANCE_PATH = '/falar-com-especialista';
export const SUSTAINABILITY_PATH = '/sustentabilidade';

// Public paths
export const KICK_OFF_PATH = '/kick-off';

// 404
export const DOES_NOT_EXIST_PATH = '/does-not-exist';

export const PUBLIC_PATHS_LIST = [KICK_OFF_PATH];
