import { DetailedCardProps, TagProps, IconProps, TooltipProps } from '@clarke-energia/foton';
import { CommissionModalityTypeEnum, GuaranteeTypeEnum } from '@utils/translators/proposal';
import { ParsedBidsData } from '@contexts/compilation-results/types';
import CCEELogo from '../../../assets/ccee-logo.svg';
import numeral from '@utils/numeral';

interface CardContent {
  value: string;
  title: string;
  icon: IconProps['icon'];
}

const cceeTooltipContent =
  'As empresas no mercado livre de energia participam do pagamento do Encargo de Serviços do Sistema para a Câmara de Comercialização de Energia Elétrica (CCEE). Caso haja cobertura, a fornecedora que fica responsável pelo pagamento.';

type hightlightStyletype = {
  label: string;
  color: 'green' | 'primary' | 'yellow' | undefined;
  tooltip: TooltipProps;
};

const checkhighLightCardInfo = (bestScoreIdx: number, currentItem: number): hightlightStyletype => {
  const hightlightStyle: hightlightStyletype = { label: '', color: undefined, tooltip: { content: '' } };
  const tooltipContentScoreDescription =
    'Fazemos uma avaliação de cada fornecedora com base em fatores qualitativos como, nível de atendimento, flexibilidade nas negociações e aderência ao produto solicitado. Também fatores quantitativos como, volume comercializado, patrimônio líquido, liquidez corrente e índice de endividamento. A partir desses requisitos atribui-se uma nota de 0 a 5 pontos.';

  if (bestScoreIdx === -1) {
    hightlightStyle.color = undefined;
  }

  if (bestScoreIdx === 0 && currentItem === 0) {
    hightlightStyle.label = 'Maior economia e melhor avaliação ';
    hightlightStyle.color = 'green';
    hightlightStyle.tooltip.content = tooltipContentScoreDescription;
  }
  if (bestScoreIdx !== 0 && currentItem === 0) {
    hightlightStyle.label = 'Maior economia ';
    hightlightStyle.color = 'primary';
  }

  if (bestScoreIdx > 0 && bestScoreIdx === currentItem) {
    hightlightStyle.label = 'Melhor avaliação ';
    hightlightStyle.color = 'yellow';
    hightlightStyle.tooltip.content = tooltipContentScoreDescription;
  }

  return hightlightStyle;
};

export const highLightCard = (
  cards: ParsedBidsData[],
  isInsideScope: boolean,
  bestScoreidx: number,
): DetailedCardProps[] => {
  return cards.map((card, idx) => {
    const tagContent: TagProps = {
      color: 'gray',
      kind: 'icon',
      icon: 'CalendarIcon',
      label: `Validade: ${card.deadline}`,
    };
    const tagHighlight: TagProps = {
      color: isInsideScope ? 'green' : 'white',
      kind: 'default',
      label: card.totalPercentageAmount,
    };

    const tagOutsideScope: TagProps = {
      color: 'red',
      kind: 'default',
      label: 'Fora do escopo',
    };
    const rank = `${idx + 1}º`;

    const highlightInfo = checkhighLightCardInfo(bestScoreidx, idx);
    const hightlightStyle = highlightInfo?.color ? { hightlightStyle: highlightInfo } : undefined;

    return {
      ...(isInsideScope && hightlightStyle),
      tag: tagContent,
      imageContent: {
        imgPath: card.traderLogo,
        ...(isInsideScope ? { text: rank } : { tag: tagOutsideScope }),
        ...(card.traderScore && {
          textWithIcon: { iconName: 'StarIcon', text: numeral(card.traderScore).format('0,0.00') },
        }),
      },
      cardHighLightInfo: {
        title: 'Economia',
        tag: tagHighlight,
        value: card.totalCurrencyAmount,
      },
      cardContent: [
        { title: 'VLP de economia', iconName: 'FaceSmileIcon', value: card.npv },
        { title: 'Flexibilidade', iconName: 'AdjustmentsVerticalIcon', value: card.flexibility },
        { title: 'Garantia', iconName: 'BriefcaseIcon', value: card.guaranteeType },
        { title: 'Modalidade de contratação', iconName: 'NewspaperIcon', value: card.traderType },
        {
          title: 'Encargos CCEE',
          imgPath: CCEELogo,
          value: card.cceeCoverTaxes,
          tooltip: { content: cceeTooltipContent },
        },
      ],
    };
  });
};

export const contentModal = (
  flexibility: string,
  guarantee: GuaranteeTypeEnum,
  commission: CommissionModalityTypeEnum,
  duration: string,
): CardContent[] => {
  return [
    {
      title: 'Flexibilidade',
      value: flexibility,
      icon: 'AdjustmentsVerticalIcon',
    },
    {
      title: 'Garantia',
      value: guarantee,
      icon: 'BriefcaseIcon',
    },
    {
      title: 'Modalidade de contratação',
      value: commission,
      icon: 'NewspaperIcon',
    },
    {
      title: 'Duração',
      value: duration,
      icon: 'ClockIcon',
    },
  ];
};

export const expandendContentTable = (managementTotal: number, initialExpenses: number, curencyAmount: number) => [
  { title: 'Gestão Clarke', key: 'management', total: managementTotal },
  { title: 'Investimento Inicial', key: 'EMPTY', total: initialExpenses },
  {
    title: 'Economia líquida anual',
    key: 'totalSavingsAmount',
    total: curencyAmount,
    styleOptions: { bold: true, bgColor: 'bg-primary-60', border: 'border-y' },
  },
];
