import React from 'react';

interface EmptyScoreProps {
  isInsideScope: boolean;
}

const EmptyScope: React.FC<EmptyScoreProps> = ({ isInsideScope }) => {
  const scopeTxt = isInsideScope ? 'dentro do escopo' : 'fora do escopo';
  return (
    <div className="flex flex-col col-span-8 gap-5 h-full">
      <div className="text-heading-large">{`Sem propostas ${scopeTxt} para esse processo.`} </div>
      <div className="text-paragraph-medium">{`Não há nenhuma proposta ${scopeTxt} para esse processo.`}</div>
    </div>
  );
};

export default EmptyScope;
