import React from 'react';
import { NumericInput } from '@clarke-energia/foton';
import { Controller, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { NumberFormatProps } from 'react-number-format';

export interface NumericFormFieldProps<T> {
  field: Path<T>;
  id: string;
  label: string;
  formatProps: Pick<
    NumberFormatProps,
    | 'placeholder'
    | 'prefix'
    | 'suffix'
    | 'allowNegative'
    | 'thousandSeparator'
    | 'decimalSeparator'
    | 'decimalScale'
    | 'fixedDecimalScale'
    | 'format'
    | 'mask'
    | 'isNumericString'
  >;
  options?: RegisterOptions;
  className?: string;
}
export function NumericFormField<T>({
  field,
  id,
  label,
  formatProps: {
    placeholder,
    prefix,
    suffix,
    format,
    mask,
    allowNegative = false,
    thousandSeparator = '.',
    decimalSeparator = ',',
    decimalScale = 2,
    fixedDecimalScale = true,
    isNumericString,
  },
  options,
}: NumericFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
    // @ts-expect-error -> Dynamic type
  } = useFormContext<T>();
  return (
    <Controller
      // @ts-expect-error -> Dynamic control
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => {
        return (
          <div className="mt-3">
            <NumericInput
              id={id}
              customAttrs={{ 'data-cy': id }}
              name={name}
              label={label}
              value={isNaN(value) || value === null || value === undefined ? '' : value.toString()}
              formatProps={{
                placeholder,
                thousandSeparator,
                decimalSeparator,
                decimalScale,
                fixedDecimalScale,
                allowNegative,
                prefix,
                suffix,
                format,
                mask,
              }}
              required={(options?.required as boolean) || false}
              error={errors[field]?.message as string | undefined}
              onBlur={onBlur}
              onChange={{
                valueArgType: isNumericString ? 'string' : 'number',
                callback: (value: string | number | null) => onChange(value),
              }}
            />
          </div>
        );
      }}
    />
  );
}
