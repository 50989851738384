export const consumptionTootTipBarChart = {
  content:
    'Este é o consumo dia a dia da sua unidade no mês atual. Assim que a medição é liberada pela CCEE ela é trazida para o gráfico. Baseado no seu histórico, projetamos quanto será o consumo nesse mês.',
};

export const consumptionTootTipLineChart = {
  content: 'Este é o consumo detalhado hora a hora da sua unidade no dia selecionado.',
};

export const projectionTootTipCard = {
  content:
    'Quantidade total estimada do que será consumido no mês. Nesse número temos o consumo medido e o consumo projetado. Essa projeção é realizada baseada no seu histórico diário, semanal e mensal.',
};

export const invoicePreviewTootTipCard = {
  content:
    'Esse é o valor que projetamos de quanto será a sua fatura de luz nesse mês. O cálculo é feito com a base da tarifa e impostos da fornecedora e distribuidora e no consumo medido e projetado do mês.',
};

export const ClarkeCustomerConsumptionPeriods = [
  {
    referenceMonthYear: '03/2023',
  },
  {
    referenceMonthYear: '04/2023',
  },
  {
    referenceMonthYear: '05/2023',
  },
  {
    referenceMonthYear: '06/2023',
  },
  {
    referenceMonthYear: '07/2023',
  },
  {
    referenceMonthYear: '08/2023',
  },
  {
    referenceMonthYear: '09/2023',
  },
  {
    referenceMonthYear: '10/2023',
  },
];

export const CLARKE_CUSTOMER_GROUP_ID = 'bf40ddd3-39d7-4860-8563-21d87dee2c1e';
