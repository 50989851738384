import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@clarke-energia/foton';

import { IKickOffSuggestedProduct } from '@contexts/kick-off/types';
import useKickOff from '@hooks/use-kick-off';
import { INotificationFeedbackContent, KickOffNotificationFeedbackContents } from '@utils/constants/common';

import { parseUpdateSuggestedProductFormData } from '../parser';
import './style.css';

interface IFormUpdateSuggestedProduct {
  inputs: Array<JSX.Element>;
  dataToUpdate: IKickOffSuggestedProduct;
  onSuccess: () => void;
  onCancel: () => void;
  setMessageNotificationFeedBack: React.Dispatch<React.SetStateAction<INotificationFeedbackContent | undefined>>;
}

function FormUpdateSuggestedProduct({
  inputs,
  dataToUpdate,
  onSuccess,
  onCancel,
  setMessageNotificationFeedBack,
}: IFormUpdateSuggestedProduct) {
  const [loading, setLoading] = useState(false);
  const { updateKickOffDiagnosisHandler } = useKickOff();

  const isOnMobileScreen = window.innerWidth <= 768;

  const parseDataToUpdate = (): IKickOffSuggestedProduct | undefined =>
    dataToUpdate && {
      ...dataToUpdate,
      upperFlexibility: dataToUpdate.upperFlexibility ? dataToUpdate.upperFlexibility * 100 : undefined,
      lowerFlexibility: dataToUpdate.lowerFlexibility ? dataToUpdate.lowerFlexibility * 100 : undefined,
    };

  const methods = useForm<IKickOffSuggestedProduct>({
    defaultValues: parseDataToUpdate(),
  });

  const onSubmit: SubmitHandler<IKickOffSuggestedProduct> = async (values) => {
    setLoading(true);
    const parsedFormData = parseUpdateSuggestedProductFormData(values);
    updateKickOffDiagnosisHandler(parsedFormData)
      .then((response) => {
        if (response.data?.updateKickOffDiagnosis.id) {
          onSuccess();
          setMessageNotificationFeedBack(KickOffNotificationFeedbackContents.UPDATE_DIAGNOSIS_SUCCESS);
        } else {
          onCancel();
          setMessageNotificationFeedBack(KickOffNotificationFeedbackContents.UPDATE_DIAGNOSIS_FAILED);
        }
      })
      .catch(() => {
        onCancel();
        setMessageNotificationFeedBack(KickOffNotificationFeedbackContents.UPDATE_DIAGNOSIS_FAILED);
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col justify-between pt-4 -mb-7">
        <div className="overflow-visible w-full">
          <div className="flex flex-col gap-2">{inputs.map((input) => input)}</div>
        </div>
        <div className="flex gap-4 mt-8 md:mt-10">
          <Button
            label="Salvar edições"
            kind="primary"
            loading={loading}
            size={isOnMobileScreen ? 'small' : 'medium'}
          />
          <Button
            kind="secondary"
            label="Cancelar"
            onClick={onCancel}
            disabled={loading}
            size={isOnMobileScreen ? 'small' : 'medium'}
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default FormUpdateSuggestedProduct;
