import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { useParams } from 'react-router';
import { Layout, ProgressGadget, ColumnOptions, Tag, ButtonIcon, Table } from '@clarke-energia/foton';

import { Category, ITask, ITopic, IUnitsReport, TaskStatus } from '@contexts/migration-report/types';
import { useMigrationReport } from '@hooks/use-migration-report';
import { useUserInfo } from '@hooks/use-user-info';
import { HOME_PATH, MIGRATION_REPORT_PATH } from '@routers/constants';

import AvatarWithName from '@components/atoms/avatar-with-name';
import ExpandedContent from '@components/atoms/expanded-content';
import { UnitMigrationReportSkeleton } from '@components/molecules/skeleton/unit-migration-report-skeleton';
import Carousel from '@components/molecules/carousel';

import clarkeLogo from '@assets/clarke-logo.svg';

import { TaskStatusTagPropsSelector } from './static-data';
import './style.css';

interface ITableData {
  expandedContent: JSX.Element;
  data: ITask;
}

interface ISelectedTopicButton extends ITopic {
  index: number;
}

const UnitMigrationReport: React.FC = () => {
  const params = useParams();
  const { user, isFetchingUser } = useUserInfo();
  const { migrationReportByGroup, setGroupId, isFetchingMigration } = useMigrationReport();

  const [unitMigrationReport, setUnitMigrationReport] = React.useState<IUnitsReport>();
  const [selectedTopic, setSelectedTopic] = React.useState<ISelectedTopicButton>();
  const [tableData, setTableData] = React.useState<ITableData[]>();

  const handleTopicClick = (topic: ITopic, index: number) => {
    setSelectedTopic({ ...topic, index });
    setActive(index);

    const localTableData: ITableData[] = topic.tasks.map((task) => ({
      expandedContent: <ExpandedContent task={task} />,
      data: {
        ...task,
      },
    }));
    setTableData(localTableData);
  };

  const {
    setActive,
    nextItem,
    previousItem,
    component: CarouselComponent,
  } = Carousel({
    itemsWidth: 206,
    gap: 20,
    paddingInline: 40,
    children: unitMigrationReport?.topics.map((topic, index) => {
      return (
        <div
          className="w-full cursor-pointer h-[204px]"
          key={topic.thirdPartyId}
          onClick={() => {
            handleTopicClick(topic, index);
          }}
        >
          <ProgressGadget
            key={topic.thirdPartyId}
            title={topic.title}
            progressValue={topic.conclusionPercentage}
            alternativeColor={selectedTopic?.index === index}
            hideHeaderLine={true}
          />
        </div>
      );
    }),
  });

  const tableColumns: ColumnOptions<ITask>[] = [
    {
      accessor: 'title',
      header: 'Etapas',
      renderAsElement: (entry: ITask) => entry.title,
    },
    {
      accessor: 'dueDate',
      header: 'Data prevista',
      renderAsElement: (entry: ITask) => (entry?.dueDate as string) && moment(entry.dueDate).format('DD/M/YYYY'),
    },
    {
      accessor: 'thirdPartyId',
      header: 'Responsável',
      renderAsElement: (entry: ITask) =>
        entry.responsible && (
          <AvatarWithName
            responsible={
              entry.responsible.name === 'Cliente'
                ? {
                    category: entry.responsible.category,
                    name: unitMigrationReport?.nickname ?? entry.responsible.name,
                  }
                : entry.responsible
            }
            avatarImage={entry.responsible.category === Category.CLARKE ? clarkeLogo : null}
          />
        ),
    },
    {
      accessor: 'status',
      header: 'Status',
      renderAsElement: (entry: ITask) => {
        if (entry.status) {
          const tagProps = TaskStatusTagPropsSelector[entry.status];
          return <Tag color={tagProps.color} kind={tagProps.kind} label={tagProps.label} icon={tagProps.icon} />;
        } else {
          return '-';
        }
      },
    },
  ];

  const getDoneTasksCount = (topic: ITopic) => topic.tasks.filter((task) => task.status === TaskStatus.DONE).length;

  React.useMemo(() => {
    if (user?.group) {
      setGroupId(user.group.id);
    }
  }, [user]);

  React.useMemo(() => {
    const unitMigrationReportByReportId = migrationReportByGroup.units?.find(
      (unit) => unit.thirdPartyId === params.unitReportId,
    );
    if (unitMigrationReportByReportId) {
      setUnitMigrationReport(unitMigrationReportByReportId);
      handleTopicClick(unitMigrationReportByReportId.topics[0], 0);
    }
  }, [migrationReportByGroup]);

  if (isFetchingMigration || isFetchingUser) {
    return (
      <Layout loading>
        <UnitMigrationReportSkeleton />
      </Layout>
    );
  }

  return (
    <Layout
      title={unitMigrationReport?.nickname}
      alternativeLinks={
        migrationReportByGroup
          ? migrationReportByGroup?.units?.map((unit) => ({
              label: unit.nickname,
              url: `/migracao/${unit.thirdPartyId}`,
            }))
          : []
      }
      navigationHistoryLinks={[
        { label: 'Home', url: HOME_PATH },
        { label: 'Migração', url: MIGRATION_REPORT_PATH },
        {
          label: `${unitMigrationReport?.nickname}`,
          url: `${MIGRATION_REPORT_PATH}/${unitMigrationReport?.thirdPartyId}`,
        },
      ]}
      headerTags={[
        {
          color: 'green',
          kind: 'default',
          label: `${numeral(unitMigrationReport?.conclusionPercentage).format('% 0')} Concluído`,
          size: 'medium',
        },
      ]}
      darkerBackground
    >
      <div className="customer-unit-section">
        <div className="mb-8 md:first-section first-section">
          <h1 className="customer-unit-title">Migração para o Mercado Livre</h1>
          <p className="customer-unit-intro">
            As etapas do processo de migração para o Mercado Livre acontecem simultaneamente. Abaixo é possível ver o
            progresso de cada uma delas. Fique atento para as suas responsabilidades em cada etapa, como envios ou
            assinaturas de documentos.
          </p>
        </div>
        <div className="topic-buttons">
          <div className="topic-header">
            <ButtonIcon icon="ChevronLeftIcon" kind="secondary" onClick={previousItem} />
            <ButtonIcon icon="ChevronRightIcon" kind="secondary" onClick={nextItem} />
          </div>
          <div className="-mx-8">{CarouselComponent}</div>
        </div>
        {selectedTopic?.tasks && (
          <div className="topic-section">
            <div className="topic-info">
              <div className="flex relative gap-4 mt-6 mb-5 md:static md:flex-row md:items-center">
                <h2 className="topic-title">{selectedTopic.title} </h2>
                <Tag
                  className="absolute right-0 bottom-3 md:static"
                  kind="default"
                  label={`${getDoneTasksCount(selectedTopic)}/${selectedTopic.tasks?.length}`}
                  color="green"
                  size="medium"
                />
              </div>
              <Table expandable data={tableData as ITableData[]} tableColumns={tableColumns} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UnitMigrationReport;
