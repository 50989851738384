import React from 'react';
import { IconCard, IconCardProps, PopUpListProps } from '@clarke-energia/foton';

import PageSectionGenerator from '@components/molecules/general/page-section-generator';

export interface ButtonIconProps {
  openPopupList: boolean;
  setOpenPopupList: () => void;
}

export interface SustainabilityCardsSectionProps {
  title: string;
  buttonIconProps?: ButtonIconProps;
  popUpListProps?: PopUpListProps;
  iconCardProps: IconCardProps[];
}

const SustainabilityCardsSection: React.FC<SustainabilityCardsSectionProps> = ({
  title,
  buttonIconProps,
  popUpListProps,
  iconCardProps,
}) => {
  return (
    <PageSectionGenerator title={title} buttonIconProps={buttonIconProps} popUpListProps={popUpListProps}>
      <>
        <div className={'grid grid-cols-1 gap-6 w-full lg:grid-cols-2 xl:grid-cols-2'}>
          {iconCardProps.map((item, index) => (
            <IconCard
              className="col-span-1"
              key={`iconCard-${index}`}
              title={item.title}
              alternativeIcon={item.alternativeIcon}
              label={item.label}
            />
          ))}
        </div>
      </>
    </PageSectionGenerator>
  );
};

export default SustainabilityCardsSection;
