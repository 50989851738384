import { QueryResult, useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';

import { authContext } from '@src/ApolloWrapper';

import { IDecodedToken } from '@contexts/users/types';
import jwtDecode from 'jwt-decode';
import { CommercialGroupManager, GroupAccumulatedEconomyReportManager } from './manager';
import {
  formatUnitsAccumulatedSummaryEconomyReportsFromGroup,
  parseEconomyReport,
  parserGroupAccumulatedEconomyReport,
} from './parser';
import {
  QUERY_GET_ALL_GROUPS,
  QUERY_GET_ALL_GROUPS_ECONOMY_REPORT_BY_GROUP_ID,
  QUERY_GET_GROUP_ACCUMULATED_ECONOMY_REPORT_BY_GROUP_ID,
} from './queries';
import {
  CommercialGroup,
  CommercialGroupGraphQLData,
  GroupAccumulatedEconomyReportByGroupIdGraphQLData,
  GroupAccumulatedEconomyReportByGroupIdParsed,
  GroupAccumulatedEconomyReportByGroupIdQueryVariables,
  GroupReportDataResponse,
  GroupReportParsed,
  GroupReportResponse,
  SummaryEconomyReportsHistory,
  UnitReportParsed,
} from './types';

export interface GroupEconomyContextType {
  getGroupEconomyReportsHandler: (id: string) => Promise<
    QueryResult<
      GroupReportResponse,
      {
        id: string;
      }
    >
  >;
  groupId: string;
  setGroupId: React.Dispatch<React.SetStateAction<string>>;
  groupEconomyReports: GroupReportParsed[];
  rawGroupEconomyReports: GroupReportDataResponse[];
  rawSummaryEconomyReportsHistory: SummaryEconomyReportsHistory[] | undefined;
  groupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupIdParsed;
  unitAccumulatedSummaryReportParsed: UnitReportParsed[] | undefined;
  groups: CommercialGroup[];
  loading: boolean;
}

export const GroupEconomyContext = createContext<GroupEconomyContextType>({} as GroupEconomyContextType);

export const GroupEconomyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { authStatus } = useContext(authContext);

  const [groupId, setGroupId] = useState<string>('');
  const [isFetchingGroupEconomyReports, setIsFetchingGroupEconomyReports] = useState<boolean>(true);
  const [groupEconomyReports, setGroupEconomyReports] = useState<GroupReportParsed[]>([] as GroupReportParsed[]);
  const [rawGroupEconomyReports, setRawGroupEconomyReports] = useState<GroupReportDataResponse[]>(
    [] as GroupReportDataResponse[],
  );
  const [rawSummaryEconomyReportsHistory, setRawSummaryEconomyReportsHistory] = useState<
    SummaryEconomyReportsHistory[] | undefined
  >();
  const [groupAccumulatedEconomyReport, setGroupAccumulatedEconomyReport] =
    useState<GroupAccumulatedEconomyReportByGroupIdParsed>({} as GroupAccumulatedEconomyReportByGroupIdParsed);
  const [unitAccumulatedSummaryReportParsed, setUnitAccumulatedSummaryReportParsed] = useState<UnitReportParsed[]>();
  const [groups, setGroups] = useState<CommercialGroup[]>([] as CommercialGroup[]);

  const decodedToken: IDecodedToken = jwtDecode(authStatus.accessToken);
  const isSuperAdmin = decodedToken.scope.split(' ').includes('switch:customer');

  const [GetGroupEconomyReports] = useLazyQuery(QUERY_GET_ALL_GROUPS_ECONOMY_REPORT_BY_GROUP_ID, {
    fetchPolicy: 'network-only',
    variables: { id: groupId },
    onCompleted: (data: GroupReportResponse) => {
      const filteredPublishedReports = data.getAllGroupsEconomyReportByGroupID.filter(
        (report) => report.status === 'FINAL',
      );

      setRawGroupEconomyReports(filteredPublishedReports);
      const groupReportsParsed = parseEconomyReport(filteredPublishedReports);
      setGroupEconomyReports(groupReportsParsed);
      setIsFetchingGroupEconomyReports(false);
    },
    onError: (e) => {
      throw `ERROR: ${e}`;
    },
  });

  const [getGroupAccumulatedEconomyReportByGroupID, { loading: loadingGetGroupAccumulatedEconomyReportByGroupID }] =
    useLazyQuery<
      GroupAccumulatedEconomyReportByGroupIdGraphQLData,
      GroupAccumulatedEconomyReportByGroupIdQueryVariables
    >(QUERY_GET_GROUP_ACCUMULATED_ECONOMY_REPORT_BY_GROUP_ID, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new GroupAccumulatedEconomyReportManager(data);
        setGroupAccumulatedEconomyReport(parserGroupAccumulatedEconomyReport(manager.groupAccumulatedEconomyReport));
        setUnitAccumulatedSummaryReportParsed(
          formatUnitsAccumulatedSummaryEconomyReportsFromGroup(
            manager.groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports
              ? manager.groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports
              : [],
          ),
        );
        setRawSummaryEconomyReportsHistory(manager.groupAccumulatedEconomyReport.summaryEconomyReportsHistory);
      },
    });

  const [getAllGroups, { loading: loadingGetAllGroups }] = useLazyQuery<CommercialGroupGraphQLData>(
    QUERY_GET_ALL_GROUPS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new CommercialGroupManager(data);
        setGroups(manager.groups);
      },
    },
  );

  function getGroupEconomyReportsHandler(id: string) {
    setIsFetchingGroupEconomyReports(true);
    return GetGroupEconomyReports({
      variables: {
        id,
      },
    });
  }

  function getGroupAccumulatedEconomyReportByGroupIDHandler() {
    return getGroupAccumulatedEconomyReportByGroupID({
      variables: {
        groupId,
      },
    });
  }

  const getAllGroupsHandler = () => {
    return getAllGroups();
  };

  useEffect(() => {
    if (groupId) {
      getGroupEconomyReportsHandler(groupId);
      getGroupAccumulatedEconomyReportByGroupIDHandler();
    }
  }, [groupId]);

  useEffect(() => {
    if (isSuperAdmin) {
      getAllGroupsHandler();
    }
  }, []);

  return (
    <GroupEconomyContext.Provider
      value={{
        groupId,
        getGroupEconomyReportsHandler,
        setGroupId,
        groupEconomyReports,
        rawGroupEconomyReports,
        rawSummaryEconomyReportsHistory,
        groupAccumulatedEconomyReport,
        unitAccumulatedSummaryReportParsed,
        groups,
        loading:
          isFetchingGroupEconomyReports || loadingGetGroupAccumulatedEconomyReportByGroupID || loadingGetAllGroups,
      }}
    >
      {children}
    </GroupEconomyContext.Provider>
  );
};
