import { CheckIcon } from '@heroicons/react/outline';
import React from 'react';

export interface TabProps {
  label: string;
  circleContent: string;
  active?: boolean;
  completed?: boolean;
  onClick?: () => void;
}

export const Tab: React.FC<TabProps> = ({ label, circleContent, active, completed, onClick }) => {
  const cicleBaseClassName =
    'w-8 h-8 p-2 flex justify-center items-center text-paragraph-small font-normal text-center rounded-pill border-[1px]';
  const circleActiveClassName = `${cicleBaseClassName} ${
    active ? 'border-primary-60 text-primary-60' : 'border-neutral-100 text-black'
  }`;
  const circleCompletedClassName = `bg-primary-60 border-black ${cicleBaseClassName}`;

  return (
    <div
      onClick={onClick}
      className={`cursor-pointer col-span-4 flex w-full justify-center lg:justify-start align-middle pb-5 border-b-[1px] ${
        active ? 'border-primary-60' : 'border-neutral-100'
      }`}
    >
      {completed && !active ? (
        <CheckIcon className={circleCompletedClassName} />
      ) : (
        <div className={circleActiveClassName}>{circleContent}</div>
      )}
      <div className="flex hidden items-center ml-4 lg:flex lg:visible">
        <p className={`text-paragraph-medium font-normal ${active ? 'text-primary-60' : 'text-black'}`}>{label}</p>
      </div>
    </div>
  );
};
