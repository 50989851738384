import React from 'react';
import { HeroIcon } from '@clarke-energia/foton';
import { useNotifications } from '@hooks/use-notifications';

interface MobileMenuControllerButtonProps {
  setMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileSidebarOpen: boolean;
}
const MobileMenuControllerButton: React.FC<MobileMenuControllerButtonProps> = ({
  setMobileSidebarOpen,
  mobileSidebarOpen,
}) => {
  const { openNotificationSlideOver, setOpenNotificationSlideOver } = useNotifications();

  return (
    <HeroIcon
      icon={mobileSidebarOpen ? 'XMarkIcon' : openNotificationSlideOver ? 'ArrowLeftIcon' : 'Bars3Icon'}
      className="inline-flex absolute top-0 left-0 justify-center items-center p-5 -mt-0.5 -ml-0.5 w-10 h-10 text-black rounded-md"
      onClick={() => {
        setOpenNotificationSlideOver(false);
        setMobileSidebarOpen((previous) => !previous);
      }}
    />
  );
};

export default MobileMenuControllerButton;
