import { ButtonIcon, PopUpList, PopUpListProps } from '@clarke-energia/foton';
import React from 'react';

interface IPageSectionGenerator extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children: JSX.Element;
  buttonIconProps?: { openPopupList: boolean; setOpenPopupList: () => void };
  popUpListProps?: PopUpListProps | undefined;
  subInfo?: string | undefined;
}

const PageSectionGenerator = ({
  title,
  children,
  buttonIconProps,
  popUpListProps,
  subInfo,
  ...attrs
}: IPageSectionGenerator): JSX.Element => {
  const { className } = attrs;
  return (
    <div className={`flex flex-col col-span-full gap-5 mx-5 h-full flex-grow lg:mx-0 ${className}`}>
      <div className="flex relative justify-between items-end w-full">
        <div className="flex relative gap-4">
          <div className="text-heading-medium lg:text-heading-large">{title}</div>
          <div className="relative">
            {buttonIconProps && (
              <ButtonIcon
                icon={buttonIconProps.openPopupList ? 'ChevronUpIcon' : 'ChevronDownIcon'}
                kind={'ghost'}
                onClick={buttonIconProps.setOpenPopupList}
              />
            )}
            {popUpListProps && (
              <PopUpList
                items={popUpListProps.items}
                show={popUpListProps.show}
                className="overflow-auto absolute top-20 z-50 mr-8 -mt-8 cursor-pointer left-[-5rem] w-[11rem] max-h-[13rem] sm:w-[18rem] sm:max-h-[20rem] sm:-right-[12rem]"
              />
            )}
          </div>
        </div>
        {subInfo && <div className="text-sm text-gray-400">{subInfo}</div>}
      </div>
      {children}
    </div>
  );
};

export default PageSectionGenerator;
