import React from 'react';

const CompareProposalsHeader: React.FC<{ processName: string | null }> = ({ processName }) => {
  return (
    <div className="col-span-full mb-7 xl:col-span-4">
      <h2 className="mb-4 font-black text-heading-2xlarge">{`Cotação ${processName}`}</h2>
      <p className="text-paragraph-medium">
        As propostas recebidas nessa rodada de cotação são categorizadas como dentro ou fora do escopo solicitado e
        expostas em ordem de qual proposta oferece mais economia.
      </p>
    </div>
  );
};

export default CompareProposalsHeader;
