export interface IMigrationReport {
  id: string;
  thirdPartyId: string;
  commercialGroup: string;
  conclusionPercentage: number;
  units: IUnitsReport[];
}

export interface IUnitsReport {
  thirdPartyId: string;
  nickname: string;
  conclusionPercentage: number;
  topics: ITopic[];
}

export interface ITopic {
  thirdPartyId: string;
  title: string;
  conclusionPercentage: number;
  dueDate: string | null;
  tasks: ITask[];
}

export interface ITask {
  thirdPartyId: string;
  title: string;
  dueDate: string | null;
  status: TaskStatus | null;
  customerResponsibility: string;
  responsible: IResponsible;
  description: string;
}

export interface IResponsible {
  category: Category;
  name: string;
}

export enum Category {
  CLARKE = 'CLARKE',
  CUSTOMER = 'CUSTOMER',
  TRADER = 'TRADER',
}

export enum TaskStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  OVERDUE = 'OVERDUE',
}
