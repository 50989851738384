import numeral from 'numeral';

import { parseAverageConsumptionValue } from '../parser';

import {
  IKickOffDiagnosis,
  IKickOffSuggestedProduct,
  KickOffProductType,
  KickOffTraderType,
  KickOffWarrantyType,
} from '@contexts/kick-off/types';

import { IKickOffDiagnosisPreviewAccordion } from '@components/atoms/kick-off/kick-off-diagnosis-preview';
import { KickOffDiagnosisPreviewExpandedContentIndexer } from './static-data';

export const parseSuggestedProductContentIndexer = (
  suggestedProduct: IKickOffSuggestedProduct,
): Array<IKickOffDiagnosisPreviewAccordion> => {
  const parseFlexibilityValue = () => {
    const parsedUpperFlexibility = numeral(Math.abs(suggestedProduct.upperFlexibility as number)).format('% 0.0');
    const parsedLowerFlexibility = numeral(Math.abs(suggestedProduct.lowerFlexibility as number)).format('% 0.0');
    return `-${parsedLowerFlexibility}/+${parsedUpperFlexibility}`;
  };

  const parseContractTermInYearsValue = () => `${suggestedProduct.contractTermInYears} anos`;

  return [
    {
      productFeature: 'productType',
      label: 'Produto',
      icon: 'PuzzlePieceIcon',
      value: KickOffProductType[suggestedProduct.productType],
      expandedContent: KickOffDiagnosisPreviewExpandedContentIndexer.productType,
    },
    {
      productFeature: 'traderType',
      label: 'Modalidade de contratação',
      icon: 'NewspaperIcon',
      value: KickOffTraderType[suggestedProduct.traderType],
      expandedContent: KickOffDiagnosisPreviewExpandedContentIndexer.traderType,
    },
    {
      productFeature: 'averageConsumption',
      label: 'Consumo médio mensal',
      icon: 'LightBulbIcon',
      value: parseAverageConsumptionValue(suggestedProduct.averageConsumptionInMwm),
      expandedContent: KickOffDiagnosisPreviewExpandedContentIndexer.averageConsumption,
    },
    {
      productFeature: 'flexibility',
      label: 'Flexibilidade',
      icon: 'AdjustmentsVerticalIcon',
      value: parseFlexibilityValue(),
      allowEditing: true,
      expandedContent: KickOffDiagnosisPreviewExpandedContentIndexer.flexibility,
    },
    {
      productFeature: 'contractTerm',
      label: 'Duração',
      icon: 'ClockIcon',
      value: parseContractTermInYearsValue(),
      allowEditing: true,
      expandedContent: KickOffDiagnosisPreviewExpandedContentIndexer.contractTerm,
    },
    {
      productFeature: 'warrantyType',
      label: 'Garantia',
      icon: 'BriefcaseIcon',
      value: KickOffWarrantyType[suggestedProduct.warrantyType],
      allowEditing: true,
      expandedContent: KickOffDiagnosisPreviewExpandedContentIndexer.warrantyType,
    },
  ];
};

export const parseUpdateSuggestedProductFormData = (values: IKickOffSuggestedProduct): IKickOffDiagnosis => ({
  suggestedProduct: {
    productType: values.productType,
    traderType: values.traderType,
    warrantyType: values.warrantyType,
    contractTermInYears: values.contractTermInYears,
    lowerFlexibility: (Number(values.lowerFlexibility) / 100) * -1,
    upperFlexibility: Number(values.upperFlexibility) / 100,
  },
});
