import React from 'react';

import { SelectFormField } from '@components/atoms/form/select';
import { NumericFormField } from '@components/atoms/form/numeric';

import { IKickOffSuggestedProduct, KickOffWarrantyType } from '@contexts/kick-off/types';

import { KickOffDiagnosisPreviewModalData, KickOffSuggestedProductFeature } from './types';

export const KickOffDiagnosisPreviewExpandedContentIndexer: Record<KickOffSuggestedProductFeature, JSX.Element> = {
  productType: (
    <p className="text-paragraph-medium">
      <strong>Preço Fixo</strong> é o modelo de contratação em que o seu negócio tem previsibilidade do quanto será
      cobrado durante o seu contrato.{' '}
      <strong>
        Nesse formato é estabelecido um valor para o megawatt-hora (MWh), para os anos da duração do contrato.
      </strong>{' '}
      Neste modelo, você fica independente do sistema de bandeiras tarifárias.
    </p>
  ),
  traderType: (
    <p className="text-paragraph-medium">
      <strong>Modalidade de contratação</strong> é a maneira como a fornecedora representará o cliente perante à Câmara
      de Comercialização de Energia Elétrica (CCEE). Existem dois tipos:
      <br></br>
      <br></br>
      <strong>Atacadista:</strong> É a modalidade onde você adere à CCEE, ou seja, você terá algumas responsabilidades
      mensais com a câmara, em contrapartida tem mais potencial de economia. A Clarke Energia colabora realizando o
      gerenciamento dessas atividades.
      <br></br>
      <br></br>
      <strong>Varejista:</strong> Já a modalidade varejista, a fornecedora de energia te representa na CCEE, ou seja,
      você fica livre dessas responsabilidades mensais com a câmara, em troca a economia é um pouco menor, comparando
      com a Atacadista. A Clarke Energia colabora na auditoria desses dados.
    </p>
  ),
  averageConsumption: (
    <p className="text-paragraph-medium">
      Baseado no seu histórico de consumo dos últimos 12 meses e na expectativa de aumento e redução do consumo,
      conseguimos encontrar o seu Consumo Médio. Esse valor demonstra a sua realidade do uso de energia e será utilizado
      para <strong>determinar a quantidade de energia que será comprada.</strong>
    </p>
  ),
  flexibility: (
    <p className="text-paragraph-medium">
      <strong>Flexibilidade</strong> é o atributo que garante que o preço da energia acordado no contrato será mantido
      mesmo que haja um consumo superior ou inferior ao volume de energia contratado. Por exemplo, uma flexibilidade de
      50% garante que o seu negócio pagará o mesmo valor de MWh tanto para o caso de ele consumir só metade da energia
      contratada quanto caso ele use 50% a mais do que o acordado. Leia mais no blog da{' '}
      <a href="https://clarke.com.br/blog/">Clarke ↗</a>
    </p>
  ),
  contractTerm: (
    <p className="text-paragraph-medium">
      Estes contratos são conhecidos no mercado livre como Contratos de Longo Prazo. Definir a duração deles é
      importante para garantir, durante os anos, as condições acordadas como volume contratado, flexibilidade e o preço
      da energia. Com uma contratação mais longa, conseguimos aproveitar os preços mais baixos dos anos mais distantes,
      sem nos expor a uma indexação acumulada no período.
    </p>
  ),
  warrantyType: (
    <p className="text-paragraph-medium">
      É um recurso para assegurar que a realização de um pagamento será efetuada. É uma maneira de dar segurança à
      fornecedora para qualquer possibilidade de inadimplência. Existem casos que não há a necessidade de garantia, mas
      é uma prática comum no mercado livre de energia.{' '}
      <strong>
        Existem diversos tipos de garantia financeira, como seguro garantia, pagamento antecipado, carta fiança bancária
        e registro contra pagamento.
      </strong>
    </p>
  ),
};

export const modalsDataForProductCustomizationIndexer: Record<
  KickOffSuggestedProductFeature,
  KickOffDiagnosisPreviewModalData | undefined
> = {
  productType: undefined,
  averageConsumption: undefined,
  traderType: undefined,
  contractTerm: {
    title: 'Editar duração.',
    description:
      'A duração do contrato define por quanto tempo você será o relacionamento com a fornecedora de energia.',
    inputs: [
      <SelectFormField<IKickOffSuggestedProduct>
        key="contractTermInYears"
        id="contractTermInYears"
        field="contractTermInYears"
        label="Duração"
        transform={{ output: (value) => Number(value) }}
        inputOptions={[2, 3, 4, 5, 6].map((contractTermInYears) => ({
          value: contractTermInYears,
          optionLabel: `${contractTermInYears} anos`,
        }))}
      />,
    ],
  },
  warrantyType: {
    title: 'Editar garantia.',
    description: 'Selecione o tipo de garantia financeira que você prefere.',
    inputs: [
      <SelectFormField<IKickOffSuggestedProduct>
        key="warrantyType"
        id="warrantyType"
        field="warrantyType"
        label="Garantia"
        inputOptions={Object.entries(KickOffWarrantyType).map((warrantyTypeEntry) => ({
          value: warrantyTypeEntry[0],
          optionLabel: warrantyTypeEntry[1],
        }))}
      />,
    ],
  },
  flexibility: {
    title: 'Editar flexibilidade.',
    description:
      'Flexibilidade garante que o preço da energia será mantido mesmo que haja um consumo maior ou menor que o contratado.',
    inputs: [
      <NumericFormField<IKickOffSuggestedProduct>
        key="upperFlexibility"
        id="upperFlexibility"
        field="upperFlexibility"
        label="Flexibilidade máxima"
        formatProps={{
          placeholder: '10%',
          mask: '_',
          allowNegative: false,
          fixedDecimalScale: false,
          decimalScale: 0,
          suffix: '%',
          prefix: '+',
        }}
      />,
      <NumericFormField<IKickOffSuggestedProduct>
        key="lowerFlexibility"
        id="lowerFlexibility"
        field="lowerFlexibility"
        label="Flexibilidade mínima"
        formatProps={{
          placeholder: '10%',
          mask: '_',
          allowNegative: false,
          fixedDecimalScale: false,
          decimalScale: 0,
          suffix: '%',
          prefix: '-',
        }}
      />,
    ],
  },
};
