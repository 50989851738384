import React from 'react';
import ClarkeLogoHorizontal from '@assets/logo-clarke-horizontal-green.png';

interface IKickOffLayout {
  children: JSX.Element;
}
const KickOffLayout: React.FC<IKickOffLayout> = ({ children }: IKickOffLayout) => {
  return (
    <div className="col-span-11 overflow-x-hidden px-6 md:px-0 h-full min-h-screen sm:mx-5 md:mx-0 bg-[#FAFAFA]">
      <div className="grid grid-cols-12 w-full">
        <img
          src={ClarkeLogoHorizontal}
          className="col-span-full px-8 mt-6 w-full md:col-span-2 md:px-0 md:m-8 md:w-4/5"
        />
        <div className="col-span-12 my-8 md:col-span-6 md:col-start-4">{children}</div>
      </div>
    </div>
  );
};

export default KickOffLayout;
