import React from 'react';
import Skeleton from 'react-loading-skeleton';

const CompilationResultSkeleton: React.FC = () => {
  return (
    <div className="col-span-full">
      <Skeleton containerClassName="mb-5" width="30%" />
      <Skeleton height="8rem" />
      <Skeleton height="6rem" className="mt-8 mb-7" width="50%" />
      <div>
        <Skeleton height="30rem" className="mb-8" />
        <Skeleton height="8rem" />
      </div>
    </div>
  );
};

export default CompilationResultSkeleton;
