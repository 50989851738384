import { gql } from '@apollo/client';
import { KICK_OFF_GRAPHQL_SCHEMA } from './queries';

export const SAVE_KICK_OFF_QUIZ_INPUTS_MUTATION = gql`
  mutation SaveKickOffQuizInputs($kickOffId: ID!, $quizInputs: KickOffQuizInputs) {
    saveKickOffQuizInputs(kickOffId: $kickOffId, quizInputs: $quizInputs)
  }
`;

export const SUBMIT_KICK_OFF_QUIZ_MUTATION = gql`
  mutation SubmitKickOffQuiz($kickOffId: ID!) {
    submitKickOffQuiz(kickOffId: $kickOffId)
  }
`;

export const CREATE_KICK_OFF_DIAGNOSIS_MUTATION = gql`
  mutation CreateKickOffDiagnosis($kickOffId: ID!, $quizInputs: KickOffQuizInputs!) {
    createKickOffDiagnosis(kickOffId: $kickOffId, quizInputs: $quizInputs) {
      ${KICK_OFF_GRAPHQL_SCHEMA}
    }
  }
`;

export const UPDATE_KICK_OFF_DIAGNOSIS_MUTATION = gql`
  mutation UpdateKickOffDiagnosis($kickOffId: ID!, $diagnosisData: KickOffDiagnosisInput!) {
    updateKickOffDiagnosis(kickOffId: $kickOffId, diagnosisData: $diagnosisData) {
      ${KICK_OFF_GRAPHQL_SCHEMA}
    }
  }
`;
