import React from 'react';
import { Button } from '@clarke-energia/foton';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';
import useKickOff from '@hooks/use-kick-off';

import KickOffLayout from '@components/molecules/kick-off/kick-off-layout';
import { KickOffSkeleton } from '@components/molecules/skeleton/kick-off-skeleton';
import KickOffWelcomeHeader from '@components/molecules/kick-off/welcome/welcome-header';

const KickOffWelcome: React.FC = () => {
  const { kickOff, loading, setFlowSection } = useKickOff();

  const isKickOffQuizAlreadyStarted = kickOff?.status === 'DIAGNOSTIC_QUIZ_BEING_COMPLETED';

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.PAGE_VIEWED, { page_name: 'KickOff Welcome Page' });
  }, []);

  return (
    <KickOffLayout>
      {loading ? (
        <KickOffSkeleton />
      ) : (
        <>
          <KickOffWelcomeHeader commercialGroup={kickOff?.commercialGroup?.name} />
          <div className="col-span-full text-center">
            <Button
              kind="primary"
              label={isKickOffQuizAlreadyStarted ? 'Continuar formulário' : 'Iniciar Formulário'}
              size="small"
              onClick={() => setFlowSection('QUIZ')}
            />
          </div>
        </>
      )}
    </KickOffLayout>
  );
};

export default KickOffWelcome;
