import React from 'react';
import { ButtonInline, IconProps } from '@clarke-energia/foton';

import {
  KickOffDiagnosisPreviewModalData,
  KickOffSuggestedProductFeature,
} from '@components/molecules/kick-off/diagnosis/preview/types';

import { modalsDataForProductCustomizationIndexer } from '@components/molecules/kick-off/diagnosis/preview/static-data';

import EmojiStatus from './emoji-status';

export interface IKickOffDiagnosisPreviewAccordion {
  productFeature: KickOffSuggestedProductFeature;
  label: string;
  value: string;
  icon: IconProps['icon'];
  allowEditing?: boolean;
  expandedContent?: JSX.Element;
  setUpdateSuggestedProductModalData?: React.Dispatch<
    React.SetStateAction<KickOffDiagnosisPreviewModalData | undefined>
  >;
}

export const KickOffDiagnosisPreviewAccordion = ({
  productFeature,
  label,
  value,
  icon,
  allowEditing,
  expandedContent,
  setUpdateSuggestedProductModalData,
}: IKickOffDiagnosisPreviewAccordion) => {
  const [showExpandedContent, setShowExpandedContent] = React.useState<boolean>(false);

  const isOnMobileScreen = window.innerWidth <= 768;

  return (
    <div className="flex gap-4 items-start px-5 py-[20px] rounded-medium border-[1px] border-neutral-30">
      <div className="flex items-center h-9">
        <EmojiStatus icon={icon} backgroundColorClassName="bg-primary-10" />
      </div>
      <div className={`flex flex-col justify-center gap-5 overflow-hidden w-full h-fit`}>
        <div className="flex flex-col justify-center pt-2 w-full h-full">
          <div className="text-paragraph-small text-neutral-50 truncate md:text-paragraph-medium">{label}</div>
          <div className="text-paragraph-medium truncate md:text-heading-xsmall">{value}</div>
        </div>
        {expandedContent && showExpandedContent && (
          <div className="text-paragraph-small md:text-heading-xsmall">{expandedContent}</div>
        )}
      </div>
      <div className="flex flex-col gap-2 justify-center items-center h-9 md:flex-row-reverse w-fit">
        {expandedContent && (
          <ButtonInline
            icon={showExpandedContent ? 'ChevronUpIcon' : 'ChevronDownIcon'}
            kind="secondary"
            size="small"
            className="inline px-4 border-neutral-30"
            onClick={() => setShowExpandedContent((previous) => !previous)}
          />
        )}
        {allowEditing && setUpdateSuggestedProductModalData && (
          <ButtonInline
            icon={isOnMobileScreen ? 'PencilIcon' : undefined}
            label={isOnMobileScreen ? undefined : 'Editar'}
            kind="secondary"
            size="small"
            className="inline px-4 border-neutral-30"
            onClick={() => setUpdateSuggestedProductModalData(modalsDataForProductCustomizationIndexer[productFeature])}
          />
        )}
      </div>
    </div>
  );
};

interface IKickOffDiagnosisPreviewHeader {
  group?: string;
}

export const KickOffDiagnosisPreviewHeader = ({ group }: IKickOffDiagnosisPreviewHeader) => (
  <div className="flex flex-col gap-4 w-full">
    <p className="text-heading-small md:text-heading-large">
      {group}, <strong>conheça o seu produto!</strong>
    </p>
    <p className="text-paragraph-small md:text-paragraph-medium">
      Combinamos as suas respostas com os dados da sua conta de luz para definir o{' '}
      <strong>melhor contrato de energia para você.</strong> Sugerimos que leia atentamente o que significa cada
      característica e <strong>caso ache necessário, edite amdumas definições do seu produto</strong>, clicando em
      Editar.
    </p>
  </div>
);
