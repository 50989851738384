import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import BarChart from '@components/atoms/charts/bar-chart';
import numeral from '@utils/numeral';
import './styles.css';

interface IBarChartComponent {
  chartLabels: string[];
  chartSecondLabels?: string[];
  chartValues: number[];
  chartSecondValues?: number[];
  barHighlight?: boolean;
  barThicknessInPixels?: number;
  currencyData?: boolean;
  className?: string;
  animation?: boolean;
  stepSizeValue?: number;
  legend?: boolean;
  legendLabel?: string;
  legendSecondLabel?: string;
  suffix?: string;
}

const BarChartComponent = ({
  chartLabels,
  chartSecondLabels,
  chartValues,
  chartSecondValues,
  barThicknessInPixels,
  barHighlight = true,
  currencyData,
  className,
  animation = true,
  stepSizeValue,
  legend = false,
  legendLabel = '',
  legendSecondLabel = '',
  suffix = '',
}: IBarChartComponent): JSX.Element => {
  const legendMargin = {
    id: 'legendMargin',
    beforeInit: function (chart: any) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.height += 40);
      };
    },
  };
  const maxChartYAxis = chartSecondValues ? Math.max(...chartSecondValues) * 1.2 : Math.max(...chartValues) * 1.2;
  const chartTooltip = {
    xAlign: 'center',
    yAlign: 'bottom',
    backgroundColor: 'white',
    borderColor: 'black',
    borderWidth: 1,
    displayColors: false,
    bodyColor: 'black',
    titleColor: 'black',
    bodyAlign: 'center',
    cornerRadius: 15,
    titleFont: { size: 12, weight: 'bold' },
    caretSize: 0,
    caretPadding: 8,
    padding: {
      top: 6,
      bottom: 0,
    },
    callbacks: {
      title: (value: Record<string, any>[]) => {
        const currencyFormat = currencyData ? '$ 0,0.00' : '0,0.00';
        return `   ${numeral(value[0].raw).format(currencyFormat)} ${suffix}    `;
      },
      label: () => null,
    },
  };

  const xAxes = {
    display: true,
    grid: {
      display: false,
      tickColor: '#FFFFFF',
    },
    ticks: {
      color: 'black',
      font: {
        size: 10,
      },
    },
  };

  const yAxes = {
    display: true,
    max: maxChartYAxis,
    grid: {
      color: '#818181',
      tickColor: '#FFFFFF',
    },
    border: {
      dash: [5, 5],
    },
    ticks: {
      font: {
        size: 10,
      },
      color: 'black',
      stepSize: stepSizeValue ? maxChartYAxis / stepSizeValue : maxChartYAxis / 2,
      callback: function (value: number, index: number) {
        const valueHigherThenThousand = value > 1000;
        const reducedValue = valueHigherThenThousand ? value / 1000 : value;
        return index > 0 ? `${numeral(reducedValue).format('0')}${valueHigherThenThousand ? 'k' : ''}` : null;
      },
    },
  };

  const valuesDataSet = [
    {
      label: legendLabel,
      type: 'bar',
      backgroundColor: barHighlight ? '#79ECA5' : '#C5C5C5',
      data: chartValues,
      datalabels: {
        labels: {
          title: null,
        },
      },
    },
  ];

  const secondValuesDataSet = [
    {
      label: legendSecondLabel,
      type: 'bar',
      backgroundColor: '#C5C5C5',
      data: chartSecondValues as number[],
      datalabels: {
        labels: {
          title: null,
        },
      },
    },
  ];

  const data = () => ({
    labels:
      chartSecondLabels && valuesDataSet[0].data.length === 0
        ? chartSecondLabels
        : chartSecondLabels && chartLabels
        ? chartLabels.concat(chartSecondLabels)
        : chartLabels,
    options: {
      barThickness: barThicknessInPixels,
      animation: animation,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: xAxes,
        y: yAxes,
      },
      plugins: {
        datalabels: {
          padding: 12,
          borderRadius: 30,
          backgroundColor: 'white',
          color: 'black',
          borderWidth: 1,
          borderColor: 'black',
        },
        legend: {
          display: legend,
          position: 'top',
          align: 'start',
          labels: {
            color: '#000',
            font: {
              family: 'Inter',
              size: 12,
            },
            usePointStyle: true,
            pointStyleWidth: 0,
          },
          onClick: (e: any) => e.stopPropagation(),
        },
        tooltip: chartTooltip,
      },
    },
    datasets:
      chartSecondValues && valuesDataSet[0].data.length === 0
        ? secondValuesDataSet
        : chartSecondValues
        ? valuesDataSet.concat(secondValuesDataSet)
        : valuesDataSet,
  });

  return (
    <div className="overflow-x-auto h-[17rem]">
      <div className={`h-full min-w-[25rem] ${className}`}>
        <BarChart
          plugins={[ChartDataLabels, legendMargin]}
          options={data().options}
          datasets={data().datasets}
          labels={data().labels}
        />
      </div>
    </div>
  );
};

export default BarChartComponent;
