import React, { useState } from 'react';

import { Layout } from '@clarke-energia/foton';

import { HOME_PATH } from '@routers/constants';
import { useCompiledResult } from '@hooks/use-enrgy-compiled';

import CompilationResultSkeleton from '@components/molecules/energy-compilation/compilation-skeleton';
import EnergyCompilationContent from '@components/organism/energy-compilation';
import { triggerButtonEvent } from './helper';

const EnergyPricePage: React.FC = () => {
  const { loading, savings, proposal, processName } = useCompiledResult();

  const [selectedScenario, setSelectedScenario] = useState<string>('Todas as unidades agrupadas');
  const [unitId, setUnitId] = useState<string | undefined>(undefined);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  if (loading) {
    return (
      <Layout loading={true} className="h-full" darkerBackground>
        <CompilationResultSkeleton />
      </Layout>
    );
  }

  const groupScenario = {
    label: 'Todas as unidades agrupadas',
    onClick: () => {
      setSelectedScenario('Todas as unidades agrupadas');
      setShowPopUp(false);
      setUnitId(undefined);
      triggerButtonEvent('Todas as unidades agrupadas');
    },
  };

  const unitScenario = proposal.units.map((unit) => {
    const unitName = unit.name;
    const unitId = unit.id;
    return {
      label: unitName,
      onClick: () => {
        setSelectedScenario(unitName);
        setShowPopUp(false);
        setUnitId(unitId);
        triggerButtonEvent(unitName);
      },
    };
  });

  const selectMenuItens = proposal.units.length > 1 ? [groupScenario, ...unitScenario] : unitScenario;

  return (
    <Layout
      title={`Cotação de Energia`}
      navigationHistoryLinks={[
        { label: 'Home', url: HOME_PATH },
        { label: 'Cotação de Energia', url: '#' },
      ]}
      className="h-full"
      darkerBackground
    >
      <EnergyCompilationContent
        processName={processName}
        proposal={proposal}
        savings={savings}
        unitId={unitId}
        selectMenu={{
          label: selectedScenario,
          items: selectMenuItens,
          showPopUp,
          onClick: () => {
            if (selectMenuItens.length === 1) {
              setShowPopUp(false);
              return;
            }
            setShowPopUp(true);
          },
        }}
      />
    </Layout>
  );
};

export default EnergyPricePage;
