import React from 'react';
import { Button, PopUpList, PopUpListProps } from '@clarke-energia/foton';

import { Units } from '@contexts/files/types';

import { capitalize } from '@utils/text';
import { IDocumentFilters } from '@pages/documents/documents-hub';
import PopUpSetPeriod from '../../molecules/popup-set-period';

interface FilesFilterBarProps {
  filters: IDocumentFilters | undefined;
  setFilters: (value: React.SetStateAction<IDocumentFilters | undefined>) => void;
  documentsTypes: string[];
  units: Units[] | undefined;
  triggerButtonEvent: (buttonText: string, destinationPath?: string) => void;
}

const FilesFilterBar: React.FC<FilesFilterBarProps> = ({
  setFilters,
  filters,
  documentsTypes,
  units,
  triggerButtonEvent,
}) => {
  const [documentTypeElements, setDocumentTypeElements] = React.useState<PopUpListProps['items']>();
  const [openDocumentTypePopUp, setOpenDocumentTypePopUp] = React.useState(false);

  const [unitElements, setUnitElements] = React.useState<PopUpListProps['items']>();
  const [openUnitFilterPopUp, setOpenUnitFilterPopUp] = React.useState(false);

  const [openDateFilterPopUp, setOpenDateFilterPopUp] = React.useState(false);

  const [someFilterUsed, setSomeFilterUsed] = React.useState<boolean>(false);

  const generalLabelParser = (label: string) => capitalize(label?.replaceAll('_', ' '), units);

  function createDocumentTypesElements() {
    const elements = documentsTypes.map((item) => ({
      label: generalLabelParser(item),
      onClick: () => {
        setOpenDocumentTypePopUp(false);
        setFilters((prev) => ({ ...prev, documentType: item }));
        setSomeFilterUsed(true);
        triggerButtonEvent(generalLabelParser(item));
      },
    }));
    setDocumentTypeElements(elements);
  }

  function createUnitsElements() {
    const elements = units?.map((unit) => ({
      label: generalLabelParser(unit.name),
      onClick: () => {
        setOpenUnitFilterPopUp(false);
        setFilters((prev) => ({ ...prev, unit: unit.id }));
        setSomeFilterUsed(true);
        triggerButtonEvent(generalLabelParser(unit.name));
      },
    }));
    setUnitElements(elements);
  }

  function clearFilters() {
    setFilters(undefined);
    setSomeFilterUsed(false);
    setOpenDocumentTypePopUp(false);
    setOpenUnitFilterPopUp(false);
    setOpenDateFilterPopUp(false);
  }

  React.useMemo(() => {
    createDocumentTypesElements();
    createUnitsElements();
  }, []);

  return (
    <div className="flex flex-col gap-5 w-fit">
      <h1 className="font-normal text-heading-xsmall">Resultado da pesquisa</h1>
      <div className="flex flex-row flex-wrap gap-4 justify-start w-full">
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="relative">
            <Button
              kind={filters?.documentType ? 'chip' : 'secondary'}
              label={
                filters?.documentType ? generalLabelParser(filters?.documentType) ?? 'Unidade' : 'Tipo de documento'
              }
              icon={openDocumentTypePopUp ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              iconPosition="right"
              size="small"
              onClick={() => {
                setOpenDocumentTypePopUp(!openDocumentTypePopUp);
                setOpenUnitFilterPopUp(false);
                setOpenDateFilterPopUp(false);
              }}
            />
            {documentsTypes && (
              <PopUpList
                items={documentTypeElements as []}
                show={openDocumentTypePopUp}
                className="overflow-y-auto absolute top-9 md:w-full h-fit max-h-[14rem] w-[80vw]"
              />
            )}
          </div>
          <div className="relative">
            <Button
              kind={filters?.unit ? 'chip' : 'secondary'}
              label={filters?.unit ? generalLabelParser(filters?.unit) ?? 'Unidade' : 'Unidade'}
              icon={openUnitFilterPopUp ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              iconPosition="right"
              size="small"
              onClick={() => {
                setOpenUnitFilterPopUp(!openUnitFilterPopUp);
                setOpenDocumentTypePopUp(false);
                setOpenDateFilterPopUp(false);
              }}
            />
            {units && (
              <PopUpList
                items={unitElements as []}
                show={openUnitFilterPopUp}
                className="overflow-y-auto absolute top-9 md:w-full h-fit max-h-[14rem] w-[80vw]"
              />
            )}
          </div>
          <div className="relative">
            <Button
              kind={filters?.month && filters?.year ? 'chip' : 'secondary'}
              label="Data"
              icon={openDateFilterPopUp ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              iconPosition="right"
              size="small"
              onClick={() => {
                setOpenDateFilterPopUp(!openDateFilterPopUp);
                setOpenUnitFilterPopUp(false);
                setOpenDocumentTypePopUp(false);
              }}
            />
            <PopUpSetPeriod
              show={openDateFilterPopUp}
              className="absolute top-9 w-[80vw] md:w-[10rem]"
              setFilters={setFilters}
              filters={filters}
              setSomeFilterUsed={setSomeFilterUsed}
            />
          </div>
        </div>
        <div>
          <Button kind="ghost" label="Limpar filtros" disabled={!someFilterUsed} size="small" onClick={clearFilters} />
        </div>
      </div>
    </div>
  );
};

export default FilesFilterBar;
