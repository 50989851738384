import { useContextSelector } from 'use-context-selector';
import { ConsumptionContext, ConsumptionContextType } from '@contexts/consumption';

export const useConsumption = (): ConsumptionContextType => {
  const setGroupId = useContextSelector(ConsumptionContext, (consumption) => consumption.setGroupId);
  const unitId = useContextSelector(ConsumptionContext, (consumption) => consumption.unitId);
  const units = useContextSelector(ConsumptionContext, (consumption) => consumption.units);
  const setUnitId = useContextSelector(ConsumptionContext, (consumption) => consumption.setUnitId);
  const monthYear = useContextSelector(ConsumptionContext, (consumption) => consumption.monthYear);
  const setMonthYear = useContextSelector(ConsumptionContext, (consumption) => consumption.setMonthYear);
  const setMonthlyControlMeasurementByUnitId = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.setMonthlyControlMeasurementByUnitId,
  );
  const monthlyControlMeasurementByUnitId = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.monthlyControlMeasurementByUnitId,
  );
  const unitConsumptionMeasuresPerHour = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.unitConsumptionMeasuresPerHour,
  );
  const getMonthlyControlMeasurementByUnitIdHandler = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.getMonthlyControlMeasurementByUnitIdHandler,
  );
  const setConsolidatedMeasurementDocId = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.setConsolidatedMeasurementDocId,
  );
  const consolidatedMeasurementById = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.consolidatedMeasurementById,
  );
  const openNotificationFeedBack = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.openNotificationFeedBack,
  );
  const setOpenNotificationFeedBack = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.setOpenNotificationFeedBack,
  );
  const openNotificationFeedBackSync = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.openNotificationFeedBackSync,
  );
  const setOpenNotificationFeedBackSync = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.setOpenNotificationFeedBackSync,
  );

  const consumptionProjectionByUnit = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.consumptionProjectionByUnit,
  );

  const totalMonthlyConsumptionByUnit = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.totalMonthlyConsumptionByUnit,
  );

  const monthlyGroupControlMeasurement = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.monthlyGroupControlMeasurement,
  );
  const setMonthlyGroupControlMeasurement = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.setMonthlyGroupControlMeasurement,
  );
  const setGroupConsolidatedMeasurementDocId = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.setGroupConsolidatedMeasurementDocId,
  );
  const groupConsolidatedMeasurementById = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.groupConsolidatedMeasurementById,
  );
  const consumptionProjectionByGroup = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.consumptionProjectionByGroup,
  );
  const totalMonthlyConsumptionByGroup = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.totalMonthlyConsumptionByGroup,
  );
  const invoicePreviewOfUnit = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.invoicePreviewOfUnit,
  );
  const invoicePreviewOfGroup = useContextSelector(
    ConsumptionContext,
    (consumption) => consumption.invoicePreviewOfGroup,
  );
  const pastInvoiceGroup = useContextSelector(ConsumptionContext, (consumption) => consumption.pastInvoiceGroup);
  const pastInvoiceUnit = useContextSelector(ConsumptionContext, (consumption) => consumption.pastInvoiceUnit);
  const loading = useContextSelector(ConsumptionContext, (consumption) => consumption.loading);

  return {
    setGroupId,
    unitId,
    setUnitId,
    units,
    monthYear,
    setMonthYear,
    setMonthlyControlMeasurementByUnitId,
    monthlyGroupControlMeasurement,
    setMonthlyGroupControlMeasurement,
    monthlyControlMeasurementByUnitId,
    unitConsumptionMeasuresPerHour,
    getMonthlyControlMeasurementByUnitIdHandler,
    setConsolidatedMeasurementDocId,
    setGroupConsolidatedMeasurementDocId,
    consolidatedMeasurementById,
    groupConsolidatedMeasurementById,
    openNotificationFeedBack,
    setOpenNotificationFeedBack,
    openNotificationFeedBackSync,
    setOpenNotificationFeedBackSync,
    consumptionProjectionByUnit,
    consumptionProjectionByGroup,
    totalMonthlyConsumptionByUnit,
    totalMonthlyConsumptionByGroup,
    invoicePreviewOfUnit,
    invoicePreviewOfGroup,
    pastInvoiceUnit,
    pastInvoiceGroup,
    loading,
  };
};
