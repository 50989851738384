import React from 'react';
import { Tooltip } from '@clarke-energia/foton';

import { IKickOffQuizInputs } from '@contexts/kick-off/types';

import { RadioFormField } from '../form/radio';
import { SelectFormField } from '../form/select';

interface IKickOffQuestion {
  title: string;
  description: JSX.Element | string;
  tooltipContent?: string;
  input: JSX.Element;
}

const KickOffQuestion = ({ title, description, tooltipContent, input }: IKickOffQuestion) => (
  <div className="mt-8 mb-8 w-full h-full lg:mb-10 lg:mt-13">
    <div className="flex gap-3 items-center mb-4">
      <p className="font-normal text-heading-xsmall">{title}</p>
      {tooltipContent && <Tooltip content={tooltipContent} />}
    </div>
    <p className="font-normal text-paragraph-medium">{description}</p>
    <div className="overflow-x-auto pt-2 pb-7 pl-2 mt-5 w-full h-fit overflow-y-clip">{input}</div>
  </div>
);

interface IKickOffQuizQuestionHandleChange {
  handleSaveQuizWhenChanged: () => void;
}

export const KickOffQuizFirstQuestion = ({ handleSaveQuizWhenChanged }: IKickOffQuizQuestionHandleChange) => (
  <KickOffQuestion
    title="Pergunta 1"
    description="Qual das duas opções abaixo mais se adequa ao seu perfil?"
    tooltipContent="No Mercado Livre de Energia, dependendo do modelo de contratação, você precisará ter algumas responsabilidades no mês a mês. Como, por exemplo, cuidar dos eventos financeiros, realizar alguns pagamentos mensais e realizar depósitos. Essas atividades mensais podem ficar a cargo da fornecedora."
    input={
      <RadioFormField<IKickOffQuizInputs>
        field="managementType"
        inputOptions={[
          {
            id: 'SOPHISTICATED',
            value: 'SOPHISTICATED',
            optionLabel: 'Ter mais economia, mesmo que meu time tenha mais trabalho mensalmente com burocracias.',
          },
          {
            id: 'SIMPLIFIED',
            value: 'SIMPLIFIED',
            optionLabel: 'Não se preocupar com nada em troca de um pouco menos de economia.',
          },
        ]}
        options={{
          onChange: () => handleSaveQuizWhenChanged && handleSaveQuizWhenChanged(),
        }}
      />
    }
  />
);

export const KickOffQuizSecondQuestion = ({ handleSaveQuizWhenChanged }: IKickOffQuizQuestionHandleChange) => (
  <KickOffQuestion
    title="Pergunta 2"
    description={
      <>
        Numa escala de <span className="font-bold">Conservador à Arrojado</span>, como você se considera? Um perfil{' '}
        <span className="font-bold">Conservador</span> prefere mais segurança e previsibilidade na economia mensal. Já
        um perfil <span className="font-bold">Arrojado</span> aceita a chance de variação mês a mês para possivelmente
        alcançar uma economia maior.
      </>
    }
    tooltipContent="O preço da energia varia todos os dias. Essa variação pode ocorrer por diversos motivos, como por exemplo o nível de água nos reservatórios. Desse modo, as empresas podem brincar essa variação de preço sendo um perfil mais conservador, ou seja, preferindo a segurança através de marcar um preço médio. Ou arrojado, que tende a se aventurar com a variação para conseguir economias maiores."
    input={
      <div className="flex gap-6 items-end">
        <p className="-my-2">Conservador</p>
        <RadioFormField<IKickOffQuizInputs>
          field="profileType"
          horizontalLayout
          inputOptions={[
            {
              id: 'CONSERVATIVE_PLUS',
              value: 'CONSERVATIVE_PLUS',
              optionLabel: '1',
            },
            {
              id: 'CONSERVATIVE',
              value: 'CONSERVATIVE',
              optionLabel: '2',
            },
            {
              id: 'MODERATE',
              value: 'MODERATE',
              optionLabel: '3',
            },
            {
              id: 'BOLD',
              value: 'BOLD',
              optionLabel: '4',
            },
            {
              id: 'BOLD_PLUS',
              value: 'BOLD_PLUS',
              optionLabel: '5',
            },
          ]}
          options={{
            onChange: () => handleSaveQuizWhenChanged && handleSaveQuizWhenChanged(),
          }}
        />
        <p className="-my-2">Arrojado</p>
      </div>
    }
  />
);

const projectedPercentageChangeInConsumptionPerYearInputOptions = [
  {
    id: '-1.0',
    value: -1.0,
    optionLabel: '-100%',
  },
  {
    id: '-0.7',
    value: -0.7,
    optionLabel: '-70%',
  },
  {
    id: '-0.35',
    value: -0.35,
    optionLabel: '-35%',
  },
  {
    id: '0.0',
    value: 0.0,
    optionLabel: '0',
  },
  {
    id: '0.35',
    value: 0.35,
    optionLabel: '+35%',
  },
  {
    id: '0.7',
    value: 0.7,
    optionLabel: '+70%',
  },
  {
    id: '1.0',
    value: 1.0,
    optionLabel: '+100%',
  },
  {
    id: 'undefined',
    value: undefined,
    optionLabel: 'Não sei',
  },
];

interface IProjectedPercentageChangeInConsumptionByYearInput extends IKickOffQuizQuestionHandleChange {
  year: number;
}

const ProjectedPercentageChangeInConsumptionByYearInput = ({
  year,
  handleSaveQuizWhenChanged,
}: IProjectedPercentageChangeInConsumptionByYearInput) => {
  const dynamicFieldNameByYear = `projectedPercentageChangeInConsumptionPerYear.${year}` as keyof IKickOffQuizInputs;

  return (
    <div className="flex flex-col gap-0 mt-5 md:flex-row md:gap-6 md:items-center">
      <p className="mt-2">{year}</p>
      <SelectFormField<IKickOffQuizInputs>
        field={dynamicFieldNameByYear}
        id={dynamicFieldNameByYear}
        placeholder="Selecione uma opção"
        inputOptions={projectedPercentageChangeInConsumptionPerYearInputOptions}
        options={{
          onChange: () => handleSaveQuizWhenChanged && handleSaveQuizWhenChanged(),
        }}
      />
    </div>
  );
};

export const KickOffQuizThirdQuestion = ({ handleSaveQuizWhenChanged }: IKickOffQuizQuestionHandleChange) => (
  <KickOffQuestion
    title="Pergunta 3"
    description={
      <>
        No decorrer dos próximos 3 anos, existe alguma{' '}
        <span className="font-bold">expectativa de alteração no consumo de energia da empresa?</span> Por exemplo, para
        casos de expansão ou diminuição da operação. Caso não saiba, selecione ”Não sei”.
      </>
    }
    tooltipContent="No Mercado Livre de Energia é necessário comprar a energia que será utilizada nos próximos anos. Por meio do estudo do histórico de consumo, conseguimos estabelecer a quantidade de energia necessária, no entanto precisamos levar em consideração algumas expectativas de futuro como expansão e consequente aumento do consumo, ou diminuição da operação e consequente redução do consumo de energia."
    input={
      <div className="flex flex-col gap-6">
        <ProjectedPercentageChangeInConsumptionByYearInput
          year={2023}
          handleSaveQuizWhenChanged={handleSaveQuizWhenChanged}
        />
        <ProjectedPercentageChangeInConsumptionByYearInput
          year={2024}
          handleSaveQuizWhenChanged={handleSaveQuizWhenChanged}
        />
        <ProjectedPercentageChangeInConsumptionByYearInput
          year={2025}
          handleSaveQuizWhenChanged={handleSaveQuizWhenChanged}
        />
      </div>
    }
  />
);

export const KickOffQuizFourthQuestion = ({ handleSaveQuizWhenChanged }: IKickOffQuizQuestionHandleChange) => (
  <KickOffQuestion
    title="Pergunta 4"
    description={
      <>
        Você está disposto a{' '}
        <span className="font-bold">
          comprar energia de 1% a 5% mais cara em troca de um certificado internacionalmente reconhecido de consumidor
          de energia 100% renovável?
        </span>
      </>
    }
    tooltipContent="A CLARKE irá ajudar vocês a se conectarem com um fornecedor de energia renovável, através do mercado livre, e mensalmente iremos calcular para vocês o impacto que isso terá no meio ambiente, através da redução da emissão de CO2. Porém para algumas empresas isso ainda não é o bastante, e elas optam também pela emissão de um certificado chamado I-REC (Renewable Energy Certificate), que é aceito em todo o mundo e assegura que a energia consumida é renovável. Esse certificado possui um custo de aproximadamente ~R$5/MWh."
    input={
      <RadioFormField<IKickOffQuizInputs>
        field="iRecCertification"
        inputOptions={[
          {
            id: 'true',
            value: 'true',
            optionLabel: 'Sim',
          },
          {
            id: 'false',
            value: 'false',
            optionLabel: 'Não',
          },
        ]}
        options={{
          onChange: () => handleSaveQuizWhenChanged && handleSaveQuizWhenChanged(),
        }}
      />
    }
  />
);
