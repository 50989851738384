import React from 'react';
import { Button, FeedbackNotification, Modal } from '@clarke-energia/foton';

import useKickOff from '@hooks/use-kick-off';
import { INotificationFeedbackContent, KickOffNotificationFeedbackContents } from '@utils/constants/common';

import { KickOffSkeleton } from '@components/molecules/skeleton/kick-off-skeleton';
import {
  KickOffDiagnosisPreviewAccordion,
  KickOffDiagnosisPreviewHeader,
} from '@components/atoms/kick-off/kick-off-diagnosis-preview';
import KickOffLayout from '@components/molecules/kick-off/kick-off-layout';

import FormUpdateSuggestedProduct from '../../components/molecules/kick-off/diagnosis/preview/form-update-suggested-product';
import { KickOffDiagnosisPreviewModalData } from '../../components/molecules/kick-off/diagnosis/preview/types';
import { parseSuggestedProductContentIndexer } from '../../components/molecules/kick-off/diagnosis/preview/parser';

const KickOffDiagnosisPreview: React.FC = () => {
  const { kickOff, loading, submitKickOffQuizHandler, setFlowSection } = useKickOff();

  const [updateSuggestedProductModalData, setUpdateSuggestedProductModalData] =
    React.useState<KickOffDiagnosisPreviewModalData>();

  const [messageNotificationFeedBack, setMessageNotificationFeedBack] = React.useState<INotificationFeedbackContent>();

  const suggestedProduct = kickOff?.diagnosis?.suggestedProduct;
  const suggestedProductContentIndexer = suggestedProduct && parseSuggestedProductContentIndexer(suggestedProduct);
  const isOnMobileScreen = window.innerWidth <= 768;

  const handleSubmitQuizForFinalDiagnosis = () => {
    submitKickOffQuizHandler()
      .then((response) => {
        if (response.data?.submitKickOffQuiz) setFlowSection('DIAGNOSIS_RESULT');
        else setMessageNotificationFeedBack(KickOffNotificationFeedbackContents.SUBMIT_DIAGNOSIS_FAILED);
      })
      .catch(() => setMessageNotificationFeedBack(KickOffNotificationFeedbackContents.SUBMIT_DIAGNOSIS_FAILED));
  };

  return (
    <KickOffLayout>
      {!suggestedProduct ? (
        <KickOffSkeleton />
      ) : (
        <>
          <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
            {messageNotificationFeedBack && (
              <FeedbackNotification
                label={messageNotificationFeedBack.label}
                message={messageNotificationFeedBack.message}
                kind={messageNotificationFeedBack.kind}
                onCloseNotification={() => setMessageNotificationFeedBack(undefined)}
              />
            )}
          </div>
          <div className="flex flex-col gap-7 w-full">
            <KickOffDiagnosisPreviewHeader group={kickOff?.commercialGroup?.name} />
            <div className="flex flex-col gap-5 w-full">
              {suggestedProductContentIndexer?.map((productFeature, index) => (
                <KickOffDiagnosisPreviewAccordion
                  key={index}
                  setUpdateSuggestedProductModalData={setUpdateSuggestedProductModalData}
                  {...productFeature}
                />
              ))}
            </div>
            <div className="flex gap-4 justify-start items-center mt-6 mb-6 w-full md:mt-9 md:mb-11">
              <Button
                label={'Voltar'}
                kind={'secondary'}
                onClick={() => setFlowSection('QUIZ')}
                type="button"
                disabled={loading}
                size={isOnMobileScreen ? 'small' : 'medium'}
              />
              <Button
                label={'Enviar resultado'}
                kind={'primary'}
                onClick={handleSubmitQuizForFinalDiagnosis}
                type="button"
                loading={loading}
                size={isOnMobileScreen ? 'small' : 'medium'}
              />
            </div>
            {updateSuggestedProductModalData && (
              <Modal
                open={updateSuggestedProductModalData !== undefined}
                setOpen={() => setUpdateSuggestedProductModalData(undefined)}
                title={updateSuggestedProductModalData.title}
                description={updateSuggestedProductModalData.description}
                className="self-center"
              >
                <FormUpdateSuggestedProduct
                  inputs={updateSuggestedProductModalData.inputs}
                  dataToUpdate={suggestedProduct}
                  onSuccess={() => setUpdateSuggestedProductModalData(undefined)}
                  onCancel={() => setUpdateSuggestedProductModalData(undefined)}
                  setMessageNotificationFeedBack={setMessageNotificationFeedBack}
                />
              </Modal>
            )}
          </div>
        </>
      )}
    </KickOffLayout>
  );
};

export default KickOffDiagnosisPreview;
