import React, { useState } from 'react';
import moment from 'moment';
import { ButtonIcon, ColumnOptions, HeroIcon, Layout, Table } from '@clarke-energia/foton';

import FilesFilterBar from '@components/molecules/files-filter-bar';
import FolderNavigationTree from '@components/molecules/folder-navigation-tree';
import { DocumentSkeleton } from '@components/molecules/skeleton/documents-skeleton';

import { File, FileData } from '@contexts/files/types';
import { useUserInfo } from '@hooks/use-user-info';
import { useFile } from '@hooks/use-file';
import { FILES_PATH, HOME_PATH } from '@routers/constants';
import { formatFilePath } from '@utils/text';

import { getDocumentsTypesFromFilesList, getFilenameFromPath, triggerButtonEvent } from './helper';

export interface IDocumentFilters {
  documentType?: string;
  unit?: string;
  month?: number;
  year?: number;
}

const DocumentsHub: React.FC = () => {
  const { user, isFetchingUser } = useUserInfo();
  const { setGroupId, groupFiles, units, isFetchingFiles } = useFile();

  const [localFilePath, setFilePath] = useState<string>('documentos/');
  const [tableData, setTableData] = useState<File[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<File[]>([]);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [treeMode, setTreeMode] = useState<boolean>(true);

  const [documentsTypes, setDocumentsTypes] = useState<string[]>([]);
  const [filters, setFilters] = useState<IDocumentFilters | undefined>();

  const handleRowInteraction = (item: string) => {
    if (item.includes('.')) {
      return;
    } else if (parseInt(item)) {
      setFilePath(`${localFilePath}${item}/`);
    } else {
      setFilePath(`${localFilePath}${item.replaceAll(' ', '_')}/`);
    }
  };

  const filesTableColumns: ColumnOptions<File>[] = [
    {
      header: 'Nome',
      renderAsElement: (entry: File) => {
        return (
          <HeroIcon
            icon={entry.filePath.includes('.') ? 'DocumentTextIcon' : 'FolderIcon'}
            className="flex-shrink-0 w-5 h-5 text-gray-500"
            aria-hidden="true"
            color="primary-60"
          />
        );
      },
    },
    { accessor: 'filePath', fixedWidthInRem: 100, renderAsElement: (entry: File) => entry.filePath },
    {
      fixedWidthInRem: 5,
      renderAsElement: (entry: File) => {
        return (
          <button
            onClick={() => {
              entry.filePath.includes('.') ? window.open(entry.url) : handleRowInteraction(entry.filePath);
              triggerButtonEvent(entry.filePath.includes('.') ? 'Baixar' : 'Abrir');
            }}
          >
            {entry.filePath.includes('.') ? 'Baixar' : 'Abrir'}
          </button>
        );
      },
    },
  ];

  const removeDuplicated = (data: File[] | undefined) => {
    const uniqueData: FileData[] = [];
    data?.forEach((item) => {
      if (!uniqueData.some((file) => file.filePath === item.filePath)) {
        uniqueData.push({ filePath: item.filePath, url: item.url });
      }
    });
    return uniqueData;
  };

  const folderNavigationHandler = () => {
    const filesFromLocalPath = groupFiles.filter((item) => item.filePath.includes(localFilePath));
    const parsedFilesFromLocalPath: File[] = removeDuplicated(
      filesFromLocalPath.map((item) => {
        return {
          ...item,
          filePath: formatFilePath(item.filePath.replace(localFilePath, '')),
          url: item.url,
        };
      }),
    ).map((item, index) => {
      return {
        id: index.toString(),
        filePath: item.filePath,
        url: item.url,
      };
    });
    if (parsedFilesFromLocalPath) setTableData(parsedFilesFromLocalPath);
  };

  const handleSearchFileName = (filesList: File[]) => {
    const searchFilteredTableData = filesList
      .filter((item) => {
        return searchText ? getFilenameFromPath(item.filePath).includes(searchText.toLowerCase()) : item;
      })
      .filter((item) => {
        return getFilenameFromPath(item.filePath).includes('.') as boolean;
      })
      .map((item) => ({
        ...item,
        filePath: getFilenameFromPath(item.filePath),
      }));

    setTableData(searchFilteredTableData);
  };

  const handleFiltersChange = () => {
    const documentTypeFilter = (documentType: string) => documentType === filters?.documentType;
    const unitNicknameFilter = (unitNickname: string) => unitNickname === filters?.unit;
    const periodFilter = (period: string) =>
      moment(period).month() + 1 === filters?.month && moment(period).year() === filters.year;

    const filteredData: File[] = tableData
      .filter((item) => (filters?.documentType ? documentTypeFilter(item.documentType as string) : item))
      .filter((item) => (filters?.unit ? unitNicknameFilter(item.unitId as string) : item))
      .filter((item) => (filters?.month && filters.year ? periodFilter(item.createdAt as string) : item));

    setFilteredTableData(filteredData);
  };

  const setTableDataInEffect = (): File[] => {
    if (filters?.documentType || filters?.unit || (filters?.month && filters.year)) return filteredTableData;
    else return tableData;
  };

  React.useMemo(() => {
    if (user?.group) {
      setGroupId(user.group.id);
    }
  }, [user]);

  React.useEffect(() => {
    if (groupFiles) {
      setDocumentsTypes(getDocumentsTypesFromFilesList(groupFiles));
    }
  }, [groupFiles]);

  React.useEffect(() => {
    if (groupFiles && treeMode) {
      setFilters(undefined);
      folderNavigationHandler();
    } else if (groupFiles && (!treeMode || searchText)) {
      setTreeMode(false);
      handleSearchFileName(groupFiles);
    }
  }, [searchText, filters, treeMode, localFilePath, groupFiles]);

  React.useEffect(() => {
    handleFiltersChange();
  }, [filters]);

  if ((isFetchingFiles || isFetchingUser) && groupFiles.length === 0) {
    return (
      <Layout loading>
        <DocumentSkeleton />
      </Layout>
    );
  }

  return (
    <Layout
      title="Documentos"
      navigationHistoryLinks={[
        { label: 'Home', url: HOME_PATH },
        { label: 'Documentos', url: FILES_PATH },
      ]}
      className="relative h-full"
      searchBar={{
        onTypedTermChangedCallback: (term: string) => setSearchText(term),
        placeholder: 'Buscar',
      }}
      darkerBackground
    >
      <div className="flex flex-col col-span-full gap-6 mx-5 h-screen lg:mx-0 min-h-[20rem]">
        <div className="flex flex-row flex-wrap gap-3 justify-between items-center">
          {treeMode ? (
            <div className="flex items-start">
              {localFilePath.split('/').map((item, index) => {
                return (
                  <FolderNavigationTree
                    key={index}
                    index={index}
                    localFilePath={localFilePath}
                    setFilePath={setFilePath}
                    title={item}
                  />
                );
              })}
            </div>
          ) : (
            <FilesFilterBar
              setFilters={setFilters}
              filters={filters}
              documentsTypes={documentsTypes}
              units={units}
              triggerButtonEvent={triggerButtonEvent}
            />
          )}
          <div className="flex justify-start self-end w-fit">
            <ButtonIcon
              kind="primary"
              icon={treeMode ? 'FunnelIcon' : 'FolderIcon'}
              onClick={() => {
                setTreeMode((previous) => !previous);
                triggerButtonEvent('Filtro');
              }}
              className="self-end"
            />
          </div>
        </div>

        <Table
          className="col-span-full"
          data={setTableDataInEffect()}
          tableColumns={filesTableColumns}
          onRowDoubleClickAction={(entry: File) => handleRowInteraction(entry.filePath)}
        />
      </div>
    </Layout>
  );
};

export default DocumentsHub;
