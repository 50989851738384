import React from 'react';

import KickOffDiagnosisImage from '@assets/kick-off-diagnosis.png';

const KickOffDiagnosisHeader = () => (
  <div>
    <img className="w-full h-30 rounded-medium" src={KickOffDiagnosisImage} alt="Renewable Energy" />
    <h1 className="mt-6 mb-4 w-full font-medium text-left md:mb-1 text-heading-small md:text-heading-large">
      Características do contrato de energia enviadas!
    </h1>
    <p className="mb-6 font-medium md:mb-8 text-paragraph-small md:text-paragraph-medium">
      Muito obrigado pela dedicação à definição do seu produto de energia. Com essas características podemos{' '}
      <span className="font-bold">
        abrir o processo de cotação com as fornecedoras e agilizar a contratação da sua energia.{' '}
      </span>{' '}
      Estamos à disposição para qualquer dúvida.
    </p>
  </div>
);

export default KickOffDiagnosisHeader;
