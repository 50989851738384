import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from '@components/molecules/general/sidebar';
import NotificationSlideOver from '@components/molecules/notification-slideover';

const ScreenLayout: React.FC = () => {
  const [isMenuCollapsed, setIsMenuCollapsed] = React.useState(false);

  return (
    <div className="flex flex-col h-screen md:flex-row">
      <div className="relative z-20 bg-white w-fit">
        <SideBar isMenuCollapsed={isMenuCollapsed} setIsMenuCollapsed={setIsMenuCollapsed} />
        <NotificationSlideOver isMenuCollapsed={isMenuCollapsed} />
      </div>
      <div className="overflow-auto h-full w-full bg-[#FAFAFA] relative">
        <Outlet />
      </div>
    </div>
  );
};

export default ScreenLayout;
