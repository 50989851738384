import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ptbr from 'dayjs/locale/pt-br';

dayjs.extend(customParseFormat);

/**
 * PT-BR configured dayjs module instance.
 * */
export const $dayjs = dayjs;

export const parseStringToDate = (str: string): Date =>
  dayjs(str, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD'], true).toDate();

export const formatDate = (date: Date, template = 'DD MMM YYYY') => dayjs(date).locale(ptbr).format(template);

export const formatDateFromString = (dateStr: string, inputFormat: string, outputFormat = 'DD/MM/YYYY, HH:mm') =>
  dayjs(dateStr, inputFormat, 'pt-br').format(outputFormat);
