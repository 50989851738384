import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Tabs } from '@clarke-energia/foton';

import { HOME_PATH } from '@routers/constants';
import { InformationMessagesByCompilationResultsMoment } from '@utils/constants/common';

import { BidWithSavings, Proposal } from '@contexts/compilation-results/types';
import MessageComponent from '@components/molecules/general/message-component';
import ScopeDisplay from '@components/molecules/energy-compilation/scope-content-display';
import CompareProposalsHeader from '@components/molecules/energy-compilation/compilation-header';
import ProcessInfoDisplay from '@components/molecules/energy-compilation/process-info-display';
import { parsedBidsData } from '@pages/energy-price/helper';

type SelectMenu = {
  label: string;
  items: {
    label: string;
    onClick: () => void;
  }[];
  showPopUp: boolean;
  onClick: () => void;
};

interface EnergyPriceContentProps {
  savings: BidWithSavings[];
  proposal: Proposal;
  processName: string | null;
  unitId: string | undefined;
  selectMenu: SelectMenu;
}

const EnergyCompilationContent: React.FC<EnergyPriceContentProps> = ({
  savings,
  proposal,
  processName,
  unitId,
  selectMenu,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  const bidsInsideScope = parsedBidsData(savings, proposal, unitId, true);
  const bidsNotInsideScope = parsedBidsData(savings, proposal, unitId, false);

  return (
    <div className="col-span-full lg:px-0 px-[16px]">
      {!savings.length ? (
        <MessageComponent
          description={InformationMessagesByCompilationResultsMoment.COMPILED_RESULT_EMPTY.description}
          title={InformationMessagesByCompilationResultsMoment.COMPILED_RESULT_EMPTY.title}
          mainButtonLabel={InformationMessagesByCompilationResultsMoment.COMPILED_RESULT_EMPTY.mainButtonLabel}
          mainButtonAction={() => navigate(HOME_PATH)}
        />
      ) : (
        <>
          <ProcessInfoDisplay proposal={proposal} setShowModal={setShowModal} showModal={showModal} />
          <div className="grid grid-cols-7">
            <CompareProposalsHeader processName={processName} />
          </div>
          <div className="pb-20">
            <Tabs
              selectMenu={selectMenu}
              actionButtons={[
                {
                  buttonType: 'buttonInline',
                  label: 'Informações do escopo',
                  kind: 'primary',
                  className: 'px-2',
                  onClick: () => setShowModal(true),
                },
              ]}
              tabs={[
                { label: 'Dentro do escopo', children: <ScopeDisplay bids={bidsInsideScope} type="INSIDE" /> },
                { label: 'Fora do escopo', children: <ScopeDisplay bids={bidsNotInsideScope} type="OUTSIDE" /> },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EnergyCompilationContent;
