import { GroupsByUser, GroupsUsersGraphQLData, UserInfo, UsersListGraphQLData } from './types';

export class UsersManager {
  rawData: UsersListGraphQLData;
  users: UserInfo[];

  constructor(rawData: UsersListGraphQLData) {
    this.rawData = rawData;
    this.users = rawData.allUsers;
  }
}

export class GroupsUsersManager {
  rawData: GroupsUsersGraphQLData;
  groupsByUser: GroupsByUser[];

  constructor(rawData: GroupsUsersGraphQLData) {
    this.rawData = rawData;
    this.groupsByUser = rawData.getGroupsByUser;
  }
}
