import React from 'react';
import numeral from 'numeral';
import { Layout, ProgressGadget } from '@clarke-energia/foton';
import { useNavigate } from 'react-router-dom';

import { useMigrationReport } from '@hooks/use-migration-report';
import { useUserInfo } from '@hooks/use-user-info';

import { ATTENDANCE_PATH, HOME_PATH, MIGRATION_REPORT_PATH } from '@routers/constants';
import { InformationMessagesByMigrationMoment } from '@utils/constants/common';

import PageSectionGenerator from '@components/molecules/general/page-section-generator';
import MessageComponent from '@components/molecules/general/message-component';
import { HubSkeleton } from '@components/molecules/skeleton/hub-skeleton';

import { getConcludedMigrationUnits, triggerButtonEvent } from './helper';
import { useUnitsEconomy } from '@hooks/use-units-economy';

const UnitMigrationReportsHub: React.FC = () => {
  const navigate = useNavigate();
  const { user, isFetchingUser } = useUserInfo();
  const { migrationReportByGroup, setGroupId: setMigrationReportGroupId, isFetchingMigration } = useMigrationReport();
  const { setGroupId: setUnitsEconomyGroupId, unitsEconomyReports } = useUnitsEconomy();

  const groupConclusionPercentageFormatted = numeral(migrationReportByGroup?.conclusionPercentage).format('% 0');

  React.useMemo(() => {
    if (user.group?.id) {
      setMigrationReportGroupId(user.group?.id);
      setUnitsEconomyGroupId(user.group?.id);
    }
  }, [user]);

  if (isFetchingMigration || isFetchingUser) {
    return (
      <Layout loading>
        <HubSkeleton />
      </Layout>
    );
  }

  return (
    <Layout
      title="Migração para Mercado Livre"
      headerTags={[
        {
          color: 'green',
          kind: 'default',
          label: `${groupConclusionPercentageFormatted} Concluído`,
          size: 'medium',
        },
      ]}
      navigationHistoryLinks={[
        { label: 'Home', url: HOME_PATH },
        { label: 'Migração', url: MIGRATION_REPORT_PATH },
      ]}
      darkerBackground
    >
      {Object.keys(migrationReportByGroup)?.length > 0 ? (
        <>
          {migrationReportByGroup?.units?.length > 0 && groupConclusionPercentageFormatted !== '100%' && (
            <PageSectionGenerator title="Progresso da migração por unidade">
              <div className="grid grid-cols-1 gap-6 w-full md:grid-cols-2 xl:grid-cols-4">
                {migrationReportByGroup?.units
                  .filter((item) => item.conclusionPercentage !== 1)
                  .map((unit) => (
                    <div key={unit.thirdPartyId} className="col-span-1">
                      <ProgressGadget
                        actionButtons={[
                          {
                            buttonType: 'buttonIcon',
                            icon: 'ArrowRightIcon',
                            onClick: () => {
                              const migrationReportUnitPath = `${MIGRATION_REPORT_PATH}/${unit.thirdPartyId}`;
                              navigate(migrationReportUnitPath);
                              triggerButtonEvent(
                                'Relatório de Migração da Unidade em andamento',
                                migrationReportUnitPath,
                              );
                            },
                            kind: 'ghost',
                          },
                        ]}
                        description="Status de Migração"
                        progressValue={unit.conclusionPercentage}
                        title={unit.nickname}
                      />
                    </div>
                  ))}
              </div>
            </PageSectionGenerator>
          )}
          {getConcludedMigrationUnits(migrationReportByGroup?.units) && (
            <PageSectionGenerator title="Unidades com migração concluída" className="mt-8">
              <div className="grid grid-cols-1 gap-6 w-full md:grid-cols-2 xl:grid-cols-4">
                {getConcludedMigrationUnits(migrationReportByGroup?.units)?.map((unit) => (
                  <div key={unit.thirdPartyId} className="col-span-1">
                    <ProgressGadget
                      className="py-6"
                      actionButtons={[
                        {
                          buttonType: 'buttonIcon',
                          icon: 'ArrowRightIcon',
                          onClick: () => {
                            const migrationReportUnitPath = `${MIGRATION_REPORT_PATH}/${unit.thirdPartyId}`;
                            navigate(migrationReportUnitPath);
                            triggerButtonEvent('Relatório de Migração da Unidade concluído', migrationReportUnitPath);
                          },
                          kind: 'ghost',
                        },
                      ]}
                      description="Status de Migração"
                      progressValue={unit.conclusionPercentage}
                      title={unit.nickname}
                    />
                  </div>
                ))}
              </div>
            </PageSectionGenerator>
          )}
        </>
      ) : Object.keys(unitsEconomyReports).length > 0 ? (
        <MessageComponent
          title={InformationMessagesByMigrationMoment.MIGRATION_CONCLUDED.title}
          description={InformationMessagesByMigrationMoment.MIGRATION_CONCLUDED.description}
          mainButtonLabel={InformationMessagesByMigrationMoment.MIGRATION_CONCLUDED.mainButtonLabel}
          mainButtonAction={() => {
            navigate(ATTENDANCE_PATH);
            triggerButtonEvent(InformationMessagesByMigrationMoment.MIGRATION_CONCLUDED.title, ATTENDANCE_PATH);
          }}
        />
      ) : (
        <MessageComponent
          title={InformationMessagesByMigrationMoment.MIGRATION_NOT_STARTED.title}
          description={InformationMessagesByMigrationMoment.MIGRATION_NOT_STARTED.description}
          mainButtonLabel={InformationMessagesByMigrationMoment.MIGRATION_NOT_STARTED.mainButtonLabel}
          mainButtonAction={() => {
            navigate(ATTENDANCE_PATH);
            triggerButtonEvent(InformationMessagesByMigrationMoment.MIGRATION_NOT_STARTED.title, ATTENDANCE_PATH);
          }}
        />
      )}
    </Layout>
  );
};

export default UnitMigrationReportsHub;
