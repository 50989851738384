import {
  MonthlyControlMeasurementByUnitIdGraphQLData,
  MonthlyControlMeasurementByUnitId,
  ConsolidatedMeasurementByIdGraphQLData,
  ConsolidatedMeasurement,
  UnitConsumptionMeasuresPerHourGraphQLData,
  UnitConsumptionMeasuresPerHour,
  UnitsGraphQLData,
  Units,
  AllMeasuringPointByGroupGraphQLData,
  UnitsIdWithMeasuringPoint,
  ConsumptionProjectionByUnitIdGraphQLData,
  IConsumptionProjection,
  AllTotalConsumptionByUnitIdGraphQLData,
  ITotalMonthlyConsumption,
  ConsumptionProjectionByGroupIdGraphQLData,
  IGroupConsumptionProjection,
  MonthlyGroupControlMeasurement,
  MonthlyGroupControlMeasurementGraphQLData,
  GroupConsolidatedMeasurementByIdGraphQLData,
  GroupConsolidatedMeasurement,
  AllTotalConsumptionByGroupIdGraphQLData,
  GetEconomyReportByGroupAndDateGraphQLData,
} from './types';
import { settingGroupAndUnitInvoice } from './parser';

export class ConsolidatedMeasurementByIdManager {
  rawData: ConsolidatedMeasurementByIdGraphQLData;
  consolidateMeasurementById: ConsolidatedMeasurement;

  constructor(rawData: ConsolidatedMeasurementByIdGraphQLData) {
    this.rawData = rawData;
    this.consolidateMeasurementById = rawData.getConsolidatedMeasurementById;
  }
}

export class GroupConsolidatedMeasurementByIdManager {
  rawData: GroupConsolidatedMeasurementByIdGraphQLData;
  getGroupConsolidatedMeasurementById: GroupConsolidatedMeasurement;

  constructor(rawData: GroupConsolidatedMeasurementByIdGraphQLData) {
    this.rawData = rawData;
    this.getGroupConsolidatedMeasurementById = rawData.getGroupConsolidatedMeasurementById;
  }
}

export class MonthlyControlMeasurementByUnitIdManager {
  rawData: MonthlyControlMeasurementByUnitIdGraphQLData;
  monthlyControlMeasurementByUnitId: Array<MonthlyControlMeasurementByUnitId>;

  constructor(rawData: MonthlyControlMeasurementByUnitIdGraphQLData) {
    const nonZeroConsumption = rawData.getMonthlyControlMeasurementByUnitId.find(
      (control) => control.totalConsumption != 0,
    );
    this.rawData = rawData;
    if (nonZeroConsumption) {
      this.monthlyControlMeasurementByUnitId = rawData.getMonthlyControlMeasurementByUnitId;
    } else {
      this.monthlyControlMeasurementByUnitId = [];
    }
  }
}

export class MonthlyGroupControlMeasurementManager {
  rawData: MonthlyGroupControlMeasurementGraphQLData;
  getMonthlyControlMeasurementByGroupId: Array<MonthlyGroupControlMeasurement>;

  constructor(rawData: MonthlyGroupControlMeasurementGraphQLData) {
    const nonZeroConsumption = rawData.getMonthlyControlMeasurementByGroupId.find(
      (control) => control.totalConsumption != 0,
    );
    this.rawData = rawData;
    if (nonZeroConsumption) {
      this.getMonthlyControlMeasurementByGroupId = rawData.getMonthlyControlMeasurementByGroupId;
    } else {
      this.getMonthlyControlMeasurementByGroupId = [];
    }
  }
}

export class UnitConsumptionMeasuresPerHourManager {
  rawData: UnitConsumptionMeasuresPerHourGraphQLData;
  unitConsumptionMeasuresPerHour: Array<UnitConsumptionMeasuresPerHour>;

  constructor(rawData: UnitConsumptionMeasuresPerHourGraphQLData) {
    this.rawData = rawData;
    this.unitConsumptionMeasuresPerHour = rawData.getUnitConsumptionMeasuresPerHour;
  }
}
export class UnitsManager {
  rawData: UnitsGraphQLData;
  units: Array<Units>;

  constructor(rawData: UnitsGraphQLData) {
    this.rawData = rawData;
    this.units = rawData.group.units;
  }
}

export class UnitsWithMeasuringPointManager {
  rawData: AllMeasuringPointByGroupGraphQLData;
  getMeasuringPointByGroupId: Array<UnitsIdWithMeasuringPoint>;

  constructor(rawData: AllMeasuringPointByGroupGraphQLData) {
    this.rawData = rawData;
    this.getMeasuringPointByGroupId = rawData.getMeasuringPointByGroupId;
  }
}

export class ConsumptionProjectionByUnitIdManager {
  rawData: ConsumptionProjectionByUnitIdGraphQLData;
  getMonthlyConsumptionProjectionByUnitId: IConsumptionProjection;

  constructor(rawData: ConsumptionProjectionByUnitIdGraphQLData) {
    this.rawData = rawData;
    this.getMonthlyConsumptionProjectionByUnitId = rawData.getMonthlyConsumptionProjectionByUnitId;
  }
}

export class ConsumptionProjectionByGroupIdManager {
  rawData: ConsumptionProjectionByGroupIdGraphQLData;
  getMonthlyGroupConsumptionProjection: IGroupConsumptionProjection;

  constructor(rawData: ConsumptionProjectionByGroupIdGraphQLData) {
    this.rawData = rawData;
    this.getMonthlyGroupConsumptionProjection = rawData.getMonthlyGroupConsumptionProjection;
  }
}

export class AllTotalConsumptionByUnitIdManager {
  rawData: AllTotalConsumptionByUnitIdGraphQLData;
  getAllTotalConsumptionMeasuresPerUnit: ITotalMonthlyConsumption[];

  constructor(rawData: AllTotalConsumptionByUnitIdGraphQLData) {
    this.rawData = rawData;
    this.getAllTotalConsumptionMeasuresPerUnit = rawData.getAllTotalConsumptionMeasuresPerUnit;
  }
}

export class AllTotalConsumptionByGroupIdManager {
  rawData: AllTotalConsumptionByGroupIdGraphQLData;
  getAllTotalConsumptionMeasuresPerGroupByMonthYear: ITotalMonthlyConsumption[];

  constructor(rawData: AllTotalConsumptionByGroupIdGraphQLData) {
    this.rawData = rawData;
    this.getAllTotalConsumptionMeasuresPerGroupByMonthYear = rawData.getAllTotalConsumptionMeasuresPerGroupByMonthYear;
  }
}

export class GetEconomyReportByGroupAndDateManager {
  rawData: GetEconomyReportByGroupAndDateGraphQLData;
  groupInvoice: number | undefined;
  unitInvoice: number | undefined;

  constructor(
    rawData: GetEconomyReportByGroupAndDateGraphQLData,
    unitId: string | undefined,
    monthYear: string | undefined,
  ) {
    const { groupTotalValue, unitTotalValue } = settingGroupAndUnitInvoice(rawData, unitId, monthYear);

    this.rawData = rawData;
    this.groupInvoice = groupTotalValue;
    this.unitInvoice = unitTotalValue;
  }
}
