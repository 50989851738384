import { File } from '@contexts/files/types';
import { triggerAmplitudeButtonClickedEvent } from '@services/event-tracking/events-trigger';

export const getFilenameFromPath = (filePath: string) => {
  const filePathSplited = filePath.split('/');
  return filePathSplited[filePathSplited.length - 1];
};

export const getDocumentsTypesFromFilesList = (files: File[]) => {
  const localDocumentsType: string[] = [];
  files?.forEach((item) => {
    if (!localDocumentsType.includes(item.documentType as string)) {
      localDocumentsType.push(item.documentType as string);
    }
  });
  return localDocumentsType;
};

export const triggerButtonEvent = (buttonText: string, destinationPath?: string) => {
  return triggerAmplitudeButtonClickedEvent('Documentos', buttonText, destinationPath);
};
