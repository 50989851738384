import { useContextSelector } from 'use-context-selector';
import { CompilationResultContext } from '@contexts/compilation-results';
import { CompiledResultsContextType } from '@contexts/compilation-results/types';

export const useCompiledResult = (): CompiledResultsContextType => {
  const loading = useContextSelector(CompilationResultContext, (compilation) => compilation.loading);
  const proposal = useContextSelector(CompilationResultContext, (compilation) => compilation.proposal);
  const savings = useContextSelector(CompilationResultContext, (compilation) => compilation.savings);
  const processName = useContextSelector(CompilationResultContext, (compilation) => compilation.processName);

  return {
    loading,
    savings,
    proposal,
    processName,
  };
};
