import { formatButtonUrl } from '@utils/text';
import dayjs from 'dayjs';
import { eventLabels } from './events-label';
import eventTracker from './index';

export const triggerAmplitudeButtonClickedEvent = (pageName: string, buttonText: string, destinationPath?: string) => {
  const eventProperties = {
    page_name: pageName,
    button_text: buttonText,
    button_url: destinationPath ? formatButtonUrl(destinationPath) : '',
    button_clicked_at: dayjs().toString(),
  };
  eventTracker.trackEvent(eventLabels.BUTTON_CLICKED, eventProperties);
};
