import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { useNotifications } from '@hooks/use-notifications';
import NotificationsHub from '@pages/notifications/notifications-hub';

interface NotificationSlideOverProps {
  isMenuCollapsed: boolean;
}

function NotificationSlideOver({ isMenuCollapsed }: NotificationSlideOverProps) {
  const { openNotificationSlideOver, setOpenNotificationSlideOver } = useNotifications();

  return (
    <Transition.Root show={openNotificationSlideOver} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpenNotificationSlideOver(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 top-10 bg-gray-500 bg-opacity-75 transition-opacity lg:top-0 lg:left-[15.5rem]" />
        </Transition.Child>
        <div
          className={`overflow-hidden fixed inset-0 top-10 lg:top-0 left-10 lg:left-${
            isMenuCollapsed ? '[4.5rem]' : '[15.5rem]'
          }`}
        >
          <div className="overflow-hidden absolute inset-0">
            <div className="flex absolute inset-0 max-w-full pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto lg:w-3/5">
                  <div className="flex overflow-y-hidden z-50 flex-col w-full h-full bg-[#FAFAFA] shadow-xl">
                    <NotificationsHub />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default NotificationSlideOver;
