import React from 'react';
import { useLazyQuery, QueryResult } from '@apollo/client';

import { GET_MIGRATION_REPORT_BY_GROUP_ID } from './queries';
import { IMigrationReport } from './types';
import { createContext } from 'use-context-selector';

export type MigrationReportContextType = {
  getMigrationReportByGroupHandler: () => Promise<QueryResult<any, { groupId: string }>>;
  migrationReportByGroup: IMigrationReport;
  setMigrationReportByGroup: (value: IMigrationReport) => void;
  groupId: string;
  setGroupId: React.Dispatch<React.SetStateAction<string>>;
  isFetchingMigration: boolean;
};

export const MigrationReportContext = createContext<MigrationReportContextType>({} as MigrationReportContextType);

export const MigrationReportProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [groupId, setGroupId] = React.useState<string>('');
  const [isFetchingMigration, setIsFetchingMigration] = React.useState<boolean>(true);
  const [migrationReportByGroup, setMigrationReportByGroup] = React.useState<IMigrationReport>({} as IMigrationReport);
  const [getMigrationReportByGroup] = useLazyQuery(GET_MIGRATION_REPORT_BY_GROUP_ID, {
    fetchPolicy: 'network-only',
    variables: { groupId },
    onCompleted: (data) => {
      if (!data.getMigrationReportByGroupId.id) {
        setMigrationReportByGroup({} as IMigrationReport);
      } else {
        setMigrationReportByGroup(data.getMigrationReportByGroupId);
      }
      setIsFetchingMigration(false);
    },
    onError: (e) => {
      setMigrationReportByGroup({} as IMigrationReport);
      throw `ERROR: ${e}`;
    },
  });

  function getMigrationReportByGroupHandler() {
    setIsFetchingMigration(true);
    return getMigrationReportByGroup();
  }

  React.useEffect(() => {
    if (groupId) {
      getMigrationReportByGroupHandler();
    }
  }, [groupId]);

  return (
    <MigrationReportContext.Provider
      value={{
        getMigrationReportByGroupHandler,
        migrationReportByGroup,
        setMigrationReportByGroup,
        groupId,
        setGroupId,
        isFetchingMigration,
      }}
    >
      {children}
    </MigrationReportContext.Provider>
  );
};
