import { CLARKE_WHATSAPP } from '@utils/constants/common';
import React from 'react';
import { HOME_PATH } from './constants';

const AttendanceRoute: React.FC = () => {
  let firstRender = true;
  React.useEffect(() => {
    if (firstRender) {
      window.open(CLARKE_WHATSAPP, '_blank');
      window.location.href = HOME_PATH;
      firstRender = false;
    }
  }, []);
  return <></>;
};

export default AttendanceRoute;
