import { gql } from '@apollo/client';

export const QUERY_GET_GROUP_FILES = gql`
  query Files($commercialGroupId: ID) {
    files(commercialGroupId: $commercialGroupId) {
      url
      unitId
      id
      filePath
      documentType
      createdAt
    }
  }
`;

export const QUERY_GET_UNITS = gql`
  query Unit($groupId: ID!) {
    group(id: $groupId) {
      units {
        id
        name
      }
    }
  }
`;
