import React from 'react';

import { ValuesPerYear } from '@contexts/compilation-results/types';
import numeral from 'numeral';

interface AcrCostsRowProps {
  valuesPerYear: ValuesPerYear[];
  total: number;
}

const AcrCostsRow: React.FC<AcrCostsRowProps> = ({ valuesPerYear, total }) => {
  const className = 'py-4 font-normal text-start border-neutral-20 border-y text-paragraph-small';
  return (
    <tr>
      <td className={className}>Custo Acr</td>
      {valuesPerYear.map(({ year, acrCost }) => (
        <React.Fragment key={year}>
          <td className={className}>{numeral(acrCost).format('$ 0,0.00')}</td>
        </React.Fragment>
      ))}
      <td className={className}>{numeral(total).format('$ 0,0.00')}</td>
    </tr>
  );
};

export default AcrCostsRow;
