import React from 'react';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';

import configuration from '@config';
import { PUBLIC_PATHS_LIST } from '@routers/constants';

export interface User {
  id: string;
  name: string;
  pictureURL: string;
  roles?: ('admin' | 'supplier' | 'customer')[];
}

export interface AuthenticationState {
  isAuthenticated: boolean;
  accessToken: string;
}

export interface IAuthContext {
  authStatus: AuthenticationState;
  user: User;
  loading: boolean;
  logout: () => void;
}

export const authContext = React.createContext({} as IAuthContext);
export const useAuth = () => React.useContext<IAuthContext>(authContext);

interface ApolloWrapperProps {
  children: JSX.Element;
}

function ApolloWrapper({ children }: ApolloWrapperProps) {
  const { getAccessTokenSilently, isAuthenticated, user, logout, isLoading } = useAuth0();
  const [token, setToken] = React.useState<string>('');

  const httpLink = createHttpLink({
    uri: configuration.GATEWAY_API_HOST,
  });

  const authLink = setContext((_, { headers, ...rest }) => {
    if (!token) return { headers, ...rest };
    return {
      ...rest,
      headers: { ...headers, Authorization: `Bearer ${token}` },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const { variables, operationName } = operation;
    const context = { name: operationName, values: variables };

    if (networkError) {
      context.values = {
        ...context.values,
        errorType: 'network',
        rawErrorObject: JSON.stringify(networkError),
      };
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        context.values = {
          ...context.values,
          errorType: 'graphql',
          rawErrorObject: JSON.stringify(graphQLError),
        };
      });
    }
  });

  const contextValue: IAuthContext = {
    authStatus: { isAuthenticated, accessToken: token },
    loading: isLoading,
    user: {
      id: user?.['https://clarke.com.br/uuid'],
      name: user?.name ?? '',
      pictureURL: user?.picture ?? '',
      roles: user?.['https://clarke.com.br/roles'] ?? undefined,
    },
    logout,
  };

  const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
  });

  const isPathPublic = (path: string) => {
    if (PUBLIC_PATHS_LIST.find((publicPath) => path.includes(publicPath)) !== undefined) return true;
    else return false;
  };

  React.useEffect(() => {
    const getToken = async () => {
      if (isPathPublic(window.location.pathname)) {
        setToken(configuration.AUTH0_ACCESS_TOKEN);
      } else {
        const bearerToken = isAuthenticated ? await getAccessTokenSilently() : '';
        setToken(bearerToken);
      }
    };

    getToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return (
    <ApolloProvider client={client}>
      <authContext.Provider value={contextValue}>{children}</authContext.Provider>
    </ApolloProvider>
  );
}

export default ApolloWrapper;
