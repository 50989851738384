import { useContextSelector } from 'use-context-selector';
import { MigrationReportContext, MigrationReportContextType } from '@contexts/migration-report';

export const useMigrationReport = (): MigrationReportContextType => {
  const getMigrationReportByGroupHandler = useContextSelector(
    MigrationReportContext,
    (unitEconomy) => unitEconomy.getMigrationReportByGroupHandler,
  );
  const migrationReportByGroup = useContextSelector(
    MigrationReportContext,
    (unitEconomy) => unitEconomy.migrationReportByGroup,
  );
  const setMigrationReportByGroup = useContextSelector(
    MigrationReportContext,
    (unitEconomy) => unitEconomy.setMigrationReportByGroup,
  );
  const setGroupId = useContextSelector(MigrationReportContext, (unitEconomy) => unitEconomy.setGroupId);
  const groupId = useContextSelector(MigrationReportContext, (unitEconomy) => unitEconomy.groupId);
  const isFetchingMigration = useContextSelector(
    MigrationReportContext,
    (unitEconomy) => unitEconomy.isFetchingMigration,
  );

  return {
    getMigrationReportByGroupHandler,
    migrationReportByGroup,
    setMigrationReportByGroup,
    setGroupId,
    groupId,
    isFetchingMigration,
  };
};
