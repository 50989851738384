import { useAuth0 } from '@auth0/auth0-react';
import { DropdownMenu, Menu, NavigationLinkProps } from '@clarke-energia/foton';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';

import { useNotifications } from '@hooks/use-notifications';
import { useUserInfo } from '@hooks/use-user-info';

import ClarkeLogo from '@assets/clarke-logo.svg';
import ClarkeLogoHorizontal from '@assets/logo-clarke-horizontal-green.png';

import { GENERAL_PANEL_PARAMS } from '@utils/constants/common';
import { getWindowDimensions } from '@utils/graphics';
import { capitalize } from '@utils/text';

import MobileMenuControllerButton from '@components/atoms/sidebar/mobile-menu-controller-button';
import MobileNavigationLinks from '@components/atoms/sidebar/mobile-navigation-links';

import { DropdownMenuProps, IDecodedToken } from '@contexts/users/types';
import { useGroupEconomy } from '@hooks/use-group-economy';
import {
  ACCUMULATED_ECONOMY_REPORTS_PATH,
  ATTENDANCE_PATH,
  CONSUMPTION_DASHBOARD_PATH,
  ENERGY_PRICE,
  HOME_PATH,
  MIGRATION_REPORT_PATH,
  MONTHLY_ECONOMY_REPORTS_PATH,
  SUSTAINABILITY_PATH,
} from '@routers/constants';
import { triggerAmplitudeButtonClickedEvent } from '@services/event-tracking/events-trigger';
import { authContext, useAuth } from '@src/ApolloWrapper';
import { InputOptionProps } from '@utils/types';
import { Transition, Dialog } from '@headlessui/react';

const getCurrentPageName = () => {
  const currentURL = window.location.href;
  const currentPageName = currentURL.split('/')[3];

  if (!currentPageName) return 'Home';
  if (currentPageName === ACCUMULATED_ECONOMY_REPORTS_PATH.replace('/', '')) return 'Relatório de Economia Acumulado';
  if (currentPageName === MONTHLY_ECONOMY_REPORTS_PATH.replace('/', '')) return 'Relatório de Economia Mensal';

  return capitalize(currentPageName);
};

const baseNavigationLinks = (): NavigationLinkProps[] => {
  const { user } = useAuth();
  const { setUserId, setOpenNotificationSlideOver, notifications } = useNotifications();
  const [currentPageName, setCurrentPageName] = useState<string>('');

  useEffect(() => {
    setCurrentPageName(getCurrentPageName());
  }, [window.location.href]);

  user && setUserId(user.id);

  const newMigrationNotifications = notifications?.filter(
    (notification) => notification.topic === 'MIGRATION' && notification.read === false,
  ).length;
  const newEconomyNotifications = notifications?.filter(
    (notification) => notification.topic === 'ECONOMY' && notification.read === false,
  ).length;
  const newConsumptionNotifications = notifications?.filter(
    (notification) => notification.topic === 'CONSUMPTION' && notification.read === false,
  ).length;
  const newNotifications = notifications?.filter((notification) => notification.read === false).length;

  const ECONOMY_REPORT_GENERAL_PATH = `${ACCUMULATED_ECONOMY_REPORTS_PATH}/${GENERAL_PANEL_PARAMS}`;
  const SUSTAINABILITY_GENERAL_PATH = `${SUSTAINABILITY_PATH}/${GENERAL_PANEL_PARAMS}`;

  return [
    {
      label: 'Home',
      path: HOME_PATH,
      iconName: 'HomeIcon',
      navLinkEvents: {
        onClick: () => triggerAmplitudeButtonClickedEvent(currentPageName, 'Home', HOME_PATH),
      },
    },
    {
      label: 'Relatórios',
      path: ECONOMY_REPORT_GENERAL_PATH,
      iconName: 'DocumentChartBarIcon',
      badgeLabel: newEconomyNotifications ? 'Novo' : '',
      navLinkEvents: {
        onClick: () => triggerAmplitudeButtonClickedEvent(currentPageName, 'Relatórios', ECONOMY_REPORT_GENERAL_PATH),
      },
    },
    {
      label: 'Sustentabilidade',
      path: SUSTAINABILITY_GENERAL_PATH,
      iconName: 'GlobeAmericasIcon',
      badgeLabel: newConsumptionNotifications ? 'Novo' : '',
      navLinkEvents: {
        onClick: () =>
          triggerAmplitudeButtonClickedEvent(currentPageName, 'Sustentabilidade', SUSTAINABILITY_GENERAL_PATH),
      },
    },
    {
      label: 'Consumo',
      path: CONSUMPTION_DASHBOARD_PATH,
      iconName: 'LightBulbIcon',
      badgeLabel: newConsumptionNotifications ? 'Novo' : '',
      navLinkEvents: {
        onClick: () => triggerAmplitudeButtonClickedEvent(currentPageName, 'Consumo', CONSUMPTION_DASHBOARD_PATH),
      },
    },
    {
      label: 'Migração',
      path: MIGRATION_REPORT_PATH,
      iconName: 'ArrowPathIcon',
      badgeLabel: newMigrationNotifications ? 'Novo' : '',
      navLinkEvents: {
        onClick: () => triggerAmplitudeButtonClickedEvent(currentPageName, 'Migração', MIGRATION_REPORT_PATH),
      },
    },
    {
      label: 'Cotação de energia',
      path: ENERGY_PRICE,
      iconName: 'SwatchIcon',
      navLinkEvents: {
        onClick: () => triggerAmplitudeButtonClickedEvent(currentPageName, 'Cotação de energia', ENERGY_PRICE),
      },
    },
    {
      label: 'Notificações',
      iconName: 'BellIcon',
      badgeLabel: newNotifications ? `• ${newNotifications}` : '',
      linkAsButton: {
        active: true,
        onClick: () => {
          setOpenNotificationSlideOver(true);
          triggerAmplitudeButtonClickedEvent(currentPageName, 'Notificações');
        },
      },
    },
    {
      label: 'Falar com Especialista',
      path: ATTENDANCE_PATH,
      iconName: 'ChatBubbleOvalLeftEllipsisIcon',
      navLinkEvents: {
        onClick: () => triggerAmplitudeButtonClickedEvent(currentPageName, 'Falar com Especialista', ATTENDANCE_PATH),
      },
    },
  ];
};

const DesktopSideBar = ({ isMenuCollapsed, setIsMenuCollapsed }: SideBarProps) => {
  const { user, upsertGroupRelationWithUserHandler, isUpsertRelationSuccess, groupsByUser } = useUserInfo();
  const { logout } = useAuth0();
  const { groups } = useGroupEconomy();
  const { authStatus } = useContext(authContext);
  const decodedToken: IDecodedToken = jwtDecode(authStatus.accessToken);

  const isSuperAdmin = decodedToken.scope.split(' ').includes('switch:customer');

  const [inputOptions, setInputOptions] = useState<InputOptionProps[]>([]);
  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);
  const [alternativeFooterLabel, setAlternativeFooterLabel] = useState<string>('');
  const [initialLetter, setInitialLetter] = useState<string>('');
  const [hasUserAuthToSwitchCustomer, setHasUserAuthToSwitchCustomer] = useState<boolean>(false);

  useEffect(() => {
    if (groupsByUser) {
      setHasUserAuthToSwitchCustomer(isSuperAdmin || groupsByUser?.length > 1);
    }
  }, [groupsByUser]);

  const dropdownMenuInfo: DropdownMenuProps = {
    dropdownMenuHeader: {
      title: user.name,
      subtitle: user.email,
      avatarUrl: user.photoUrl,
    },
    dropdownMenuBody: {
      title: user.group?.name ? 'Suas empresas' : '',
      mainSectionProps: {
        overflownMenusProps: [
          {
            label: alternativeFooterLabel,
            avatarPros: { initialLetter: initialLetter },
            tagProps: { label: 'Ativo', color: 'green', icon: 'CheckIcon', kind: 'icon' },
          },
        ],
        buttonProps: { label: 'Trocar', kind: 'secondary' },
        hasUserAuthorization: hasUserAuthToSwitchCustomer,
      },
      secondarySectionProps: {
        multiSelectProps: {
          id: 'multi-select-test',
          inputOptions: inputOptions,
          name: 'multi-select-test',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onBlur: () => {},
          onChange: (value) => onGroupSelected(value[0]),
          value: [''],
        },
      },
    },
    dropdownMenuFooter: {
      label: 'Sair',
      icon: 'ArrowRightOnRectangleIcon',
      onClick: () => logout(),
    },
  };

  const onGroupSelected = (groupId: string) => {
    upsertGroupRelationWithUserHandler(groupId);
    setShowDropdownMenu(false);
  };

  useEffect(() => {
    if (isUpsertRelationSuccess) {
      window.location.replace(HOME_PATH);
    }
  }, [isUpsertRelationSuccess]);

  useEffect(() => {
    if (groups || groupsByUser) {
      const localGroups = isSuperAdmin ? groups : groupsByUser;
      const localInputOptions = localGroups
        ? localGroups.map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          })
        : [];
      setInputOptions(localInputOptions);
    }
  }, [groups, groupsByUser]);

  useEffect(() => {
    if (user.group) {
      setAlternativeFooterLabel(user.group.name ? user.group.name : user.name);
      setInitialLetter(user.group.name ? user.group.name.charAt(0).toUpperCase() : user.name.charAt(0).toUpperCase());
    }
  }, [user]);

  return (
    <div className={`h-full pb-3 ${isMenuCollapsed ? 'w-[4.5rem]' : 'w-[14.5rem]'}`}>
      <Menu
        companyLogo={ClarkeLogo}
        isAdmin={user.isAdmin}
        adminLinks={baseNavigationLinks()}
        userLinks={baseNavigationLinks()}
        alternativeFooter={{
          label: alternativeFooterLabel,
          avatarPros: { initialLetter: initialLetter },
          icon: 'ChevronRightIcon',
          iconPosition: 'right',
          onClick: () => setShowDropdownMenu(!showDropdownMenu),
        }}
        dropdownMenuInfo={dropdownMenuInfo}
        showDropdownMenu={showDropdownMenu}
        menuCollapseControl={{
          setIsMenuCollapsed: () => setIsMenuCollapsed(!isMenuCollapsed),
          isMenuCollapsed: isMenuCollapsed,
        }}
      />
    </div>
  );
};

export const MobileSidebar: React.FC = () => {
  const { user, upsertGroupRelationWithUserHandler, isUpsertRelationSuccess, groupsByUser } = useUserInfo();
  const { logout } = useAuth0();
  const { groups } = useGroupEconomy();
  const { authStatus } = useContext(authContext);
  const decodedToken: IDecodedToken = jwtDecode(authStatus.accessToken);

  const isSuperAdmin = decodedToken.scope.split(' ').includes('switch:customer');

  const [inputOptions, setInputOptions] = useState<InputOptionProps[]>([]);
  const [alternativeFooterLabel, setAlternativeFooterLabel] = useState<string>('');
  const [initialLetter, setInitialLetter] = useState<string>('');
  const [hasUserAuthToSwitchCustomer, setHasUserAuthToSwitchCustomer] = useState<boolean>(false);

  useEffect(() => {
    if (groupsByUser) {
      setHasUserAuthToSwitchCustomer(isSuperAdmin || groupsByUser?.length > 1);
    }
  }, [groupsByUser]);

  const dropdownMenuInfo: DropdownMenuProps = {
    dropdownMenuHeader: {
      title: user.name,
      subtitle: user.email,
      avatarUrl: user.photoUrl,
    },
    dropdownMenuBody: {
      title: user.group?.name ? 'Suas empresas' : '',
      mainSectionProps: {
        overflownMenusProps: [
          {
            label: alternativeFooterLabel,
            avatarPros: { initialLetter: initialLetter },
            tagProps: { label: 'Ativo', color: 'green', icon: 'CheckIcon', kind: 'icon' },
          },
        ],
        buttonProps: { label: 'Trocar', kind: 'secondary' },
        hasUserAuthorization: hasUserAuthToSwitchCustomer,
      },
      secondarySectionProps: {
        multiSelectProps: {
          id: 'multi-select-test',
          inputOptions: inputOptions,
          name: 'multi-select-test',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onBlur: () => {},
          onChange: (value) => onGroupSelected(value[0]),
          value: [''],
        },
      },
    },
    dropdownMenuFooter: {
      label: 'Sair',
      icon: 'ArrowRightOnRectangleIcon',
      onClick: () => logout(),
    },
  };

  const onGroupSelected = (groupId: string) => {
    upsertGroupRelationWithUserHandler(groupId);
  };

  useEffect(() => {
    if (isUpsertRelationSuccess) {
      window.location.replace(HOME_PATH);
    }
  }, [isUpsertRelationSuccess]);

  useEffect(() => {
    if (groups || groupsByUser) {
      const localGroups = isSuperAdmin ? groups : groupsByUser;
      const localInputOptions = localGroups
        ? localGroups.map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          })
        : [];
      setInputOptions(localInputOptions);
    }
  }, [groups, groupsByUser]);

  useEffect(() => {
    if (user.group) {
      setAlternativeFooterLabel(user.group.name ? user.group.name : user.name);
      setInitialLetter(user.group.name ? user.group.name.charAt(0).toUpperCase() : user.name.charAt(0).toUpperCase());
    }
  }, [user]);

  const [mobileSidebarOpen, setMobileSidebarOpen] = useState<boolean>(false);
  const closeSidebar = () => setMobileSidebarOpen(false);

  return (
    <div className="flex">
      <div className="flex relative">
        <MobileMenuControllerButton setMobileSidebarOpen={setMobileSidebarOpen} mobileSidebarOpen={mobileSidebarOpen} />
        <div className="flex justify-center items-center w-screen h-10">
          <img src={ClarkeLogoHorizontal} className="h-6 w-[10rem]" />
        </div>
      </div>
      <Transition show={mobileSidebarOpen} as={React.Fragment}>
        <Dialog open={mobileSidebarOpen} onClose={closeSidebar} className="relative z-50">
          <div className="flex fixed bottom-0 top-10 w-full p-l-0">
            <Transition.Child
              as={React.Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="flex overflow-y-auto relative flex-col gap-6 justify-between px-4 pt-6 pr-6 w-full bg-white overflow-x-clip">
                <MobileNavigationLinks links={baseNavigationLinks()} closeSidebar={closeSidebar} />
                <DropdownMenu {...dropdownMenuInfo} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

interface SideBarProps {
  isMenuCollapsed: boolean;
  setIsMenuCollapsed: (isMenuCollapsed: boolean) => void;
}

const SideBar = ({ isMenuCollapsed, setIsMenuCollapsed }: SideBarProps) => {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (windowDimensions.width <= 768) return <MobileSidebar />;
  else return <DesktopSideBar isMenuCollapsed={isMenuCollapsed} setIsMenuCollapsed={setIsMenuCollapsed} />;
};

export default SideBar;
