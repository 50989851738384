import React from 'react';
import { DetailedCard } from '@clarke-energia/foton';
import { ParsedBidsData } from '@contexts/compilation-results/types';

import EmptyScope from '@components/atoms/energy-compilation/empty-score';
import TableDisplay from './table-display';
import { highLightCard } from './helper';

interface ScopeDisplayProps {
  bids: ParsedBidsData[];
  type: 'INSIDE' | 'OUTSIDE';
}

const ScopeDisplay: React.FC<ScopeDisplayProps> = ({ bids, type }) => {
  const highlights: ParsedBidsData[] = bids.length >= 3 ? bids.slice(0, 3) : bids;

  const hasValidScore = highlights.some((highlight) => highlight.traderScore);

  const maxScoreValue = hasValidScore && Math.max(...highlights.map((highlight) => highlight.traderScore));

  const bestScoreIdx = highlights.findIndex((item) => item.traderScore === maxScoreValue);

  const isInsideScope = type === 'INSIDE';
  const formatedHighlights = highLightCard(highlights, isInsideScope, bestScoreIdx);

  if (!bids.length) return <EmptyScope isInsideScope={isInsideScope} />;

  return (
    <div className="grid grid-cols-1 gap-6 xl:grid-cols-9 -z-10">
      {formatedHighlights.map(({ cardContent, cardHighLightInfo, imageContent, tag, hightlightStyle }, idx) => (
        <DetailedCard
          key={`card-${idx}`}
          tag={tag}
          hightlightStyle={hightlightStyle}
          cardHighLightInfo={cardHighLightInfo}
          imageContent={imageContent}
          cardContent={cardContent}
          className={`${isInsideScope && idx !== 0 ? 'self-end' : ''} col-span-full lg:col-span-3`}
        />
      ))}
      <div className="col-span-full mt-8">
        <h2 className="mb-6 text-heading-medium">Todas as propostas detalhadas</h2>
        <TableDisplay data={bids} />
      </div>
    </div>
  );
};
export default ScopeDisplay;
