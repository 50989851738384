import { gql } from '@apollo/client';

export const GET_MIGRATION_REPORT_BY_GROUP_ID = gql`
  query GetMigrationReportByGroupId($groupId: ID!) {
    getMigrationReportByGroupId(groupId: $groupId) {
      id
      thirdPartyId
      commercialGroup
      conclusionPercentage
      units {
        thirdPartyId
        nickname
        conclusionPercentage
        topics {
          thirdPartyId
          title
          conclusionPercentage
          dueDate
          tasks {
            thirdPartyId
            title
            dueDate
            status
            customerResponsibility
            responsible {
              category
              name
            }
            description
          }
        }
      }
    }
  }
`;
