import React from 'react';
import { Table, ColumnOptions } from '@clarke-energia/foton';
import { ParsedBidsData, ValuesPerYear, ValuesReport } from '@contexts/compilation-results/types';
import EnergyPriceRow from '@components/atoms/energy-compilation/table/energy-price-row';
import AcrCostsRow from '@components/atoms/energy-compilation/table/acr-costs';
import AclCostsRow from '@components/atoms/energy-compilation/table/acl-costs';
import CommonRowsEnergyCompilationTable, { StyleOptions } from '@components/atoms/energy-compilation/table/common-rows';
import { expandendContentTable } from './helper';

interface TableProps {
  data: ParsedBidsData[];
}

const SavingsDetailTable: React.FC<{ report: ValuesReport }> = ({ report }) => {
  const className = 'py-4 font-normal text-start border-neutral-20 border-y text-paragraph-small';
  const totalManagementCost = report.valuesPerYear.reduce((total, value) => total + value.management, 0);
  const totalACLCost = report.valuesPerYear.reduce((total, value) => total + value.aclCost, 0);
  const totalAcrCost = report.valuesPerYear.reduce((total, value) => total + value.acrCost, 0);

  return (
    <div className="py-6 pl-7">
      <table className="w-full">
        <thead>
          <tr>
            <th
              className="py-4 px-5 font-extrabold text-paragraph-small text-start"
              colSpan={report.valuesPerYear.length + 2}
            >
              Detalhamento
            </th>
          </tr>
          <tr>
            <th className={className}></th>
            {report.valuesPerYear.map(({ year }) => (
              <th key={year} className={className}>
                {year}
              </th>
            ))}
            <th className={className}>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <EnergyPriceRow valuesPerYear={report.valuesPerYear} />
          <AcrCostsRow valuesPerYear={report.valuesPerYear} total={totalAcrCost} />
          <AclCostsRow valuesPerYear={report.valuesPerYear} total={totalACLCost - totalManagementCost} />
          {expandendContentTable(totalManagementCost, report.initialExpenses, report.totalCurrencyAmount).map(
            ({ title, key, styleOptions, total }) => {
              return (
                <CommonRowsEnergyCompilationTable
                  key={title}
                  title={title}
                  rowKey={key as keyof ValuesPerYear}
                  valuesPerYear={report.valuesPerYear}
                  total={total}
                  styleOptions={styleOptions as StyleOptions}
                />
              );
            },
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableDisplay: React.FC<TableProps> = ({ data }) => {
  const mainTableColumns: ColumnOptions<ParsedBidsData>[] = [
    { accessor: 'traderName', header: 'Fornecedora', renderAsElement: (entry) => entry.traderName },
    { accessor: 'totalEconomy', header: 'Economia', renderAsElement: (entry) => entry.totalEconomy },
    { accessor: 'npv', header: 'VLP de economia', renderAsElement: (entry) => entry.npv },
    { accessor: 'flexibility', header: 'Flexibilidade', renderAsElement: (entry) => entry.flexibility },
    { accessor: 'guaranteeType', header: 'Garantia', renderAsElement: (entry) => entry.guaranteeType },
    { accessor: 'traderType', header: 'Modalidade de contrato', renderAsElement: (entry) => entry.traderType },
    { accessor: 'deadline', header: 'Validade da proposta', renderAsElement: (entry) => entry.deadline },
    { accessor: 'cceeCoverTaxes', header: 'Encargos CCEE', renderAsElement: (entry) => entry.cceeCoverTaxes },
  ];

  const expandedDataContent = (data: ParsedBidsData[]) =>
    data.map((item) => {
      return {
        data: { ...item },
        expandedContent: <SavingsDetailTable report={item.reportForDisplay} />,
      };
    });

  return (
    <div className="overflow-x-auto overflow">
      <Table tableColumns={mainTableColumns} data={expandedDataContent(data)} expandable />
    </div>
  );
};
export default TableDisplay;
