import React from 'react';

import useKickOff from '@hooks/use-kick-off';

import KickOffLayout from '@components/molecules/kick-off/kick-off-layout';
import KickOffDiagnosisSuggestedProductTable from '@components/molecules/kick-off/diagnosis/result/diagnosis-suggested-product-table';
import KickOffDiagnosisHeader from '@components/molecules/kick-off/diagnosis/result/diagnosis-header';
import KickOffDiagnosisFooter from '@components/molecules/kick-off/diagnosis/result/diagnosis-footer';

const KickOffDiagnosisResult: React.FC = () => {
  const { kickOff } = useKickOff();
  return (
    <KickOffLayout>
      <>
        <KickOffDiagnosisHeader />
        <KickOffDiagnosisSuggestedProductTable suggestedProduct={kickOff?.diagnosis?.suggestedProduct} />
        <KickOffDiagnosisFooter />
      </>
    </KickOffLayout>
  );
};

export default KickOffDiagnosisResult;
