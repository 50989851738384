import { useContextSelector } from 'use-context-selector';
import { KickOffContext } from '@contexts/kick-off';

const useKickOff = () => {
  const kickOff = useContextSelector(KickOffContext, (kickOff) => kickOff.kickOff);
  const loading = useContextSelector(KickOffContext, (kickOff) => kickOff.loading);
  const setGroupId = useContextSelector(KickOffContext, (kickOff) => kickOff.setGroupId);

  const flowSection = useContextSelector(KickOffContext, (kickOff) => kickOff.flowSection);
  const setFlowSection = useContextSelector(KickOffContext, (kickOff) => kickOff.setFlowSection);

  const getKickOffByGroupIDHandler = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.getKickOffByGroupIDHandler,
  );

  const saveKickOffQuizInputsHandler = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.saveKickOffQuizInputsHandler,
  );
  const submitKickOffQuizHandler = useContextSelector(KickOffContext, (kickOff) => kickOff.submitKickOffQuizHandler);

  const createKickOffDiagnosisHandler = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.createKickOffDiagnosisHandler,
  );
  const updateKickOffDiagnosisHandler = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.updateKickOffDiagnosisHandler,
  );

  return {
    loading,
    kickOff,
    flowSection,
    setFlowSection,
    setGroupId,
    getKickOffByGroupIDHandler,
    saveKickOffQuizInputsHandler,
    submitKickOffQuizHandler,
    createKickOffDiagnosisHandler,
    updateKickOffDiagnosisHandler,
  };
};

export default useKickOff;
