import {
  ACCUMULATED_ECONOMY_REPORTS_PATH,
  ATTENDANCE_PATH,
  ENERGY_PRICE,
  FILES_PATH,
  MIGRATION_REPORT_PATH,
} from '@routers/constants';
import arrowPathIcon from '@src/assets/arrow-path-icon.png';
import documentChartIcon from '@src/assets/document-chart-icon.png';
import documentReportIcon from '@src/assets/document-report-icon.png';
import questionMarkIcon from '@src/assets/question-mark-icon.png';
import { GENERAL_PANEL_PARAMS } from '@utils/constants/common';

interface ICardInformation {
  id: string;
  icon: string;
  title: string;
  description: string;
  path: string;
}

export const cardsInformation: ICardInformation[] = [
  {
    id: '1',
    icon: arrowPathIcon,
    title: 'Migração para Mercado Livre',
    description:
      'O processo de migração para o mercado livre tem diversas etapas. Aqui você fica por dentro das suas responsabilidades e avanços.',
    path: MIGRATION_REPORT_PATH,
  },
  {
    id: '2',
    icon: documentChartIcon,
    title: 'Relatórios de economia',
    description:
      'Mensalmente criamos relatórios informando o seu desempenho no mercado livre de energia através de dados e insights.',
    path: `${ACCUMULATED_ECONOMY_REPORTS_PATH}/${GENERAL_PANEL_PARAMS}`,
  },
  {
    id: '3',
    icon: documentReportIcon,
    title: 'Documentos',
    description:
      'Todos os arquivos e documentos importantes em um só lugar! Aqui você encontra os contratos e as faturas das suas unidades.',
    path: FILES_PATH,
  },
  {
    id: '4',
    icon: documentChartIcon,
    title: 'Cotação de energia',
    description: 'Detalhes sobre sua cotação de energia.',
    path: ENERGY_PRICE,
  },
  {
    id: '4',
    icon: questionMarkIcon,
    title: 'Tire suas dúvidas!',
    description:
      'É nosso prazer poder ajudar você. Sempre que precisar, temos um time de especialistas pronto para te atender.',
    path: ATTENDANCE_PATH,
  },
];
