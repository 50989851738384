import { gql } from '@apollo/client';

export const GET_USERS_QUERY = gql`
  query AllUsers {
    allUsers {
      id
      name
      email
      createdAt
      phone
    }
  }
`;

export const GET_MYSELF_QUERY = gql`
  query GetMyself {
    me {
      id
      name
      email
      trader {
        id
      }
    }
  }
`;

function generateQuery(queryName: string, functionName: string) {
  return gql`
    query ${queryName}($id: ID!) {
      ${functionName}(id: $id) {
        id
        group {
          id
          name
        }
      }
    }
  `;
}

export const QUERY_GET_GROUP_BY_USER = generateQuery('getGroupByUser', 'getGroupByUser');
export const QUERY_GET_GROUPS_BY_USER = generateQuery('getGroupsByUser', 'getGroupsByUser');
