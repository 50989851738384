import { IconProps } from '@clarke-energia/foton';
import { NotificationTopic } from '@contexts/notifications/types';
import {
  MIGRATION_REPORT_PATH,
  FILES_PATH,
  CONSUMPTION_DASHBOARD_PATH,
  ACCUMULATED_ECONOMY_REPORTS_PATH,
} from '@routers/constants';

export const NotificationTopicsContents: Record<
  NotificationTopic,
  { actionButtonUrl: string; iconName: IconProps['icon'] }
> = {
  MIGRATION: {
    actionButtonUrl: MIGRATION_REPORT_PATH,
    iconName: 'ArrowPathIcon',
  },
  CONSUMPTION: {
    actionButtonUrl: CONSUMPTION_DASHBOARD_PATH,
    iconName: 'LightBulbIcon',
  },
  DOCUMENTS: {
    actionButtonUrl: FILES_PATH,
    iconName: 'DocumentTextIcon',
  },
  ECONOMY: {
    actionButtonUrl: `${ACCUMULATED_ECONOMY_REPORTS_PATH}/painel-geral`,
    iconName: 'DocumentChartBarIcon',
  },
  GENERAL: {
    actionButtonUrl: '#',
    iconName: 'BellIcon',
  },
};
