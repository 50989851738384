import React, { useState, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import domtoimage from 'dom-to-image';
import {
  ButtonIcon,
  ColumnOptions,
  GeneralGadget,
  IconCard,
  IconCardProps,
  Layout,
  PopUpList,
  PopUpListProps,
  SlideshowGadget,
  Table,
} from '@clarke-energia/foton';

import {
  formatGadgetContent,
  IHistoryTable,
  parseGroupEconomyReportCharts,
  parserGroupEconomyReportHistoryTable,
} from '@contexts/group-economy/parser';
import {
  GroupAccumulatedEconomyReportByGroupIdParsed,
  IHistoryCharts,
  IHistoryChartTitle,
  UnitReportParsed,
} from '@contexts/group-economy/types';
import {
  parseAccumulatedUnitEconomyReportCharts,
  parserUnitAccumulatedEconomyReportHistoryTable,
} from '@contexts/units-economy/parser';
import { useUnitsEconomy } from '@hooks/use-units-economy';
import { useUserInfo } from '@hooks/use-user-info';
import { useGroupEconomy } from '@hooks/use-group-economy';
import { useMigrationReport } from '@hooks/use-migration-report';

import { formatMonthAndYearDate } from '@utils/text';
import { GENERAL_PANEL_PARAMS } from '@utils/constants/common';

import BarChartComponent from '@components/molecules/charts/bar-chart';
import PageSectionGenerator from '@components/molecules/general/page-section-generator';
import { HomeSkeleton } from '@components/molecules/skeleton/home-skeleton';
import { GenerateMessageByMigrationMoment } from '@components/molecules/general/message-component';
import { ACCUMULATED_ECONOMY_REPORTS_PATH, HOME_PATH, MONTHLY_ECONOMY_REPORTS_PATH } from '@routers/constants';

import { chartsInformation } from './accumulated-economy-report-static-data';
import { IActionsButtons, triggerButtonEvent } from './helpers';

const AccumulatedEconomyReportPage: React.FC = () => {
  const navigate = useNavigate();
  const { id: unitId } = useParams();
  const {
    groupEconomyReports,
    rawGroupEconomyReports,
    setGroupId: setGroupEconomyGroupId,
    groupAccumulatedEconomyReport,
    loading: groupLoading,
  } = useGroupEconomy();
  const {
    unitAccumulatedEconomyReport,
    rawHistoryUnitEconomyReport,
    setGroupId: setUnitsEconomyGroupId,
    setUnitId,
    rawSummaryEconomyReportsHistory,
    loading: unitLoading,
  } = useUnitsEconomy();

  const { migrationReportByGroup, setGroupId: setMigrationReportGroupId } = useMigrationReport();
  const { user, isFetchingUser } = useUserInfo();

  const [openPeriodSelector, setOpenPeriodSelector] = useState<boolean>(false);
  const [reportsPeriods, setReportsPeriods] = useState<PopUpListProps['items']>();
  const [unitSummaryEconomyReport, setUnitSummaryEconomyReport] = useState<UnitReportParsed[] | undefined>();

  const [accumulatedEconomyReportCharts, setAccumulatedEconomyReportCharts] = useState<IHistoryCharts>();
  const [accumulatedEconomyReportHistoryTable, setAccumulatedEconomyReportHistoryTable] = useState<IHistoryTable[]>();
  const [openReportByUnitOrGeneralSelector, setOpenReportByUnitOrGeneralSelector] = useState<boolean>(false);
  const [reportsByUnitOrGeneral, setReportsByUnitOrGeneral] = useState<PopUpListProps['items']>();
  const exportRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const columnDefinitions: ColumnOptions<IHistoryTable>[] = [
    { accessor: 'month', header: 'Mês', renderAsElement: (history) => history.month },
    {
      accessor: 'economy',
      header: 'Economia',
      renderAsElement: (history) => history.economy,
    },
    { accessor: 'expense', header: 'Custo', renderAsElement: (history) => history.expense },
    {
      accessor: 'consumption',
      header: 'Consumo',
      renderAsElement: (history) => history.consumption,
    },
    {
      fixedWidthInRem: 1,
      renderAsElement: (history) => (
        <ButtonIcon
          onClick={() => {
            handleTableOrBtnClick(history);
          }}
          kind="ghost"
          icon="EyeIcon"
        />
      ),
    },
  ];

  const handleTableOrBtnClick = (history: IHistoryTable) => {
    const unitPath = unitId ? unitId : GENERAL_PANEL_PARAMS;
    const monthlyEconomyReportGeneralPath = `${MONTHLY_ECONOMY_REPORTS_PATH}/${unitPath}/${history.month}`;
    window.open(`${window.location.origin}${monthlyEconomyReportGeneralPath}`, '_blank');
    triggerButtonEvent('Tabela', monthlyEconomyReportGeneralPath);
  };

  const generateHeaderButtonsProps = (): IActionsButtons[] => {
    return [
      {
        buttonType: 'buttonInline',
        className: 'px-4 border-neutral-30',
        kind: 'primary',
        label: 'Histórico Completo',
        size: 'small',
      },
      {
        buttonType: 'buttonInline',
        className: 'px-4 border-neutral-30',
        kind: 'secondary',
        label: 'Mensal',
        icon: openPeriodSelector ? 'ChevronUpIcon' : 'ChevronDownIcon',
        iconPosition: 'right',
        size: 'small',
        onClick: () => {
          setOpenPeriodSelector(!openPeriodSelector);
        },
      },
      {
        buttonType: 'buttonInline',
        className: 'px-4 border-neutral-30',
        kind: 'secondary',
        label: 'Exportar',
        icon: 'InboxIcon',
        iconPosition: 'left',
        size: 'small',
        disabled: false,
        onClick: async () => {
          triggerButtonEvent('Exportar');
          domtoimage
            .toPng(exportRef.current)
            .then((dataUrl: string) => {
              const link = document.createElement('a');
              link.href = dataUrl;
              link.download = `relatorio-acumulado-${
                unitSummaryEconomyReport ? 'geral' : unitAccumulatedEconomyReport.unitName
              }.png`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch((error: string) => {
              // eslint-disable-next-line no-console
              console.error('Error capturing component as image:', error);
            });
        },
      },
    ];
  };

  const generateReportsByUnitOrGeneralPopupList = (
    groupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupIdParsed,
  ) => {
    if (groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports.length > 1) {
      const reportsByUnitOrGeneral = groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports.map(
        (report) => ({
          label: report.unitName,
          onClick: () => {
            const accumulatedEconomyReportUnitPath = `${ACCUMULATED_ECONOMY_REPORTS_PATH}/${report.unitId}`;
            setOpenReportByUnitOrGeneralSelector(false);
            navigate(accumulatedEconomyReportUnitPath);
            triggerButtonEvent(report.unitName, accumulatedEconomyReportUnitPath);
          },
        }),
      );

      const generatePopupListIfGroupsHasMoreThanOneUnit = () => {
        return [
          {
            label: 'Geral',
            onClick: () => {
              const accumulatedEconomyReportGeneralPath = `${ACCUMULATED_ECONOMY_REPORTS_PATH}/${GENERAL_PANEL_PARAMS}`;
              setOpenReportByUnitOrGeneralSelector(false);
              navigate(accumulatedEconomyReportGeneralPath);
              triggerButtonEvent('Geral', accumulatedEconomyReportGeneralPath);
            },
          },
          ...reportsByUnitOrGeneral,
        ];
      };

      setReportsByUnitOrGeneral(generatePopupListIfGroupsHasMoreThanOneUnit());
    }
  };

  const getAccumulatedEconomyReportIconCardProps = (): IconCardProps[] => {
    return [
      {
        icon: 'CurrencyDollarIcon',
        title: 'Economia',
        label: !unitSummaryEconomyReport
          ? unitAccumulatedEconomyReport.totalSavings
          : groupAccumulatedEconomyReport.totalSavings,
        tag: {
          type: !unitSummaryEconomyReport
            ? unitAccumulatedEconomyReport.totalSavingsPercentageTagType ?? 'default'
            : groupAccumulatedEconomyReport.totalSavingsPercentageTagType ?? 'default',
          value: !unitSummaryEconomyReport
            ? unitAccumulatedEconomyReport.totalSavingsPercentage
            : groupAccumulatedEconomyReport.totalSavingsPercentage,
        },
      },
      {
        icon: 'CreditCardIcon',
        title: 'Custo',
        label: !unitSummaryEconomyReport
          ? unitAccumulatedEconomyReport.totalEnergyCost
          : groupAccumulatedEconomyReport.totalEnergyCost,
      },
      {
        icon: 'LightBulbIcon',
        title: 'Consumo',
        label: !unitSummaryEconomyReport
          ? unitAccumulatedEconomyReport.totalEnergyConsumption
          : groupAccumulatedEconomyReport.totalEnergyConsumption,
      },
    ];
  };

  useMemo(() => {
    if (user?.group) {
      setGroupEconomyGroupId(user.group.id);
      setMigrationReportGroupId(user.group.id);
    }
  }, [user]);

  useMemo(() => {
    if (groupEconomyReports.length) {
      const economyReports =
        unitId === GENERAL_PANEL_PARAMS
          ? groupEconomyReports
          : unitAccumulatedEconomyReport.summaryEconomyReportsHistory ?? [];

      const getReportsPeriods = (economyReports: any[]) => {
        return economyReports
          .map((report: any) => ({
            label: report.date,
            onClick: () => {
              const MonthlyEconomyReportPath = `${MONTHLY_ECONOMY_REPORTS_PATH}${
                unitAccumulatedEconomyReport.unitId
                  ? `/${unitAccumulatedEconomyReport.unitId}`
                  : `/${GENERAL_PANEL_PARAMS}`
              }/${report.date}`;
              setOpenPeriodSelector(false);
              window.open(`${window.location.origin}${MonthlyEconomyReportPath}`, '_blank');
              triggerButtonEvent(report.date, MonthlyEconomyReportPath);
            },
          }))
          .reverse();
      };

      setReportsPeriods(getReportsPeriods(economyReports));
    }
  }, [groupEconomyReports, unitAccumulatedEconomyReport]);

  useMemo(() => {
    if (
      groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports &&
      groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports.length > 0
    ) {
      generateReportsByUnitOrGeneralPopupList(groupAccumulatedEconomyReport);
    }
  }, [groupAccumulatedEconomyReport, unitAccumulatedEconomyReport]);

  useMemo(() => {
    if (rawSummaryEconomyReportsHistory) {
      setAccumulatedEconomyReportCharts(parseAccumulatedUnitEconomyReportCharts(rawSummaryEconomyReportsHistory));
    }
  }, [rawSummaryEconomyReportsHistory]);

  useMemo(() => {
    if (unitAccumulatedEconomyReport.summaryEconomyReportsHistory) {
      setAccumulatedEconomyReportHistoryTable(
        parserUnitAccumulatedEconomyReportHistoryTable(
          unitAccumulatedEconomyReport.summaryEconomyReportsHistory,
        ).reverse(),
      );
    }
  }, [unitAccumulatedEconomyReport]);

  useMemo(() => {
    if (unitId) {
      if (unitId !== GENERAL_PANEL_PARAMS) {
        setUnitId(unitId);
        setUnitSummaryEconomyReport(undefined);
      } else {
        setUnitSummaryEconomyReport(groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports);
        setAccumulatedEconomyReportCharts(parseGroupEconomyReportCharts(rawGroupEconomyReports));
        setAccumulatedEconomyReportHistoryTable(parserGroupEconomyReportHistoryTable(groupEconomyReports).reverse());
      }
    }
  }, [unitId, groupAccumulatedEconomyReport, rawGroupEconomyReports, groupEconomyReports]);

  useMemo(() => {
    if (unitSummaryEconomyReport && unitSummaryEconomyReport?.length <= 1) {
      setUnitsEconomyGroupId(groupAccumulatedEconomyReport.groupId);
    }
  }, [unitSummaryEconomyReport]);

  useMemo(() => {
    const groupHasOnlyOneUnit = rawHistoryUnitEconomyReport.length > 0 && unitId === GENERAL_PANEL_PARAMS;

    if (groupHasOnlyOneUnit) {
      const unitAccumulatedEconomyReportPath = `${ACCUMULATED_ECONOMY_REPORTS_PATH}/${rawHistoryUnitEconomyReport[0].unit.id}`;
      navigate(unitAccumulatedEconomyReportPath);

      const reportsPeriods = rawHistoryUnitEconomyReport
        .map((report) => {
          const unitMonthlyEconomyReportPath = `${MONTHLY_ECONOMY_REPORTS_PATH}/${
            rawHistoryUnitEconomyReport[0].unit.id
          }/${formatMonthAndYearDate(report.date)}`;
          return {
            label: formatMonthAndYearDate(report.date),
            onClick: () => {
              setOpenPeriodSelector(false);
              window.open(`${window.location.origin}${unitMonthlyEconomyReportPath}`, '_blank');
              triggerButtonEvent(formatMonthAndYearDate(report.date), unitMonthlyEconomyReportPath);
            },
          };
        })
        .reverse();

      setReportsPeriods(reportsPeriods);
    }
  }, [rawHistoryUnitEconomyReport]);

  if (isFetchingUser || groupLoading || unitLoading) {
    return (
      <Layout darkerBackground loading>
        <HomeSkeleton />
      </Layout>
    );
  }

  return (
    <div ref={exportRef}>
      <Layout
        title={`Relatório de economia`}
        navigationHistoryLinks={[
          { label: 'Home', url: HOME_PATH },
          { label: 'Relatório de economia', url: '#' },
        ]}
        headerButtons={user.group?.name.length ? generateHeaderButtonsProps() : []}
        className="relative h-full"
        darkerBackground
      >
        {unitSummaryEconomyReport || Object.keys(unitAccumulatedEconomyReport).length > 0 ? (
          <>
            {reportsPeriods && (
              <PopUpList
                items={reportsPeriods}
                show={openPeriodSelector}
                className="absolute mr-8 -mt-8 cursor-pointer sm:right-24 sm:top-32"
              />
            )}
            <div className={'grid grid-cols-3 col-span-full gap-8'}>
              <React.Fragment key={groupAccumulatedEconomyReport.id}>
                <PageSectionGenerator
                  title={`Painel ${unitSummaryEconomyReport ? 'Geral' : unitAccumulatedEconomyReport.unitName}`}
                  buttonIconProps={
                    reportsByUnitOrGeneral && {
                      openPopupList: openReportByUnitOrGeneralSelector,
                      setOpenPopupList: () => setOpenReportByUnitOrGeneralSelector(!openReportByUnitOrGeneralSelector),
                    }
                  }
                  popUpListProps={
                    reportsByUnitOrGeneral && {
                      items: reportsByUnitOrGeneral,
                      show: openReportByUnitOrGeneralSelector,
                    }
                  }
                >
                  <>
                    <div className={'grid grid-cols-1 gap-6 w-full lg:grid-cols-2 xl:grid-cols-3'}>
                      {getAccumulatedEconomyReportIconCardProps().map((item, index) => (
                        <IconCard
                          className="col-span-1"
                          key={`iconCard-${index}`}
                          title={item.title}
                          icon={item.icon}
                          label={item.label}
                          tag={item.tag && item.tag}
                        />
                      ))}
                    </div>
                  </>
                </PageSectionGenerator>
                <PageSectionGenerator title="Histórico dos Resultados">
                  <div className={`flex flex-col gap-6 ${!unitSummaryEconomyReport ? 'mb-10' : ''}`}>
                    {accumulatedEconomyReportCharts && (
                      <>
                        <div className={'grid grid-cols-1 gap-6 w-full lg:grid-cols-2 xl:grid-cols-3'}>
                          {Object.keys(chartsInformation).map((chartKey, index) => {
                            const chartInfo = chartsInformation[chartKey as IHistoryChartTitle];
                            const chartData = accumulatedEconomyReportCharts[chartKey as IHistoryChartTitle];
                            if (chartData) {
                              return (
                                <GeneralGadget
                                  key={index}
                                  title={chartInfo.title}
                                  tooltipContent={chartInfo.tooltipContent}
                                  className={index === 2 ? 'col-span-full xl:col-span-1' : 'col-span-1'}
                                >
                                  <BarChartComponent
                                    chartLabels={chartData.labels}
                                    chartValues={chartData.values as number[]}
                                    barHighlight={chartInfo.highlightChart}
                                    currencyData={chartData.currencyData}
                                    suffix={chartData.suffix}
                                    className="w-fit"
                                  />
                                </GeneralGadget>
                              );
                            }
                          })}
                        </div>
                        {accumulatedEconomyReportHistoryTable && (
                          <GeneralGadget
                            title="Histórico"
                            tooltipContent="Tabela com o histórico dos resultados de economia, custo e consumo mês a mês, compilado de todas as unidades no Mercado Livre de Energia."
                          >
                            <div className="overflow-y-auto h-fit max-h-[15rem]">
                              <Table<IHistoryTable>
                                tableColumns={columnDefinitions}
                                data={accumulatedEconomyReportHistoryTable}
                                onRowClickAction={(entry) => handleTableOrBtnClick(entry)}
                              />
                            </div>
                          </GeneralGadget>
                        )}
                      </>
                    )}
                  </div>
                </PageSectionGenerator>
                {unitSummaryEconomyReport && (
                  <PageSectionGenerator title="Resultado por unidade" className="mb-10">
                    <div className={'grid grid-cols-1 gap-6 w-full lg:grid-cols-2 xl:grid-cols-3'}>
                      {unitSummaryEconomyReport.map(({ unitName, unitId, economy, energyConsumption, energyCost }) => (
                        <div key={unitName} className="col-span-1 min-w-[230px]">
                          <SlideshowGadget
                            title={unitName}
                            gadgetContent={formatGadgetContent({ economy, energyConsumption, energyCost })}
                            actionButtons={[
                              {
                                buttonType: 'buttonInline',
                                label: 'Ver mais',
                                size: 'xSmall',
                                className: 'px-4 border-neutral-30',
                                onClick: () => {
                                  const accumulatedEconomyReportUnitPath = `${ACCUMULATED_ECONOMY_REPORTS_PATH}/${unitId}`;
                                  window.open(`${window.location.origin}${accumulatedEconomyReportUnitPath}`, '_blank');
                                  triggerButtonEvent('Ver mais', accumulatedEconomyReportUnitPath);
                                },
                                kind: 'ghost',
                              },
                            ]}
                          />
                        </div>
                      ))}
                    </div>
                  </PageSectionGenerator>
                )}
              </React.Fragment>
            </div>
          </>
        ) : (
          <GenerateMessageByMigrationMoment migrationReportByUnits={migrationReportByGroup.units} />
        )}
      </Layout>
    </div>
  );
};

export default AccumulatedEconomyReportPage;
