import React from 'react';
import numeral from 'numeral';
import { ButtonProps, GeneralGadget, Table } from '@clarke-energia/foton';
import {
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
} from '@contexts/group-economy/types';
import { UnitEconomyReportDataResponse } from '@contexts/units-economy/types';
import { triggerAmplitudeButtonClickedEvent } from '@services/event-tracking/events-trigger';

type IEnergyMarketType = 'ACL' | 'ACR';
interface IPreviewGadgetContentGenerator {
  totalValue?: number;
  marketType: IEnergyMarketType;
}

const PreviewGadgetContentGenerator = ({ totalValue, marketType }: IPreviewGadgetContentGenerator): JSX.Element => (
  <div className="flex flex-col gap-3">
    <div>Total Mercado {marketType === 'ACL' ? 'Livre' : 'Cativo'} (c/impostos)</div>
    <div className="text-heading-xlarge">{numeral(totalValue).format('$ 0,0.00')}</div>
  </div>
);

interface IReportGadgetGenerator {
  unitEconomyReport: UnitEconomyReportDataResponse;
  tables: Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>;
  marketType: IEnergyMarketType;
}

export const ReportGadgetGenerator = ({
  unitEconomyReport,
  tables,
  marketType,
}: IReportGadgetGenerator): JSX.Element => {
  const usedReport = marketType === 'ACL' ? unitEconomyReport.freeMarket : unitEconomyReport.conventionalMarket;

  return (
    <GeneralGadget
      title={marketType === 'ACL' ? 'Custos Mercado Livre' : 'Simulação Mercado Cativo'}
      tooltipContent={
        marketType === 'ACL'
          ? 'Esse é o valor da sua conta de luz no Mercado Livre. Clicando em ”Ver Mais” é possível ver o detalhamento da fatura de distribuição e de fornecimento.'
          : "Simulamos como a sua conta de luz seria se estivesse no Mercado Cativo. A partir desse número chegamos quanto foi a economia. É possível ver o detalhamento clicando em 'Ver Mais'"
      }
      className="col-span-1 h-fit"
    >
      <div className="flex flex-col gap-8">
        <PreviewGadgetContentGenerator marketType={marketType} totalValue={usedReport.totalValue} />
        {tables &&
          Object.values(tables).map((table, index) => (
            <div key={index} className="flex overflow-x-auto flex-col gap-8">
              <Table<IGeneralReportTable | IMinimalGeneralReportTable>
                data={table.data}
                tableColumns={table.columnDefinitions}
                superHeader={table.superheader}
                lastRow={{
                  fontBold: true,
                }}
              />
            </div>
          ))}
        <div className="flex justify-between py-3 pr-5 pl-6 font-bold bg-primary-60 text-paragraph-small">
          <div>Total Mercado {marketType === 'ACL' ? 'Livre' : 'Cativo'} (s/impostos)</div>
          <div>{numeral(usedReport.totalValueWithoutTax).format('$ 0,0.00')}</div>
        </div>
      </div>
    </GeneralGadget>
  );
};

export interface IActionsButtons extends ButtonProps {
  buttonType?: 'buttonInline' | 'button' | 'buttonIcon';
}

export const triggerButtonEvent = (buttonText: string, destinationPath?: string) => {
  return triggerAmplitudeButtonClickedEvent('Relatório de Economia Acumulado', buttonText, destinationPath);
};
