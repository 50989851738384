import React from 'react';

import { Category, IResponsible } from '@contexts/migration-report/types';
import { capitalizeWords } from '@utils/text';

interface AvatarWithNameProps {
  responsible: IResponsible;
  avatarImage: string | null;
}

const AvatarWithName: React.FC<AvatarWithNameProps> = ({ responsible, avatarImage }) => {
  const isCustomer = responsible.category === Category.CUSTOMER;
  const bgColorClass = isCustomer ? 'bg-[#970DF2]' : 'bg-[#FFE500]';

  const MAXIMUM_TASK_RESPONSIBLE_NAME_CHARACTERS = 50;

  return (
    <div className="flex gap-3">
      {responsible.name.length < MAXIMUM_TASK_RESPONSIBLE_NAME_CHARACTERS ? (
        <>
          <div
            className={`flex justify-center text-center items-center rounded-full ${bgColorClass} w-[1rem] h-[1rem] border-[1px] border-black`}
          >
            {avatarImage ? (
              <img src={avatarImage} />
            ) : (
              <div className={`w-full font-bold text-center ${isCustomer && 'text-white'}`}>
                {capitalizeWords(responsible.name[0])}
              </div>
            )}
          </div>
          <span>{capitalizeWords(responsible.name)}</span>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};

export default AvatarWithName;
