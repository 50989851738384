import { IHistoryChartTitle } from '@contexts/group-economy/types';
interface IChartInformation {
  title: string;
  tooltipContent: string;
  highlightChart: boolean;
}
export const chartsInformation: Record<IHistoryChartTitle, IChartInformation> = {
  economyHistory: {
    title: 'Economia mês a mês (R$)',
    tooltipContent:
      'Gráfico da economia compilada de todas as unidades no Mercado Livre de Energia, em reais mês a mês.',
    highlightChart: true,
  },
  costsHistory: {
    title: 'Custo mês a mês (R$)',
    tooltipContent: 'Gráfico de custos compilados de todas as unidades mês a mês',
    highlightChart: true,
  },
  consumptionHistory: {
    title: 'Histórico de consumo (MWh)',
    tooltipContent:
      'Gráfico da quantidade de consumo de energia compilado de todas as unidades no Mercado Livre de Energia, em MWh mês a mês.',
    highlightChart: true,
  },
};
