import moment from 'moment';
import { ColumnOptions } from '@clarke-energia/foton';

import numeral from '@utils/numeral';
import { formatCurrency, formatMonthAndYearDate, formatNumber, upperFirstLetter } from '@utils/text';
import {
  IGeneralReportTable,
  IGeneralReportTableData,
  IHistoryChartDataset,
  IHistoryCharts,
  IMinimalGeneralReportTable,
  SummaryEconomyReportsHistory,
} from '@contexts/group-economy/types';

import {
  UnitAccumulatedEconomyReportByUnitId,
  UnitAccumulatedEconomyReportByUnitIdParsed,
  SummaryEconomyReportsHistoryParsed,
  UnitEconomyReportDataResponse,
  UnitReportParsed,
} from './types';
import { UnitEconomyContextType } from '.';

const percentOption = {
  style: 'percent',
  maximumFractionDigits: 2,
};

export const freeMarketTablesGenerator = (report: UnitEconomyReportDataResponse) => {
  const freeMarketReport = report.freeMarket;

  const distributionReportData = freeMarketReport.energyDistribution;
  const distributionTable: IGeneralReportTableData<IGeneralReportTable> = {
    columnDefinitions: generalMarketColumnDefinitions,
    data: [
      ...(distributionReportData.billedDemandPeakAmount
        ? [
            {
              item: 'Demanda Ponta (kW)',
              quantity: formatNumber(distributionReportData.billedDemandPeakAmount),
              tariff: formatNumber(distributionReportData.billedDemandPeakCost),
              total: numeral(distributionReportData.billedDemandPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Demanda Fora Ponta (kW)',
        quantity: formatNumber(distributionReportData.billedDemandOffPeakAmount),
        tariff: formatNumber(distributionReportData.billedDemandOffPeakCost),
        total: numeral(distributionReportData.billedDemandOffPeakTotal).format('$ 0,0.00'),
      },
      ...(distributionReportData.acrBilledDemandPeakTotal
        ? [
            {
              item: 'Demanda Ponta ACR (kW)',
              quantity: formatNumber(distributionReportData.acrBilledDemandPeakAmount),
              tariff: formatNumber(distributionReportData.acrBilledDemandPeakCost),
              total: numeral(distributionReportData.acrBilledDemandPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(distributionReportData.acrBilledDemandOffPeakTotal
        ? [
            {
              item: 'Demanda Fora Ponta ACR (kW)',
              quantity: formatNumber(distributionReportData.acrBilledDemandOffPeakAmount),
              tariff: formatNumber(distributionReportData.acrBilledDemandOffPeakCost),
              total: numeral(distributionReportData.acrBilledDemandOffPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'TUSD Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tusdPeakAmount),
        tariff: formatNumber(distributionReportData.tusdPeakCost),
        total: numeral(distributionReportData.tusdPeakTotal).format('$ 0,0.00'),
      },
      {
        item: 'TUSD Fora Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tusdOffPeakAmount),
        tariff: formatNumber(distributionReportData.tusdOffPeakCost),
        total: numeral(distributionReportData.tusdOffPeakTotal).format('$ 0,0.00'),
      },
      ...(distributionReportData.acrTePeakTotal
        ? [
            {
              item: 'TE Ponta ACR (MWh)',
              quantity: formatNumber(distributionReportData.acrBilledDemandPeakAmount),
              tariff: formatNumber(distributionReportData.acrBilledDemandPeakCost),
              total: numeral(distributionReportData.acrBilledDemandPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(distributionReportData.acrTeOffPeakTotal
        ? [
            {
              item: 'TE Fora Ponta ACR (MWh)',
              quantity: formatNumber(distributionReportData.acrBilledDemandOffPeakAmount),
              tariff: formatNumber(distributionReportData.acrBilledDemandOffPeakCost),
              total: numeral(distributionReportData.acrBilledDemandOffPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(distributionReportData.covidTariffTotal
        ? [
            {
              item: 'Encargo COVID-19 + Escassez Hídrica',
              quantity: formatNumber(distributionReportData.covidTariffAmount),
              tariff: formatNumber(distributionReportData.covidTariffCost),
              total: numeral(distributionReportData.covidTariffTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(distributionReportData.overtakingTotal
        ? [
            {
              item: 'Ultrapassagem',
              quantity: '-',
              tariff: '-',
              total: numeral(distributionReportData.overtakingTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Reativos',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.reactivesTotal).format('$ 0,0.00'),
      },
      {
        item: 'Impostos',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.taxesTotal).format('$ 0,0.00'),
      },
      {
        item: 'Outros',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.others).format('$ 0,0.00'),
      },
      {
        item: 'Total',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.totalValue).format('$ 0,0.00'),
      },
    ],
    superheader: `Distribuição ${report.unit.edc.name ? `- ${report.unit.edc.name}` : ''}`,
  };

  const providedEnergyReportData = freeMarketReport.energyProvided;
  const shouldNotDeclareDevec = report.devecDeclaration === null;
  const providedEnergyTable: IGeneralReportTableData<IGeneralReportTable> = {
    columnDefinitions: generalMarketColumnDefinitions,
    data: [
      {
        item: report.trader.name,
        quantity: formatNumber(providedEnergyReportData.energyProvidedAmount, {
          style: 'decimal',
          useGrouping: true,
          maximumFractionDigits: 3,
        }),
        tariff: formatNumber(providedEnergyReportData.energyProvidedCost),
        total: numeral(providedEnergyReportData.energyProvidedTotal).format('$ 0,0.00'),
      },
      {
        item: '↪ Destaque PIS/COFINS',
        quantity: '-',
        tariff: formatNumber(providedEnergyReportData.highlightPisCofinsCost, percentOption),
        total: numeral(providedEnergyReportData.highlightPisCofinsTotal).format('$ 0,0.00'),
      },
      ...(providedEnergyReportData.energyProvidedAdjustmentValue
        ? [
            {
              item: 'Devoluções/Ajustes',
              quantity: '-',
              tariff: '-',
              total: numeral(providedEnergyReportData.energyProvidedAdjustmentValue).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(shouldNotDeclareDevec
        ? [
            {
              item: 'ICMS',
              quantity: '-',
              tariff: formatNumber(providedEnergyReportData.icmsCost, percentOption),
              total: numeral(providedEnergyReportData.icmsTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Total Fornecimento (c/ impostos)',
        quantity: '-',
        tariff: '-',
        total: numeral(providedEnergyReportData.totalValue).format('$ 0,0.00'),
      },
    ],
    superheader: 'Fornecimento',
  };

  const othersReportData = freeMarketReport.others;
  const othersTable: IGeneralReportTableData<IMinimalGeneralReportTable> = {
    columnDefinitions: generalMarketMinimalColumnDefinitions,
    data: [
      ...(othersReportData.eerCost
        ? [
            {
              item: 'EER',
              total: numeral(othersReportData.eerCost).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(othersReportData.essCost
        ? [
            {
              item: 'Liquidação Financeira + ESS',
              total: numeral(othersReportData.essCost).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(othersReportData.cceeCost
        ? [
            {
              item: 'Contribuição Associativa',
              total: numeral(othersReportData.cceeCost).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Gestão Clarke',
        total: numeral(othersReportData.clarkeManagementCost).format('$ 0,0.00'),
      },
    ],
    superheader: 'Encargos / Outros',
  };

  return [distributionTable, providedEnergyTable, othersTable];
};

export const conventionalMarketTablesGenerator = (report: UnitEconomyReportDataResponse) => {
  const conventionalMarketReport = report.conventionalMarket;

  const distributionReportData = conventionalMarketReport;
  const distributionTable: IGeneralReportTableData<IGeneralReportTable> = {
    columnDefinitions: generalMarketColumnDefinitions,
    data: [
      ...(distributionReportData.billedDemandPeakAmount
        ? [
            {
              item: 'Demanda Ponta (kW)',
              quantity: formatNumber(distributionReportData.billedDemandPeakAmount),
              tariff: formatNumber(distributionReportData.billedDemandPeakCost),
              total: numeral(distributionReportData.billedDemandPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Demanda  Fora Ponta (kW)',
        quantity: formatNumber(distributionReportData.billedDemandOffPeakAmount),
        tariff: formatNumber(distributionReportData.billedDemandOffPeakCost),
        total: numeral(distributionReportData.billedDemandOffPeakTotal).format('$ 0,0.00'),
      },
      {
        item: 'TUSD Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tusdPeakAmount),
        tariff: formatNumber(distributionReportData.tusdPeakCost),
        total: numeral(distributionReportData.tusdPeakTotal).format('$ 0,0.00'),
      },
      {
        item: 'TUSD Fora Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tusdOffPeakAmount),
        tariff: formatNumber(distributionReportData.tusdOffPeakCost),
        total: numeral(distributionReportData.tusdOffPeakTotal).format('$ 0,0.00'),
      },

      {
        item: 'TE Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tePeakAmount),
        tariff: formatNumber(distributionReportData.tePeakCost),
        total: numeral(distributionReportData.tePeakTotal).format('$ 0,0.00'),
      },
      {
        item: 'TE Fora Ponta (MWh)',
        quantity: formatNumber(distributionReportData.teOffPeakAmount),
        tariff: formatNumber(distributionReportData.teOffPeakCost),
        total: numeral(distributionReportData.teOffPeakTotal).format('$ 0,0.00'),
      },

      ...(distributionReportData.overtakingTotal
        ? [
            {
              item: 'Ultrapassagem',
              quantity: '-',
              tariff: '-',
              total: numeral(distributionReportData.overtakingTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Reativos',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.reactivesTotal).format('$ 0,0.00'),
      },
      {
        item: 'Bandeira Tarifária',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.tariffFlagTotal).format('$ 0,0.00'),
      },
      {
        item: 'Impostos',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.taxesTotal).format('$ 0,0.00'),
      },
      {
        item: 'Outros',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.others).format('$ 0,0.00'),
      },
      {
        item: 'Total',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.totalValue).format('$ 0,0.00'),
      },
    ],
    superheader: `Distribuição ${report.unit.edc.name ? `- ${report.unit.edc.name}` : ''}`,
  };

  return [distributionTable];
};

export const formatUnitsEconomyReports = (unitReports: UnitEconomyReportDataResponse[]): UnitReportParsed[] => {
  const unitReportsSortedByPeriod = unitReports.sort(
    (current, next) => moment(current.date).unix() - moment(next.date).unix(),
  );
  return unitReportsSortedByPeriod.map((unitReport) => {
    return {
      reportId: unitReport.id,
      unitName: unitReport.unit.name,
      unitId: unitReport.unit.id,
      date: unitReport.date,
      conventionalMarket: conventionalMarketTablesGenerator(unitReport),
      freeMarket: freeMarketTablesGenerator(unitReport),
      economy: {
        total: numeral(unitReport.currentSavings).format('$ 0,0.00'),
        percentage: `${unitReport.savingsPercent.toFixed(2).replace('.', ',')}%`,
        typePercentageStyle: unitReport.savingsPercent < 0 ? 'downwarding' : 'default',
      },
      energyConsumption: {
        total: `${numeral(unitReport.energyTotalConsumption || 0).format('0,0.00')} MWh`,
        percentageBalance: unitReport.energyConsumptionBalancePercent
          ? `${unitReport.energyConsumptionBalancePercent.toFixed().replace('.', ',')}%`
          : '',
        typePercentageStyle:
          unitReport.energyConsumptionBalancePercent && unitReport.energyConsumptionBalancePercent > 0
            ? 'upwarding'
            : 'downwarding',
      },
      energyCost: {
        total: numeral(unitReport.energyTotalCost).format('$ 0,0.00'),
        percentageBalance: unitReport.energyConsumptionBalancePercent
          ? `${unitReport.energyConsumptionBalancePercent.toFixed().replace('.', ',')}%`
          : '',
        typePercentageStyle:
          unitReport.energyConsumptionBalancePercent && unitReport.energyConsumptionBalancePercent > 0
            ? 'upwarding'
            : 'downwarding',
      },
    };
  });
};

export const parseUnitEconomyReportCharts = (
  unitReports: UnitEconomyReportDataResponse[],
  unitId: string,
): IHistoryCharts => {
  const verifyHistoryValidity = (historyObject: IHistoryChartDataset) =>
    historyObject.values.filter((value) => value !== null).length > 0 ? historyObject : null;
  const unitReportsById = unitReports
    .filter((unitReport) => unitReport.unit.id === unitId)
    .sort((current, next) => moment(current.date).unix() - moment(next.date).unix());

  const historyMonthsLabels = unitReportsById.map((unitReport) => moment(unitReport.date).format('MM/YY'));
  const historyMonthsValues = unitReportsById.map((unitReport) => unitReport.currentSavings);
  const economyHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: historyMonthsValues,
    currencyData: true,
  };

  const consumptionHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: unitReportsById.map((unitReport) => unitReport.energyTotalConsumption),
  };

  const costsHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: unitReportsById.map((unitReport) => unitReport.energyTotalCost),
  };

  return {
    economyHistory: verifyHistoryValidity(economyHistory),
    consumptionHistory: verifyHistoryValidity(consumptionHistory),
    costsHistory: verifyHistoryValidity(costsHistory),
  };
};

export interface IHistoryTable {
  month: string;
  economy: string;
  expense: string;
  consumption: string;
}

export const parserUnitEconomyReportHistoryTable = (
  unitsEconomyReports: UnitEconomyContextType['unitsEconomyReports'],
  unitId: string,
): Array<IHistoryTable> => {
  const unitEconomyReportHistoryTable: Array<IHistoryTable | undefined> = Object.keys(unitsEconomyReports)
    .map((reportPeriod) => {
      const unitReport = unitsEconomyReports[reportPeriod].find((unitReport) => unitReport.unitId === unitId);
      if (unitReport) {
        return {
          month: upperFirstLetter(moment(unitReport.date).format('MMMM YYYY')) ?? '',
          economy: unitReport.economy.total,
          expense: unitReport.energyCost.total,
          consumption: unitReport.energyConsumption.total,
        };
      } else return undefined;
    })
    .filter((monthReport) => monthReport !== undefined);

  return unitEconomyReportHistoryTable as Array<IHistoryTable>;
};

const generalMarketColumnDefinitions: ColumnOptions<IGeneralReportTable>[] = [
  { accessor: 'item', header: 'Item', renderAsElement: (report) => report.item },
  { accessor: 'quantity', header: 'Qtd.', fixedWidthInRem: 7, renderAsElement: (report) => report.quantity },
  { accessor: 'tariff', header: 'Tarifa', fixedWidthInRem: 7, renderAsElement: (report) => report.tariff },
  { accessor: 'total', header: 'Total', fixedWidthInRem: 6, renderAsElement: (report) => report.total },
];

const generalMarketMinimalColumnDefinitions: ColumnOptions<IMinimalGeneralReportTable>[] = [
  { accessor: 'item', header: 'Item', renderAsElement: (report) => report.item },
  { accessor: 'total', header: 'Total', fixedWidthInRem: 6.7, renderAsElement: (report) => report.total },
];

export const parserUnitAccumulatedEconomyReport = (
  accumulatedEconomyReport: UnitAccumulatedEconomyReportByUnitId,
): UnitAccumulatedEconomyReportByUnitIdParsed => {
  return {
    id: accumulatedEconomyReport.id,
    groupId: accumulatedEconomyReport.group.id,
    groupName: accumulatedEconomyReport.group.name,
    unitId: accumulatedEconomyReport.unit.id,
    unitName: accumulatedEconomyReport.unit.name,
    totalCarbonDioxideEmissionAvoided: numeral(accumulatedEconomyReport.totalCarbonDioxideEmissionAvoided).format(
      '0,0',
    ),
    totalEnergyConsumption: `${numeral(accumulatedEconomyReport.totalEnergyConsumption || 0).format('0,0.00')} MWh`,
    totalEnergyCost: formatCurrency(accumulatedEconomyReport.totalEnergyCost),
    totalSavings: formatCurrency(accumulatedEconomyReport.totalSavings),
    totalSavingsPercentage: numeral(accumulatedEconomyReport.totalSavingsPercentage / 100).format('% 0'),
    totalTreesSaved: numeral(accumulatedEconomyReport.totalTreesSaved).format('0,0'),
    totalSavingsPercentageTagType: accumulatedEconomyReport.totalSavings > 0 ? 'default' : 'downwarding',
    type: accumulatedEconomyReport.type,
    summaryEconomyReportsHistory: formatSummaryEconomyReportsHistory(
      accumulatedEconomyReport.summaryEconomyReportsHistory ?? [],
    ),
    createdAt: accumulatedEconomyReport.createdAt,
    updatedAt: accumulatedEconomyReport.updatedAt,
  };
};

export const formatSummaryEconomyReportsHistory = (
  summaryEconomyReportsHistory: SummaryEconomyReportsHistory[],
): SummaryEconomyReportsHistoryParsed[] => {
  return summaryEconomyReportsHistory.map((summaryEconomyReport) => ({
    id: summaryEconomyReport.id,
    date: formatMonthAndYearDate(summaryEconomyReport.date),
    monthlySavings: {
      total: numeral(summaryEconomyReport.monthlySavings).format('$ 0,0.00'),
      percentage: `${
        summaryEconomyReport.monthlySavingsPercent
          ? summaryEconomyReport.monthlySavingsPercent.toFixed().replace('.', ',')
          : 0
      }%`,
      typePercentageStyle: 'default',
    },
    monthlyEnergyConsumption: {
      total: `${numeral(summaryEconomyReport.monthlyEnergyConsumption || 0).format('0,0.00')} MWh`,
      percentage: '',
      typePercentageStyle: 'default',
    },
    monthlyEnergyCost: {
      total: numeral(summaryEconomyReport.monthlyEnergyCost).format('$ 0,0.00'),
      percentage: '',
      typePercentageStyle: 'default',
    },
    monthlyCarbonDioxideEmissionAvoided: numeral(summaryEconomyReport.monthlyCarbonDioxideEmissionAvoided ?? 0).format(
      '0,0',
    ),
    monthlyTreesSaved: numeral(summaryEconomyReport.monthlyTreesSaved ?? 0).format('0,0'),
  }));
};

export const parseAccumulatedUnitEconomyReportCharts = (
  unitSummaryReportHistory: SummaryEconomyReportsHistory[],
): IHistoryCharts => {
  const verifyHistoryValidity = (historyObject: IHistoryChartDataset) =>
    historyObject.values.filter((value) => value !== null).length > 0 ? historyObject : null;

  const unitSummaryReportHistorySorted = unitSummaryReportHistory.sort(
    (current, next) => moment(current.date).unix() - moment(next.date).unix(),
  );

  const historyMonthsLabels = unitSummaryReportHistorySorted.map((report) =>
    moment(report.date, 'YYYY-MM-DD').format('MM/YY'),
  );

  const economyHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: unitSummaryReportHistorySorted.map((report) => report.monthlySavings ?? 0),
    currencyData: true,
  };

  const costsHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: unitSummaryReportHistorySorted.map((report) => report.monthlyEnergyCost ?? 0),
    currencyData: true,
  };

  const consumptionHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: unitSummaryReportHistorySorted.map((report) => report.monthlyEnergyConsumption ?? 0),
    suffix: 'MWh',
  };

  return {
    economyHistory: verifyHistoryValidity(economyHistory),
    costsHistory: verifyHistoryValidity(costsHistory),
    consumptionHistory: verifyHistoryValidity(consumptionHistory),
  };
};

export const parserUnitAccumulatedEconomyReportHistoryTable = (
  data: SummaryEconomyReportsHistoryParsed[],
): IHistoryTable[] => {
  const groupEconomyReportHistoryTable: IHistoryTable[] = data.map((report) => ({
    month: report.date,
    economy: report.monthlySavings.total,
    expense: report.monthlyEnergyCost.total,
    consumption: report.monthlyEnergyConsumption.total,
  }));

  return groupEconomyReportHistoryTable;
};
