import React from 'react';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import {
  DeepRequired,
  FieldValues,
  FieldErrorsImpl,
  FieldName,
  FieldPath,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';

export interface RadioFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  inputOptions: { id: string; optionLabel: string; value?: string | number }[];
  defaultValue?: number | string;
  label?: string;
  options?: RegisterOptions;
  horizontalLayout?: boolean;
}

export function RadioFormField<T extends FieldValues>({
  field,
  defaultValue,
  label,
  inputOptions,
  options,
  horizontalLayout,
}: RadioFormFieldProps<T>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();

  const isInputDisabled = options?.disabled === true;
  const inputColorClass = isInputDisabled ? 'text-neutral-50' : 'text-primary-60';
  const textColorClass = isInputDisabled ? 'text-neutral-50' : '';

  return (
    <div className="flex flex-col space-y-1">
      {label && (
        <label className="text-paragraph-medium">
          {label}
          {options?.required && <span className="text-danger-60">*</span>}
        </label>
      )}
      <div className={`flex ${horizontalLayout ? 'flex-row gap-7' : 'flex-col gap-4'}`}>
        {inputOptions.map(({ id, value, optionLabel }, index) => (
          <div
            key={index}
            className={`flex items-center ${horizontalLayout ? 'flex-col-reverse gap-2' : 'flex-row gap-4'}`}
          >
            <input
              id={id}
              data-cy={id}
              type="radio"
              className={inputColorClass}
              value={value}
              defaultChecked={defaultValue === value}
              {...register(field, options)}
            />
            <label htmlFor={id} className={`text-paragraph-medium ${textColorClass}`}>
              {optionLabel}
            </label>
          </div>
        ))}
      </div>
      <ErrorMessage
        name={field as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrorsImpl<DeepRequired<T>>>>}
        errors={errors}
        render={({ message }) => <span className="text-paragraph-medium text-danger-30">{message}</span>}
      />
    </div>
  );
}
