import React from 'react';

import { ValuesPerYear } from '@contexts/compilation-results/types';
import numeral from 'numeral';

export type StyleOptions = {
  bold: boolean;
  border?: 'border-y' | 'border-t' | 'border-b';
  bgColor?: string;
};

interface CommonRowsProps {
  valuesPerYear: ValuesPerYear[];
  title: string;
  rowKey: keyof ValuesPerYear | 'EMPTY';
  total?: number;
  styleOptions?: StyleOptions;
}

const CommonRowsEnergyCompilationTable: React.FC<CommonRowsProps> = ({
  valuesPerYear,
  title,
  rowKey,
  total,
  styleOptions,
}) => {
  const className = `py-4 ${styleOptions?.bold ? 'font-bold' : 'font-normal'} text-start border-neutral-20 ${
    styleOptions ? styleOptions.border : 'border-y'
  } text-paragraph-small ${styleOptions?.bgColor ? styleOptions.bgColor : ''}`;
  return (
    <tr>
      <td className={className}>{title}</td>
      {valuesPerYear.map((item) => (
        <React.Fragment key={item.year}>
          <td className={className}>{rowKey === 'EMPTY' ? '-' : numeral(item[rowKey]).format('$ 0,0.00')}</td>
        </React.Fragment>
      ))}
      <td className={className}>{total ? numeral(total).format('$ 0,0.00') : '-'}</td>
    </tr>
  );
};

export default CommonRowsEnergyCompilationTable;
