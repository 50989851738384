import moment from 'moment';
import { GadgetContentProps } from '@clarke-energia/foton';

import {
  EconomyReport,
  GroupReportDataResponse,
  UnitReportParsed,
  GroupReportParsed,
  IHistoryChartDataset,
  IHistoryCharts,
  GroupAccumulatedEconomyReportByGroupId,
  UnitsAccumulatedSummaryEconomyReports,
  GroupAccumulatedEconomyReportByGroupIdParsed,
  ISustainabilityHistoryCharts,
  SummaryEconomyReportsHistory,
} from '@contexts/group-economy/types';
import { formatCurrency, formatMonthAndYearDate } from '@utils/text';
import numeral from '@utils/numeral';
import { formatSummaryEconomyReportsHistory } from '@contexts/units-economy/parser';

export type GadgetTagType = 'upwarding' | 'downwarding' | 'neutral' | 'default';

interface GadgetContent {
  economy: {
    total: string;
    percentage: string;
    typePercentageStyle: GadgetTagType;
  };
  energyConsumption: {
    total: string;
    percentageBalance: string;
    typePercentageStyle: GadgetTagType;
  };
  energyCost: {
    total: string;
    percentageBalance: string;
    typePercentageStyle: GadgetTagType;
  };
}

export const formatGadgetContent = ({
  economy,
  energyConsumption,
  energyCost,
}: GadgetContent): GadgetContentProps[] => [
  {
    title: 'Economia',
    value: economy.total,
    tag: {
      type: economy.typePercentageStyle,
      value: economy.percentage,
    },
  },
  {
    title: 'Custo',
    value: energyCost.total.replace(/[+-]/g, ''),
    ...(energyCost.percentageBalance && {
      tag: { type: energyCost.typePercentageStyle, value: energyCost.percentageBalance.replace(/[+-]/g, '') },
    }),
  },
  {
    title: 'Consumo',
    value: energyConsumption.total.replace(/[+-]/g, ''),
    ...(energyConsumption.percentageBalance && {
      tag: {
        type: energyConsumption.typePercentageStyle,
        value: energyConsumption.percentageBalance.replace(/[+-]/g, ''),
      },
    }),
  },
];

const formatGroupUnitReports = (unitReports: EconomyReport[]): UnitReportParsed[] => {
  return unitReports.map((unitReport) => ({
    unitId: unitReport.unit.id,
    unitName: unitReport.unit.name,
    date: unitReport.date,
    reportId: unitReport.id,
    economy: {
      total: numeral(unitReport.currentSavings).format('$ 0,0.00'),
      percentage: `${unitReport.savingsPercent.toFixed().replace('.', ',')}%`,
      typePercentageStyle: 'default',
    },
    energyConsumption: {
      total: `${numeral(unitReport.energyTotalConsumption || 0).format('0,0.00')} MWh`,
      percentageBalance: unitReport.energyConsumptionBalancePercent
        ? `${unitReport.energyConsumptionBalancePercent.toFixed().replace('.', ',')}%`
        : '',
      typePercentageStyle:
        unitReport.energyConsumptionBalancePercent && unitReport.energyConsumptionBalancePercent > 0
          ? 'upwarding'
          : 'downwarding',
    },
    energyCost: {
      total: numeral(unitReport.energyTotalCost).format('$ 0,0.00'),
      percentageBalance: unitReport.energyConsumptionBalancePercent
        ? `${unitReport.energyConsumptionBalancePercent.toFixed().replace('.', ',')}%`
        : '',
      typePercentageStyle:
        unitReport.energyConsumptionBalancePercent && unitReport.energyConsumptionBalancePercent > 0
          ? 'upwarding'
          : 'downwarding',
    },
  }));
};

export const parseEconomyReport = (data: GroupReportDataResponse[]): GroupReportParsed[] => {
  const groupReportsSortedByReferencePeriod = data.sort(
    (current, next) => moment(current.date).unix() - moment(next.date).unix(),
  );
  return groupReportsSortedByReferencePeriod.map(
    ({
      date,
      id,
      totalMonthlySavings,
      totalMonthlySavingsPercent,
      totalMonthlyEnergyConsumption,
      totalMonthlyEnergyCost,
      energyConsumptionBalancePercent,
      energyCostBalancePercent,
      economyReports,
      group: { name: groupName },
    }) => {
      return {
        id: id,
        groupName,
        date: formatMonthAndYearDate(date),
        compiledResult: {
          economy: {
            total: numeral(totalMonthlySavings || 0).format('$ 0,0.00'),
            percentage: `${totalMonthlySavingsPercent.toFixed(2).replace('.', ',')}%`,
            typePercentageStyle: 'default',
          },
          energyConsumption: {
            total: `${numeral(totalMonthlyEnergyConsumption || 0).format('0,0.00')} MWh`,
            percentageBalance: energyConsumptionBalancePercent
              ? `${energyConsumptionBalancePercent.toFixed(2).replace('.', ',')}%`
              : '',
            typePercentageStyle:
              energyConsumptionBalancePercent && energyConsumptionBalancePercent > 0 ? 'upwarding' : 'downwarding',
          },
          energyCost: {
            total: numeral(totalMonthlyEnergyCost || 0).format('$ 0,0.00'),
            percentageBalance: energyCostBalancePercent
              ? `${energyCostBalancePercent.toFixed(2).replace('.', ',')}%`
              : '',
            typePercentageStyle: energyCostBalancePercent && energyCostBalancePercent > 0 ? 'upwarding' : 'downwarding',
          },
        },
        unitsEconomyReports: formatGroupUnitReports(economyReports),
      };
    },
  );
};

export const parseGroupEconomyReportCharts = (groupReports: GroupReportDataResponse[]): IHistoryCharts => {
  const verifyHistoryValidity = (historyObject: IHistoryChartDataset) =>
    historyObject.values.filter((value) => value !== null).length > 0 ? historyObject : null;

  const groupReportsSortedByReferencePeriod = groupReports.sort(
    (current, next) => moment(current.date).unix() - moment(next.date).unix(),
  );

  const historyMonthsLabels = groupReportsSortedByReferencePeriod.map((report) =>
    moment(report.date, 'YYYY-MM-DD').format('MM/YY'),
  );

  const economyHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: groupReportsSortedByReferencePeriod.map((report) => report.totalMonthlySavings ?? 0),
    currencyData: true,
  };

  const costsHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: groupReportsSortedByReferencePeriod.map((report) => report.totalMonthlyEnergyCost ?? 0),
    currencyData: true,
  };

  const consumptionHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: groupReportsSortedByReferencePeriod.map((report) => report.totalMonthlyEnergyConsumption ?? 0),
    suffix: 'MWh',
  };

  return {
    economyHistory: verifyHistoryValidity(economyHistory),
    costsHistory: verifyHistoryValidity(costsHistory),
    consumptionHistory: verifyHistoryValidity(consumptionHistory),
  };
};

export interface IHistoryTable {
  month: string;
  economy: string;
  expense: string;
  consumption: string;
}

export const parserGroupEconomyReportHistoryTable = (data: GroupReportParsed[]): IHistoryTable[] => {
  const groupEconomyReportHistoryTable: IHistoryTable[] = data.map((report) => ({
    month: report.date,
    economy: report.compiledResult.economy.total,
    expense: report.compiledResult.energyCost.total,
    consumption: report.compiledResult.energyConsumption.total,
  }));

  return groupEconomyReportHistoryTable;
};

export const formatUnitsAccumulatedSummaryEconomyReportsFromGroup = (
  unitsAccumulatedSummaryEconomyReports: UnitsAccumulatedSummaryEconomyReports[],
): UnitReportParsed[] => {
  return unitsAccumulatedSummaryEconomyReports.map((unitAccumulatedSummaryReport) => {
    const economyPerecentageSavgins = unitAccumulatedSummaryReport.accumulatedUnitsSavingsPercentage ?? 0;
    return {
      unitId: unitAccumulatedSummaryReport.unit.id,
      unitName: unitAccumulatedSummaryReport.unit.name,
      date: unitAccumulatedSummaryReport.date,
      economy: {
        total: numeral(unitAccumulatedSummaryReport.accumulatedUnitsSavings).format('$ 0,0.00'),
        percentage: numeral(economyPerecentageSavgins / 100).format('%0'),
        typePercentageStyle:
          unitAccumulatedSummaryReport.accumulatedUnitsSavingsPercentage &&
          unitAccumulatedSummaryReport.accumulatedUnitsSavingsPercentage > 0
            ? 'default'
            : 'downwarding',
      },
      energyConsumption: {
        total: `${numeral(unitAccumulatedSummaryReport.accumulatedUnitsEnergyConsumption || 0).format('0,0.00')} MWh`,
        percentageBalance: unitAccumulatedSummaryReport.accumulatedUnitsEnergyConsumptionPercent
          ? `${unitAccumulatedSummaryReport.accumulatedUnitsEnergyConsumptionPercent.toFixed().replace('.', ',')}%`
          : '',
        typePercentageStyle:
          unitAccumulatedSummaryReport.accumulatedUnitsEnergyConsumptionPercent &&
          unitAccumulatedSummaryReport.accumulatedUnitsEnergyConsumptionPercent > 0
            ? 'upwarding'
            : 'downwarding',
      },
      energyCost: {
        total: numeral(unitAccumulatedSummaryReport.accumulatedUnitsEnergyCost).format('$ 0,0.00'),
        percentageBalance: unitAccumulatedSummaryReport.accumulatedUnitsEnergyCostPercent
          ? `${unitAccumulatedSummaryReport.accumulatedUnitsEnergyCostPercent.toFixed().replace('.', ',')}%`
          : '',
        typePercentageStyle:
          unitAccumulatedSummaryReport.accumulatedUnitsEnergyCostPercent &&
          unitAccumulatedSummaryReport.accumulatedUnitsEnergyCostPercent > 0
            ? 'upwarding'
            : 'downwarding',
      },
    };
  });
};

export const parserGroupAccumulatedEconomyReport = (
  groupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupId,
): GroupAccumulatedEconomyReportByGroupIdParsed => {
  return {
    id: groupAccumulatedEconomyReport.id,
    groupId: groupAccumulatedEconomyReport.group.id,
    groupName: groupAccumulatedEconomyReport.group.name,
    totalCarbonDioxideEmissionAvoided: numeral(groupAccumulatedEconomyReport.totalCarbonDioxideEmissionAvoided).format(
      '0,0',
    ),
    totalEnergyConsumption: `${numeral(groupAccumulatedEconomyReport.totalEnergyConsumption || 0).format(
      '0,0.00',
    )} MWh`,
    totalEnergyCost: formatCurrency(groupAccumulatedEconomyReport.totalEnergyCost),
    totalSavings: formatCurrency(groupAccumulatedEconomyReport.totalSavings),
    totalSavingsPercentage: numeral(groupAccumulatedEconomyReport.totalSavingsPercentage / 100).format('% 0'),
    totalTreesSaved: numeral(groupAccumulatedEconomyReport.totalTreesSaved ?? 0).format('0,0'),
    totalSavingsPercentageTagType: groupAccumulatedEconomyReport.totalSavings > 0 ? 'default' : 'downwarding',
    type: groupAccumulatedEconomyReport.type,
    unitsAccumulatedSummaryEconomyReports: formatUnitsAccumulatedSummaryEconomyReportsFromGroup(
      groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports ?? [],
    ),
    summaryEconomyReportsHistory: formatSummaryEconomyReportsHistory(
      groupAccumulatedEconomyReport.summaryEconomyReportsHistory ?? [],
    ),
    createdAt: groupAccumulatedEconomyReport.createdAt,
    updatedAt: groupAccumulatedEconomyReport.updatedAt,
  };
};

export const parseSustainabilityReportCharts = (
  summaryEconomyReportsHistory: SummaryEconomyReportsHistory[],
): ISustainabilityHistoryCharts => {
  const verifyHistoryValidity = (historyObject: IHistoryChartDataset) =>
    historyObject.values.filter((value) => value !== null).length > 0 ? historyObject : null;

  const summaryReportsSortedByReferencePeriod = summaryEconomyReportsHistory.sort(
    (current, next) => moment(current.date).unix() - moment(next.date).unix(),
  );

  const historyMonthsLabels = summaryReportsSortedByReferencePeriod.map((report) =>
    moment(report.date, 'YYYY-MM-DD').format('MM/YY'),
  );

  const CO2AvoidedHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: summaryReportsSortedByReferencePeriod.map((report) => report.monthlyCarbonDioxideEmissionAvoided ?? 0),
  };

  const treesSavedHistory: IHistoryChartDataset = {
    labels: historyMonthsLabels,
    values: summaryReportsSortedByReferencePeriod.map((report) => report.monthlyTreesSaved ?? 0),
  };

  return {
    CO2AvoidedHistory: verifyHistoryValidity(CO2AvoidedHistory),
    treesSavedHistory: verifyHistoryValidity(treesSavedHistory),
  };
};
