import { useContextSelector } from 'use-context-selector';
import { UnitEconomyContext, UnitEconomyContextType } from '@contexts/units-economy';

export const useUnitsEconomy = (): UnitEconomyContextType => {
  const setGroupId = useContextSelector(UnitEconomyContext, (unitEconomy) => unitEconomy.setGroupId);
  const setUnitId = useContextSelector(UnitEconomyContext, (unitEconomy) => unitEconomy.setUnitId);
  const unitsEconomyReports = useContextSelector(UnitEconomyContext, (unitEconomy) => unitEconomy.unitsEconomyReports);
  const unitAccumulatedEconomyReport = useContextSelector(
    UnitEconomyContext,
    (unitEconomy) => unitEconomy.unitAccumulatedEconomyReport,
  );
  const rawHistoryUnitEconomyReport = useContextSelector(
    UnitEconomyContext,
    (unitEconomy) => unitEconomy.rawHistoryUnitEconomyReport,
  );
  const rawSummaryEconomyReportsHistory = useContextSelector(
    UnitEconomyContext,
    (unitEconomy) => unitEconomy.rawSummaryEconomyReportsHistory,
  );
  const loading = useContextSelector(UnitEconomyContext, (unitEconomy) => unitEconomy.loading);

  return {
    setGroupId,
    setUnitId,
    unitsEconomyReports,
    unitAccumulatedEconomyReport,
    rawHistoryUnitEconomyReport,
    rawSummaryEconomyReportsHistory,
    loading,
  };
};
