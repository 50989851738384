import React from 'react';

import {
  KickOffQuizFirstQuestion,
  KickOffQuizFourthQuestion,
  KickOffQuizSecondQuestion,
  KickOffQuizThirdQuestion,
} from '@components/atoms/kick-off/kick-off-quiz-questions';
import { TabProps } from '@components/molecules/tabs/tab';

export const KickOffQuestionsTabList: Array<TabProps> = [
  {
    label: 'Pergunta 1',
    circleContent: '01',
  },
  {
    label: 'Pergunta 2',
    circleContent: '02',
  },
  {
    label: 'Pergunta 3',
    circleContent: '03',
  },
  {
    label: 'Pergunta 4',
    circleContent: '04',
  },
];

export const KickOffQuestionsContentList = (handleSaveQuizWhenChanged: () => void): Array<React.ReactNode> => [
  <KickOffQuizFirstQuestion key={1} handleSaveQuizWhenChanged={handleSaveQuizWhenChanged} />,
  <KickOffQuizSecondQuestion key={2} handleSaveQuizWhenChanged={handleSaveQuizWhenChanged} />,
  <KickOffQuizThirdQuestion key={3} handleSaveQuizWhenChanged={handleSaveQuizWhenChanged} />,
  <KickOffQuizFourthQuestion key={4} handleSaveQuizWhenChanged={handleSaveQuizWhenChanged} />,
];
