import React from 'react';

import { Tab, TabProps } from '@components/molecules/tabs/tab';
import { Button } from '@clarke-energia/foton';

interface TabContainerProps {
  tabList: Array<TabProps>;
  contentList: React.ReactNode[];
  incrementalCompletion?: boolean;
  onNavigateBackInInitialTab: () => void;
  onNavigateForwardInFinalTab: () => void;
}

export const TabContainer: React.FC<TabContainerProps> = ({
  tabList,
  contentList,
  incrementalCompletion,
  onNavigateBackInInitialTab,
  onNavigateForwardInFinalTab,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleNavigateBackButtonClick = () => {
    if (activeTab === 0) {
      onNavigateBackInInitialTab();
    } else setActiveTab(activeTab - 1);
  };
  const handleNavigateForwardButtonClick = () => {
    if (activeTab + 1 < tabList.length) {
      setActiveTab(activeTab + 1);
    } else {
      onNavigateForwardInFinalTab();
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full h-fit">
        {tabList.map((tab, index) => (
          <Tab
            onClick={() => setActiveTab(index)}
            key={index}
            label={tab.label}
            active={activeTab === index}
            circleContent={tab.circleContent}
            completed={incrementalCompletion ? activeTab > index : tab.completed}
          />
        ))}
      </div>
      <div className="w-full">{contentList[activeTab]}</div>
      <div className="flex gap-4 justify-start items-center w-full">
        <Button label={'Voltar'} kind={'secondary'} onClick={handleNavigateBackButtonClick} type="button" />
        <Button label={'Próxima'} kind={'primary'} onClick={handleNavigateForwardButtonClick} type="button" />
      </div>
    </div>
  );
};
