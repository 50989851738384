import React from 'react';
import { GeneralGadget } from '@clarke-energia/foton';

import PageSectionGenerator from '@components/molecules/general/page-section-generator';
import {
  ISustainabilityChartInformation,
  ISustainabilityHistoryChartTitle,
  ISustainabilityHistoryCharts,
} from '@contexts/group-economy/types';
import BarChartComponent from '@components/molecules/charts/bar-chart';

export interface SustainabilityBarChartsSectionProps {
  title: string;
  summaryEconomyHistoryCharts: ISustainabilityHistoryCharts;
  sustainabilityChartsInformation: Record<ISustainabilityHistoryChartTitle, ISustainabilityChartInformation>;
}

const SustainabilityBarChartsSection: React.FC<SustainabilityBarChartsSectionProps> = ({
  title,
  summaryEconomyHistoryCharts,
  sustainabilityChartsInformation,
}) => {
  return (
    <PageSectionGenerator title={title}>
      <div className={`flex flex-col gap-6 `}>
        <div className={'grid grid-cols-1 gap-6 w-full lg:grid-cols-2 xl:grid-cols-2'}>
          {Object.keys(sustainabilityChartsInformation).map((chartKey, index) => {
            const chartInfo = sustainabilityChartsInformation[chartKey as ISustainabilityHistoryChartTitle];
            const chartData = summaryEconomyHistoryCharts[chartKey as ISustainabilityHistoryChartTitle];
            if (chartData) {
              return (
                <GeneralGadget
                  key={index}
                  title={chartInfo.title}
                  tooltipContent={chartInfo.tooltipContent}
                  className={index === 2 ? 'col-span-full xl:col-span-1' : 'col-span-1'}
                >
                  <BarChartComponent
                    chartLabels={chartData.labels}
                    chartValues={chartData.values as number[]}
                    barHighlight={chartInfo.highlightChart}
                    currencyData={chartData.currencyData}
                    suffix={chartData.suffix}
                    className="w-full"
                  />
                </GeneralGadget>
              );
            }
          })}
        </div>
      </div>
    </PageSectionGenerator>
  );
};

export default SustainabilityBarChartsSection;
