import numeral from 'numeral';
import { formatDate } from '@utils/dayjs';
import { GuaranteeTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';
import { BidWithSavings, Proposal, Unit } from '@contexts/compilation-results/types';
import { triggerAmplitudeButtonClickedEvent } from '@services/event-tracking/events-trigger';

const sortBidsByEconomyValue = (firstBid: BidWithSavings, secondBid: BidWithSavings) => {
  const aPriceValue =
    firstBid.contractType === 'FIXED_PRICE'
      ? firstBid.savings.fixedPrice.groupReport.totalCurrencyAmount
      : firstBid.savings.guaranteedSaving.groupReport.totalCurrencyAmount;
  const bPriceValue =
    secondBid.contractType === 'FIXED_PRICE'
      ? secondBid.savings.fixedPrice.groupReport.totalCurrencyAmount
      : secondBid.savings.guaranteedSaving.groupReport.totalCurrencyAmount;
  return bPriceValue - aPriceValue;
};

const filterBidsByScope = (bids: BidWithSavings[], isInsideScope: boolean) => {
  return bids.filter((bid) => bid.savings.status === 'COMPLETED' && bid.savings.isInsideScope === isInsideScope);
};

const formatBidValues = (bid: BidWithSavings, proposalUnit: Unit | undefined, unitId: string | undefined) => {
  const isGroupedResultView = proposalUnit === undefined;
  const unitName = !isGroupedResultView ? proposalUnit.name : '';

  const result = bid.contractType === 'FIXED_PRICE' ? bid.savings.fixedPrice : bid.savings.guaranteedSaving;

  const totalCurrencyAmount =
    unitId === undefined
      ? numeral(result.groupReport.totalCurrencyAmount).format('$ 0,0.00')
      : numeral(result.unitsReports[unitName]?.totalCurrencyAmount).format('$ 0,0.00');

  const totalPercentageAmount =
    unitId === undefined
      ? numeral(result.groupReport.totalPercentageAmount).format('0.00%')
      : numeral(result.unitsReports[unitName]?.totalPercentageAmount).format('0.00%');

  const totalEconomy = `${totalCurrencyAmount} (${totalPercentageAmount})`;
  const traderName = bid.trader.name;
  const traderLogo = bid.trader.logoUri;
  const traderScore = bid.trader.score;
  const npv = result.groupReport.npv ? numeral(result.groupReport.npv).format('$ 0,0.00') : '-';
  const flexibility = `-${bid.lowerFlexibility}%/+${bid.upperFlexibility}%`;
  const guaranteeType = GuaranteeTypeEnum[bid.guaranteeType as keyof typeof GuaranteeTypeEnum];
  const traderType = bid.retailService ? 'Varejista' : 'Atacadista';
  const proposalType = ProposalTypeEnum[bid.proposalType as keyof typeof ProposalTypeEnum];

  const deadline = formatDate(bid.deadline, 'DD/MM/YYYY HH:mm');
  const periods = bid.periods.map((year) => `${year} anos`).join(', ');
  const cceeCoverTaxes = bid.coverCceeTaxes ? 'Com cobertura' : 'Não há cobertura';

  const reportForDisplay = isGroupedResultView ? result.groupReport : result.unitsReports[unitName];
  return {
    totalEconomy,
    traderName,
    traderLogo,
    traderScore,
    npv,
    flexibility,
    guaranteeType,
    traderType,
    proposalType,
    deadline,
    periods,
    totalPercentageAmount,
    totalCurrencyAmount,
    reportForDisplay,
    cceeCoverTaxes,
  };
};

export const parsedBidsData = (
  bids: BidWithSavings[],
  proposal: Proposal,
  unitId: string | undefined,
  isInsideScope: boolean,
) => {
  const proposalUnit = proposal.units.find((unit) => unit.id === unitId);

  const sortedBids = bids.sort(sortBidsByEconomyValue);
  const filteredBids = filterBidsByScope(sortedBids, isInsideScope);

  return filteredBids.map((bid) => formatBidValues(bid, proposalUnit, unitId));
};

export const triggerButtonEvent = (buttonText: string, destinationPath?: string) => {
  return triggerAmplitudeButtonClickedEvent('Cotação de energia', buttonText, destinationPath);
};
