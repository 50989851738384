import React from 'react';
import { Button } from '@clarke-energia/foton';

import { CLARKE_WHATSAPP_FOR_KICK_OFF } from '@utils/constants/common';

const KickOffDiagnosisFooter = () => {
  const isOnMobileScreen = window.innerWidth <= 768;

  return (
    <div>
      <p className="mt-6 mb-8 font-medium text-paragraph-small md:text-paragraph-medium">
        <span className="block font-bold">Abraços e conte com a gente,</span> Equipe Clarke Energia
      </p>
      <div className="mb-8 w-full">
        <Button
          kind="secondary"
          label="Falar com a Clarke"
          onClick={() => (window.location.href = CLARKE_WHATSAPP_FOR_KICK_OFF)}
          size={isOnMobileScreen ? 'small' : 'medium'}
        />
      </div>
    </div>
  );
};

export default KickOffDiagnosisFooter;
