import { GeneralGadget, IconCard } from '@clarke-energia/foton';
import BarChartComponent from '@components/molecules/charts/bar-chart';
import PageSectionGenerator from '@components/molecules/general/page-section-generator';
import { ConsumptionIconCardStaticProps } from '@contexts/consumption';
import { useConsumption } from '@hooks/use-consumption';
import { useUserInfo } from '@hooks/use-user-info';
import {
  CLARKE_CUSTOMER_GROUP_ID,
  consumptionTootTipBarChart,
  invoicePreviewTootTipCard,
  projectionTootTipCard,
} from '@pages/consumption/consumption-static-data';
import {
  ConsumptionHandlerHelper,
  ProjectionHandlerHelper,
  getActualMonth,
  getMonthYear,
} from '@pages/consumption/helper';
import { formatAsKWh, formatCurrency } from '@utils/text';
import React, { useMemo } from 'react';
import { IConsumptionFirstPageSection } from './types';

export const ConsumptionFirstPageSection: React.FC<IConsumptionFirstPageSection> = ({
  barChartLabels,
  barChartValues,
  barChartSecondValues,
  barChartSecondLabels,
  controlDocsLength,
}) => {
  const {
    monthlyControlMeasurementByUnitId,
    monthlyGroupControlMeasurement,
    consumptionProjectionByUnit,
    consumptionProjectionByGroup,
    unitId,
    invoicePreviewOfGroup,
    invoicePreviewOfUnit,
    pastInvoiceGroup,
    pastInvoiceUnit,
    monthYear,
  } = useConsumption();
  const { user } = useUserInfo();

  const consumptionProjection = unitId ? consumptionProjectionByUnit : consumptionProjectionByGroup;
  const invoicePreview = unitId ? invoicePreviewOfUnit : invoicePreviewOfGroup;
  const pastInvoice = unitId ? pastInvoiceUnit : pastInvoiceGroup;

  const getTotalConsumptionIconCardProps = (): ConsumptionIconCardStaticProps[] => {
    return [
      {
        icon: 'LightBulbIcon',
        title: 'Total do mês',
        label: formatAsKWh(ConsumptionHandler?.total),
        iconColor: 'primary',
      },
    ];
  };

  const getAverageConsumptionIconCardProps = (): ConsumptionIconCardStaticProps[] => {
    return [
      {
        icon: 'BoltIcon',
        title: 'Média de consumo diário',
        label: formatAsKWh(ConsumptionHandler?.average),
        iconColor: 'primary',
      },
    ];
  };

  const getProjectionIconCardProps = (): ConsumptionIconCardStaticProps[] => {
    return [
      {
        icon: 'ChartBarSquareIcon',
        title: 'Estimativa de consumo',
        label: formatAsKWh(ProjectionHandler),
        iconColor: 'neutral',
        tooltip: projectionTootTipCard,
      },
    ];
  };

  const getInvoicePreviewIconCardProps = (): ConsumptionIconCardStaticProps[] => {
    return [
      {
        icon: 'CalculatorIcon',
        title: 'Previsão de fatura',
        label: invoicePreview ? formatCurrency(invoicePreview) : '-',
        iconColor: 'neutral',
        tooltip: invoicePreviewTootTipCard,
      },
    ];
  };

  const getPastInvoiceIconCardProps = (): ConsumptionIconCardStaticProps[] => {
    return [
      {
        icon: 'CalculatorIcon',
        title: 'Fatura do mês',
        label: pastInvoice ? formatCurrency(pastInvoice) : '-',
        iconColor: 'primary',
      },
    ];
  };

  const getCardsProps = () => {
    const consumptionCards = getTotalConsumptionIconCardProps().concat(getAverageConsumptionIconCardProps());
    const consumptionAndProjectionCards = getTotalConsumptionIconCardProps().concat(getProjectionIconCardProps());

    if (user.group?.id === CLARKE_CUSTOMER_GROUP_ID) {
      return consumptionAndProjectionCards.concat(getInvoicePreviewIconCardProps());
    }

    if (monthYear !== getMonthYear(getActualMonth(user.group?.id))) {
      return pastInvoice ? consumptionCards.concat(getPastInvoiceIconCardProps()) : consumptionCards;
    } else {
      return consumptionProjection && invoicePreview
        ? consumptionAndProjectionCards.concat(getInvoicePreviewIconCardProps())
        : consumptionProjection
        ? consumptionAndProjectionCards
        : getTotalConsumptionIconCardProps();
    }
  };

  const ConsumptionHandler = useMemo(() => {
    if (unitId && monthlyControlMeasurementByUnitId) {
      return ConsumptionHandlerHelper(monthlyControlMeasurementByUnitId);
    } else if (!unitId && monthlyGroupControlMeasurement) {
      return ConsumptionHandlerHelper(monthlyGroupControlMeasurement);
    }
  }, [monthlyControlMeasurementByUnitId, monthlyGroupControlMeasurement]);

  const ProjectionHandler = useMemo(() => {
    const totalMonthlyConsumption = ConsumptionHandler?.total;
    if (unitId && consumptionProjectionByUnit) {
      return ProjectionHandlerHelper(consumptionProjectionByUnit, controlDocsLength, totalMonthlyConsumption);
    } else if (!unitId && consumptionProjectionByGroup) {
      return ProjectionHandlerHelper(consumptionProjectionByGroup, controlDocsLength, totalMonthlyConsumption);
    }
  }, [consumptionProjectionByUnit, consumptionProjectionByGroup, unitId, ConsumptionHandler, controlDocsLength]);

  return (
    <PageSectionGenerator title="">
      <>
        <div
          className={`grid grid-cols-1 gap-6 w-full ${
            (consumptionProjection && invoicePreview) ||
            (monthYear !== getMonthYear(getActualMonth(user.group?.id)) && pastInvoice) ||
            user.group?.id === CLARKE_CUSTOMER_GROUP_ID
              ? 'xl:grid-cols-3'
              : 'xl:grid-cols-2'
          }`}
        >
          {getCardsProps().map((item, index) => (
            <IconCard
              className="col-span-1"
              key={`iconCard-${index}`}
              title={item.title}
              icon={item.icon}
              label={item.label}
              iconColor={item.iconColor}
              tooltip={item?.tooltip ?? undefined}
            />
          ))}
        </div>
        <GeneralGadget
          title="Gráfico dia x consumo em kWh"
          tooltipContent={consumptionTootTipBarChart.content}
          className={'col-span-full xl:col-span-1'}
          margin="m-0"
        >
          <BarChartComponent
            chartLabels={barChartLabels as string[]}
            chartSecondLabels={barChartSecondLabels as string[]}
            chartValues={barChartValues as number[]}
            chartSecondValues={barChartSecondValues as number[]}
            barHighlight={true}
            className="w-full h-full"
            stepSizeValue={4}
            legend={consumptionProjection ? true : false}
            legendLabel="Consumo Realizado"
            legendSecondLabel={consumptionProjection ? 'Consumo Projetado' : undefined}
            suffix="kWh"
          />
        </GeneralGadget>
      </>
    </PageSectionGenerator>
  );
};
