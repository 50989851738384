import React from 'react';
import numeral from 'numeral';

import { parseAverageConsumptionValue } from '../parser';

import {
  IKickOffDiagnosis,
  KickOffProductType,
  KickOffTraderType,
  KickOffWarrantyType,
} from '@contexts/kick-off/types';

interface IKickOffDiagnosisSuggestedProductTableItem {
  label: string;
  value?: string;
  clearerBackground?: boolean;
}

const KickOffDiagnosisSuggestedProductTableItem = ({
  label,
  value,
  clearerBackground,
}: IKickOffDiagnosisSuggestedProductTableItem) => (
  <div
    className={`flex justify-between px-4 py-1 text-paragraph-small md:text-paragraph-medium ${
      clearerBackground ? 'bg-white' : ''
    }`}
  >
    <div className="w-full font-bold text-left">{label}</div>
    <div className="w-full font-normal text-right">{value}</div>
  </div>
);

const KickOffDiagnosisSuggestedProductTable: React.FC<IKickOffDiagnosis> = ({
  suggestedProduct,
}: IKickOffDiagnosis) => {
  const parsedProductTypeValue = () => suggestedProduct && KickOffProductType[suggestedProduct?.productType];
  const parsedTraderTypeValue = () => suggestedProduct && KickOffTraderType[suggestedProduct?.traderType];
  const parsedFlexibilityValue = () => {
    const parsedUpperFlexibility = numeral(Math.abs(suggestedProduct?.upperFlexibility as number)).format('% 0.0');
    const parsedLowerFlexibility = numeral(Math.abs(suggestedProduct?.lowerFlexibility as number)).format('% 0.0');
    return `-${parsedLowerFlexibility}/+${parsedUpperFlexibility}`;
  };
  const parsedContractTermInYearsValue = () => `${suggestedProduct?.contractTermInYears} anos`;
  const parsedWarrantyTypeValue = () => suggestedProduct && KickOffWarrantyType[suggestedProduct?.warrantyType];

  return (
    <div className="flex flex-col gap-3 w-full">
      <KickOffDiagnosisSuggestedProductTableItem label="Tipo de contrato" value={parsedProductTypeValue()} />
      <KickOffDiagnosisSuggestedProductTableItem
        label="Modalidade de contratação"
        value={parsedTraderTypeValue()}
        clearerBackground
      />
      <KickOffDiagnosisSuggestedProductTableItem
        label="Consumo médio mensal"
        value={parseAverageConsumptionValue(suggestedProduct?.averageConsumptionInMwm)}
      />
      <KickOffDiagnosisSuggestedProductTableItem
        label="Flexibilidade"
        value={parsedFlexibilityValue()}
        clearerBackground
      />
      <KickOffDiagnosisSuggestedProductTableItem label="Duração do contrato" value={parsedContractTermInYearsValue()} />
      <KickOffDiagnosisSuggestedProductTableItem
        label="Tipo de garantia"
        value={parsedWarrantyTypeValue()}
        clearerBackground
      />
    </div>
  );
};

export default KickOffDiagnosisSuggestedProductTable;
