import React from 'react';
import { createContext } from 'use-context-selector';
import { useQuery } from '@apollo/client';

import { useUserInfo } from '@hooks/use-user-info';
import { GET_PROCESS_BY_PROPOSAL_ID, GET_PROPOSAL_WITH_SAVINGS_COMPILED_BY_GROUP_ID_QUERY } from './queries';
import { findProposalWithSavings, formatBids, formatProposal } from './parser';
import { BidSavingsGraphQLResponse, BidWithSavings, CompiledResultsContextType, Proposal } from './types';

export const CompilationResultContext = createContext({} as CompiledResultsContextType);

interface Provider {
  children: React.ReactNode;
}

const CompilationResultProvider: React.FC<Provider> = ({ children }: Provider) => {
  const {
    user: { group },
  } = useUserInfo();

  const [savings, setSavings] = React.useState<BidWithSavings[]>([]);
  const [processName, setProcessName] = React.useState<string | null>(null);
  const [proposalId, setProposalId] = React.useState<string>('');
  const [proposal, setProposal] = React.useState<Proposal>({
    id: '',
    round: NaN,
    units: [],
    startDate: '',
    lowerFlexibility: NaN,
    upperFlexibility: NaN,
    guaranteeType: 'NO_GUARANTEE',
    periods: [],
    commissionModality: 'RETAILER',
  });

  const { loading: loadingProposal } = useQuery(GET_PROPOSAL_WITH_SAVINGS_COMPILED_BY_GROUP_ID_QUERY, {
    variables: { groupId: group?.id },
    onCompleted: (data) => {
      const rawProposalsOrderByDate = data.group.proposals.slice().reverse();
      const rawProposal = findProposalWithSavings(rawProposalsOrderByDate);

      if (rawProposal) {
        setProposalId(rawProposal.id);

        const proposalType = rawProposal.proposalType;
        const bids: BidSavingsGraphQLResponse['proposal']['bids'] = rawProposal.bids;

        const proposalResult = formatProposal(rawProposal);
        const bidsResult = formatBids(bids, proposalType);

        setProposal(proposalResult);
        setSavings(bidsResult);
        return;
      }

      setSavings([]);
    },
    onError: () => {
      setSavings([]);
      setProposalId('');
    },
  });

  const { loading: loadindgProcessName } = useQuery(GET_PROCESS_BY_PROPOSAL_ID, {
    variables: { proposalId },
    skip: !proposalId,
    onCompleted: (data) => {
      const rawProcess = data.getProcessByProposalId.process;
      setProcessName(rawProcess.name);
    },
    onError: () => {
      setProcessName(null);
    },
  });

  return (
    <CompilationResultContext.Provider
      value={{
        loading: loadingProposal || loadindgProcessName,
        savings,
        proposal,
        processName,
      }}
    >
      {children}
    </CompilationResultContext.Provider>
  );
};

export default CompilationResultProvider;
