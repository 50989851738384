import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { createContext } from 'use-context-selector';
import groupBy from 'lodash.groupby';

import { SummaryEconomyReportsHistory } from '@contexts/group-economy/types';
import { formatMonthAndYearDate } from '@utils/text';

import { AccumulatedEconomyReportManager } from './manager';
import { formatUnitsEconomyReports, parserUnitAccumulatedEconomyReport } from './parser';
import {
  QUERY_GET_UNITS_ECONOMY_REPORTS_BY_GROUP_ID,
  QUERY_GET_ACCUMULATED_ECONOMY_REPORTS_BY_UNIT_ID,
} from './queries';
import {
  UnitUnitAccumulatedEconomyReportByUnitIdGraphQLData,
  UnitUnitAccumulatedEconomyReportByUnitIdQueryVariables,
  UnitEconomyReportDataResponse,
  UnitReportParsed,
  UnitAccumulatedEconomyReportByUnitIdParsed,
} from './types';

export interface UnitEconomyContextType {
  setGroupId: React.Dispatch<React.SetStateAction<string>>;
  setUnitId: React.Dispatch<React.SetStateAction<string>>;
  unitsEconomyReports: Record<string, UnitReportParsed[]>;
  unitAccumulatedEconomyReport: UnitAccumulatedEconomyReportByUnitIdParsed;
  rawHistoryUnitEconomyReport: UnitEconomyReportDataResponse[];
  rawSummaryEconomyReportsHistory: SummaryEconomyReportsHistory[] | undefined;
  loading: boolean;
}

export const UnitEconomyContext = createContext<UnitEconomyContextType>({} as UnitEconomyContextType);

export const UnitEconomyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [groupId, setGroupId] = React.useState<string>('');
  const [unitId, setUnitId] = React.useState<string>('');
  const [unitsEconomyReports, setUnitsEconomyReports] = React.useState<Record<string, UnitReportParsed[]>>(
    {} as Record<string, UnitReportParsed[]>,
  );
  const [unitAccumulatedEconomyReport, setUnitAccumulatedEconomyReport] =
    useState<UnitAccumulatedEconomyReportByUnitIdParsed>({} as UnitAccumulatedEconomyReportByUnitIdParsed);
  const [rawHistoryUnitEconomyReport, setRawHistoryUnitEconomyReport] = React.useState<UnitEconomyReportDataResponse[]>(
    [] as UnitEconomyReportDataResponse[],
  );
  const [rawSummaryEconomyReportsHistory, setRawSummaryEconomyReportsHistory] =
    React.useState<SummaryEconomyReportsHistory[]>();

  const [GetUnitsEconomyReportsByGroupId, { loading: isFetchingUnitsEconomyReport }] = useLazyQuery(
    QUERY_GET_UNITS_ECONOMY_REPORTS_BY_GROUP_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const filteredPublishedReports = (
          data.unitsEconomyReportsByGroupId as Array<UnitEconomyReportDataResponse>
        ).filter((report) => report.status === 'FINAL');

        const reportsParsed = formatUnitsEconomyReports(filteredPublishedReports);
        setRawHistoryUnitEconomyReport(filteredPublishedReports);
        setUnitsEconomyReports(groupBy(reportsParsed, (report) => formatMonthAndYearDate(report.date)));
      },
      onError: (e) => {
        throw `ERROR: ${e}`;
      },
    },
  );

  const [getAccumulatedEconomyReportByUnitID, { loading: loadingGetAccumulatedEconomyReportByUnitID }] = useLazyQuery<
    UnitUnitAccumulatedEconomyReportByUnitIdGraphQLData,
    UnitUnitAccumulatedEconomyReportByUnitIdQueryVariables
  >(QUERY_GET_ACCUMULATED_ECONOMY_REPORTS_BY_UNIT_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new AccumulatedEconomyReportManager(data);
      setUnitAccumulatedEconomyReport(parserUnitAccumulatedEconomyReport(manager.unitAccumulatedEconomyReport));
      setRawSummaryEconomyReportsHistory(manager.rawSummaryEconomyReportsHistory);
    },
  });

  function getUnitsEconomyReportsHandler(id: string) {
    return GetUnitsEconomyReportsByGroupId({
      variables: {
        id,
      },
    });
  }

  function getAccumulatedEconomyReportByUnitIDHandler() {
    return getAccumulatedEconomyReportByUnitID({
      variables: {
        unitId,
      },
    });
  }

  React.useEffect(() => {
    if (groupId) {
      getUnitsEconomyReportsHandler(groupId);
    }
  }, [groupId]);

  React.useEffect(() => {
    if (unitId) {
      getAccumulatedEconomyReportByUnitIDHandler();
    }
  }, [unitId]);

  return (
    <UnitEconomyContext.Provider
      value={{
        setGroupId,
        setUnitId,
        rawHistoryUnitEconomyReport,
        unitsEconomyReports,
        unitAccumulatedEconomyReport,
        rawSummaryEconomyReportsHistory,
        loading: isFetchingUnitsEconomyReport || loadingGetAccumulatedEconomyReportByUnitID,
      }}
    >
      {children}
    </UnitEconomyContext.Provider>
  );
};
