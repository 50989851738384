import { SummaryEconomyReportsHistory } from '@contexts/group-economy/types';
import { UnitAccumulatedEconomyReportByUnitId, UnitUnitAccumulatedEconomyReportByUnitIdGraphQLData } from './types';

export class AccumulatedEconomyReportManager {
  rawData: UnitUnitAccumulatedEconomyReportByUnitIdGraphQLData;
  unitAccumulatedEconomyReport: UnitAccumulatedEconomyReportByUnitId;
  rawSummaryEconomyReportsHistory: SummaryEconomyReportsHistory[] | undefined;

  constructor(rawData: UnitUnitAccumulatedEconomyReportByUnitIdGraphQLData) {
    this.rawData = rawData;
    this.unitAccumulatedEconomyReport = rawData.getAccumulatedEconomyReportByUnitID;
    this.rawSummaryEconomyReportsHistory = rawData.getAccumulatedEconomyReportByUnitID.summaryEconomyReportsHistory;
  }
}
