import React from 'react';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { Select } from '@clarke-energia/foton';

export interface SelectFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  label?: string;
  placeholder?: string;
  id: string;
  inputOptions: { optionLabel: string; value: any }[];
  options?: RegisterOptions;
  transform?: {
    input?: (value: number | string) => number | string;
    output?: (value: number | string) => number | string;
  };
}

export function SelectFormField<T extends FieldValues>({
  field,
  label,
  id,
  inputOptions,
  placeholder,
  options,
  transform,
}: SelectFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className="flex flex-col pt-4 space-y-1">
          <Select
            id={id}
            name={name}
            label={label ?? ''}
            options={inputOptions.map(({ optionLabel, value }, _) => ({
              display: optionLabel,
              value: value,
            }))}
            value={transform?.input ? transform.input(value) : value}
            placeholder={placeholder || 'Selecione uma opção'}
            required={(options?.required as boolean) || false}
            error={errors[field]?.message as string | undefined}
            onBlur={onBlur}
            onChange={(e) => {
              const newValue = e.target.value;
              onChange(transform?.output ? transform.output(newValue) : newValue);
            }}
          />
        </div>
      )}
    />
  );
}
