import { gql } from '@apollo/client';

export const QUERY_GET_GROUP_ECONOMY_REPORT_BY_GROUP_ID = gql`
  query getGroupEconomyReportByGroupID($id: ID!) {
    getGroupEconomyReportByGroupID(id: $id) {
      id
      group {
        name
      }
      economyReports {
        id
        trader {
          name
        }
        unit {
          id
          name
          edc {
            name
          }
          contractedDemandOffPeak
        }
        energyTotalConsumption
        currentSavings
        energyTotalCost
        status
      }
      totalMonthlySavings
      totalMonthlySavingsPercent
      totalMonthlyEnergyCost
      totalMonthlyEnergyConsumption
      date
      status
    }
  }
`;

export const QUERY_GET_ALL_GROUPS_ECONOMY_REPORT_BY_GROUP_ID = gql`
  query GetGroupReports($id: ID!) {
    getAllGroupsEconomyReportByGroupID(id: $id) {
      id
      economyReports {
        id
        unit {
          id
          name
        }
        date
        currentSavings
        savingsPercent
        energyTotalCost
        energyTotalConsumption
        energyConsumptionBalancePercent
        energyCostBalancePercent
        savingBalancePercent
        createdAt
        updatedAt
        status
      }
      totalMonthlySavings
      totalMonthlySavingsPercent
      totalMonthlyEnergyCost
      totalMonthlyEnergyConsumption
      energyConsumptionBalancePercent
      energyCostBalancePercent
      savingBalancePercent
      totalCarbonDioxideEmissionAvoided
      date
      createdAt
      updatedAt
      group {
        name
        id
      }
      status
    }
  }
`;

export const QUERY_GET_GROUP_ACCUMULATED_ECONOMY_REPORT_BY_GROUP_ID = gql`
  query GetGroupAccumulatedEconomyReportByGroupID($groupId: ID!) {
    getGroupAccumulatedEconomyReportByGroupID(groupId: $groupId) {
      id
      group {
        id
        name
      }
      type
      createdAt
      totalSavings
      totalSavingsPercentage
      totalEnergyCost
      totalCarbonDioxideEmissionAvoided
      totalEnergyConsumption
      totalTreesSaved
      summaryEconomyReportsHistory {
        id
        monthlySavings
        monthlyEnergyCost
        monthlyCarbonDioxideEmissionAvoided
        monthlyEnergyConsumption
        monthlyTreesSaved
        date
      }
      unitsAccumulatedSummaryEconomyReports {
        unit {
          id
          name
        }
        accumulatedUnitsSavings
        accumulatedUnitsEnergyCost
        accumulatedUnitsCarbonDioxideEmissionAvoided
        accumulatedUnitsEnergyConsumption
        accumulatedUnitsSavingsPercentage
        accumulatedTreesSaved
      }
    }
  }
`;

export const QUERY_GET_ALL_GROUPS = gql`
  query GetAllGroups {
    groups {
      data {
        id
        name
      }
    }
  }
`;
