import { transformDateToMonthYear } from '@pages/consumption/helper';
import { GetEconomyReportByGroupAndDateGraphQLData } from './types';

export const settingGroupAndUnitInvoice = (
  rawData: GetEconomyReportByGroupAndDateGraphQLData,
  unitId: string | undefined,
  monthYear: string | undefined,
) => {
  let groupTotalValue = 0;
  let unitTotalValue = 0;
  rawData.unitsEconomyReportsByGroupIdAndDate.forEach((unit) => {
    const reportMonthYear = transformDateToMonthYear(unit.date);
    if (monthYear === reportMonthYear) {
      if (unit.unit.id === unitId) unitTotalValue = unit.freeMarket.totalValue;
      groupTotalValue += unit.freeMarket.totalValue;
    } else {
      groupTotalValue += 0;
    }
  });

  return { groupTotalValue, unitTotalValue };
};
