import React, { useState, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconCard, IconCardProps, Layout, PopUpList, PopUpListProps, SlideshowGadget } from '@clarke-energia/foton';
import domtoimage from 'dom-to-image';

import {
  GroupReportParsed,
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
} from '@contexts/group-economy/types';
import PageSectionGenerator from '@components/molecules/general/page-section-generator';
import { UnitEconomyReportDataResponse, UnitReportParsed } from '@contexts/units-economy/types';
import { conventionalMarketTablesGenerator, freeMarketTablesGenerator } from '@contexts/units-economy/parser';
import { formatGadgetContent } from '@contexts/group-economy/parser';
import { useUserInfo } from '@hooks/use-user-info';
import { useUnitsEconomy } from '@hooks/use-units-economy';
import { useGroupEconomy } from '@hooks/use-group-economy';

import { UnitEconomyReportSkeleton } from '@components/molecules/skeleton/unit-economy-report-skeleton';

import { ACCUMULATED_ECONOMY_REPORTS_PATH, HOME_PATH, MONTHLY_ECONOMY_REPORTS_PATH } from '@routers/constants';
import { GENERAL_PANEL_PARAMS } from '@utils/constants/common';

import { IActionsButtons, ReportGadgetGenerator, triggerButtonEvent } from './helpers';
import './style.css';

const MonthlyEconomyReportPage: React.FC = () => {
  const navigate = useNavigate();
  const { id: unitId, period } = useParams();
  const { user, isFetchingUser } = useUserInfo();
  const {
    groupAccumulatedEconomyReport,
    setGroupId: setGroupEconomyGroupId,
    groupEconomyReports,
    loading: groupEconomyLoading,
  } = useGroupEconomy();
  const {
    setGroupId,
    rawHistoryUnitEconomyReport,
    unitsEconomyReports,
    loading,
    setUnitId,
    unitAccumulatedEconomyReport,
  } = useUnitsEconomy();

  const [unitEconomyReport, setUnitEconomyReport] = useState<UnitReportParsed>();
  const [groupEconomyParsed, setGroupEconomyParsed] = useState<GroupReportParsed>();
  const [rawUnitEconomyReport, setRawUnitEconomyReport] = useState<UnitEconomyReportDataResponse>();

  const [freeMarketTables, setFreeMarketTables] =
    useState<Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>>();
  const [conventionalMarketTables, setConventionalMarketTables] =
    useState<Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>>();

  const [openPeriodSelector, setOpenPeriodSelector] = useState<boolean>(false);
  const [reportPeriodLabel, setReportPeriodLabel] = useState<string>('');
  const [reportsPeriods, setReportsPeriods] = useState<PopUpListProps['items']>();

  const [openReportByUnitOrGeneralSelector, setOpenReportByUnitOrGeneralSelector] = useState<boolean>(false);
  const [reportsByUnitOrGeneral, setReportsByUnitOrGeneral] = useState<PopUpListProps['items']>();
  const exportRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const generateHeaderButtonsProps = (): IActionsButtons[] => {
    return [
      {
        buttonType: 'buttonInline',
        className: 'px-4 border-neutral-30',
        kind: 'secondary',
        label: 'Histórico Completo',
        size: 'small',
        onClick: () => {
          const accumulatedEconomyReportGeneralPath = `${ACCUMULATED_ECONOMY_REPORTS_PATH}/${GENERAL_PANEL_PARAMS}`;
          window.open(`${window.location.origin}${accumulatedEconomyReportGeneralPath}`, '_blank');
          triggerButtonEvent('Histórico Completo', accumulatedEconomyReportGeneralPath);
        },
      },
      {
        buttonType: 'buttonInline',
        className: 'px-4 border-neutral-30',
        kind: 'primary',
        label: reportPeriodLabel || '',
        icon: openPeriodSelector ? 'ChevronUpIcon' : 'ChevronDownIcon',
        iconPosition: 'right',
        size: 'small',
        onClick: () => {
          setOpenPeriodSelector(!openPeriodSelector);
        },
      },
      {
        buttonType: 'buttonInline',
        className: 'px-4 border-neutral-30',
        kind: 'secondary',
        label: 'Exportar',
        icon: 'InboxIcon',
        iconPosition: 'left',
        size: 'small',
        disabled: false,
        onClick: () => {
          triggerButtonEvent('Exportar');
          domtoimage
            .toPng(exportRef.current)
            .then((dataUrl: string) => {
              const link = document.createElement('a');
              link.href = dataUrl;
              link.download = `relatorio-mensal-${unitEconomyReport ? unitEconomyReport.unitName : 'geral'}.png`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch((error: string) => {
              // eslint-disable-next-line no-console
              console.error('Error capturing component as image:', error);
            });
        },
      },
    ];
  };

  const generateReportByUnitOrGroupPopupList = (unitsReports: UnitReportParsed[]) => {
    if (unitsReports && unitsReports.length > 1) {
      const reportsByUnitOrGeneral = unitsReports.map((report) => ({
        label: report.unitName,
        onClick: () => {
          const monthlyEconomyReportUnitPath = `${MONTHLY_ECONOMY_REPORTS_PATH}/${report.unitId}/${period}`;
          setOpenReportByUnitOrGeneralSelector(false);
          setUnitId(report.unitId);
          navigate(monthlyEconomyReportUnitPath);
          triggerButtonEvent(report.unitName, monthlyEconomyReportUnitPath);
        },
      }));

      const generatePopupListIfGroupsHasMoreThanOneUnit = () => {
        if (
          groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports &&
          groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports.length > 0
        ) {
          return [
            {
              label: 'Geral',
              onClick: () => {
                const monthlyEconomyReportGeneralPath = `${MONTHLY_ECONOMY_REPORTS_PATH}/${GENERAL_PANEL_PARAMS}/${period}`;
                setOpenReportByUnitOrGeneralSelector(false);
                setUnitEconomyReport(undefined);
                navigate(monthlyEconomyReportGeneralPath);
                triggerButtonEvent('Geral', monthlyEconomyReportGeneralPath);
              },
            },
            ...reportsByUnitOrGeneral,
          ];
        }
      };

      setReportsByUnitOrGeneral(generatePopupListIfGroupsHasMoreThanOneUnit());
    }
  };

  const getMonthlyEconomyReportIconCardProps = (): IconCardProps[] => {
    return [
      {
        icon: 'CurrencyDollarIcon',
        title: 'Economia',
        label: !unitEconomyReport
          ? groupEconomyParsed?.compiledResult.economy.total ?? ''
          : unitEconomyReport?.economy.total ?? '',
        tag: {
          type: !unitEconomyReport
            ? groupEconomyParsed?.compiledResult.economy.typePercentageStyle ?? 'default'
            : unitEconomyReport?.economy.typePercentageStyle ?? 'default',
          value: !unitEconomyReport
            ? groupEconomyParsed?.compiledResult.economy.percentage ?? 'default'
            : unitEconomyReport?.economy.percentage ?? 'default',
        },
      },
      {
        icon: 'CreditCardIcon',
        title: 'Custo',
        label: !unitEconomyReport
          ? groupEconomyParsed?.compiledResult.energyCost.total ?? ''
          : unitEconomyReport?.energyCost.total ?? '',
      },
      {
        icon: 'LightBulbIcon',
        title: 'Consumo',
        label: !unitEconomyReport
          ? groupEconomyParsed?.compiledResult.energyConsumption.total ?? ''
          : unitEconomyReport?.energyConsumption.total ?? '',
      },
    ];
  };

  useMemo(() => {
    generateReportByUnitOrGroupPopupList(unitsEconomyReports[reportPeriodLabel]);
  }, [unitsEconomyReports, reportPeriodLabel]);

  useMemo(() => {
    if (user?.group) {
      setGroupId(user.group.id);
      setGroupEconomyGroupId(user.group.id);
    }
  }, [user]);

  useMemo(() => {
    if (Object.keys(unitsEconomyReports).length && unitId && period) {
      const filteredUnitsEconomyReports =
        unitId === GENERAL_PANEL_PARAMS
          ? unitsEconomyReports
          : Object.fromEntries(
              Object.entries(unitsEconomyReports)
                .map(([month, reports]) => [month, reports.filter((report) => report.unitId === unitId)])
                .filter(([_, reports]) => reports.length > 0),
            );

      const getReportPeriods = (unitsEconomyReports: Record<string, UnitReportParsed[]>) => {
        return Object.keys(unitsEconomyReports)
          .map((period) => {
            const unitMonthlyEconomyReportPath = `${MONTHLY_ECONOMY_REPORTS_PATH}/${unitId}/${period}`;
            return {
              label: period,
              onClick: () => {
                setOpenPeriodSelector(false);
                window.open(unitMonthlyEconomyReportPath, '_blank');
                triggerButtonEvent(period, unitMonthlyEconomyReportPath);
              },
            };
          })
          .reverse();
      };

      setReportsPeriods(getReportPeriods(filteredUnitsEconomyReports));
      setReportPeriodLabel(period);
    }
  }, [unitsEconomyReports, unitId, unitAccumulatedEconomyReport]);

  useMemo(() => {
    if (reportPeriodLabel) {
      const selectedUnitEconomyReport: UnitReportParsed | undefined = unitsEconomyReports[reportPeriodLabel].find(
        (unitReport) => unitReport.unitId === unitId,
      );

      const selectedGroupEconomyReport: GroupReportParsed | undefined = groupEconomyReports.filter(
        (report) => report.date === reportPeriodLabel,
      )[0];

      if (selectedUnitEconomyReport) {
        setUnitEconomyReport(selectedUnitEconomyReport);
        const rawUnitReport = rawHistoryUnitEconomyReport.find(
          (unitReport) => unitReport.id === selectedUnitEconomyReport.reportId,
        );

        if (rawUnitReport) {
          setRawUnitEconomyReport(rawUnitReport);
          setFreeMarketTables(freeMarketTablesGenerator(rawUnitReport) as []);
          setConventionalMarketTables(conventionalMarketTablesGenerator(rawUnitReport) as []);
        }
      }

      if (selectedGroupEconomyReport) {
        setGroupEconomyParsed(selectedGroupEconomyReport);
      }
    }
  }, [reportPeriodLabel, unitId, groupEconomyReports]);

  if (isFetchingUser || loading || groupEconomyLoading)
    return (
      <Layout darkerBackground loading>
        <UnitEconomyReportSkeleton />
      </Layout>
    );

  return (
    <div ref={exportRef}>
      <Layout
        title={`Relatório de economia`}
        navigationHistoryLinks={[
          { label: 'Home', url: HOME_PATH },
          { label: 'Relatório de economia', url: '#' },
        ]}
        headerButtons={user.group?.name.length ? generateHeaderButtonsProps() : []}
        className="relative h-full"
        darkerBackground
      >
        {reportsPeriods && (
          <PopUpList
            items={reportsPeriods}
            show={openPeriodSelector}
            className="absolute mr-8 -mt-8 cursor-pointer sm:right-24 sm:top-32"
          />
        )}
        <div className="grid grid-cols-3 col-span-full gap-8 pb-10">
          <React.Fragment key={unitEconomyReport ? unitEconomyReport.reportId : groupEconomyParsed?.id}>
            <PageSectionGenerator
              title={`Painel ${unitEconomyReport ? unitEconomyReport.unitName : 'Geral'}`}
              buttonIconProps={
                reportsByUnitOrGeneral && {
                  openPopupList: openReportByUnitOrGeneralSelector,
                  setOpenPopupList: () => setOpenReportByUnitOrGeneralSelector(!openReportByUnitOrGeneralSelector),
                }
              }
              popUpListProps={
                reportsByUnitOrGeneral && {
                  items: reportsByUnitOrGeneral,
                  show: openReportByUnitOrGeneralSelector,
                }
              }
            >
              <>
                <div className="grid grid-cols-1 gap-6 w-full lg:grid-cols-2 xl:grid-cols-3">
                  {getMonthlyEconomyReportIconCardProps().map((item, index) => (
                    <IconCard
                      className="col-span-1"
                      key={`iconCard-${index}`}
                      title={item.title}
                      icon={item.icon}
                      label={item.label}
                      tag={item.tag && item.tag}
                    />
                  ))}
                </div>
                {unitEconomyReport && (
                  <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
                    {freeMarketTables && rawUnitEconomyReport && (
                      <ReportGadgetGenerator
                        marketType="ACL"
                        tables={freeMarketTables}
                        unitEconomyReport={rawUnitEconomyReport}
                      />
                    )}
                    {conventionalMarketTables && rawUnitEconomyReport && (
                      <ReportGadgetGenerator
                        marketType="ACR"
                        tables={conventionalMarketTables}
                        unitEconomyReport={rawUnitEconomyReport}
                      />
                    )}
                  </div>
                )}
              </>
            </PageSectionGenerator>
            {!unitEconomyReport && (
              <PageSectionGenerator title="Resultado por unidade" className="mb-10">
                <div className="grid grid-cols-1 gap-6 justify-between w-full lg:grid-cols-2 xl:grid-cols-3">
                  {unitsEconomyReports[reportPeriodLabel] &&
                    unitsEconomyReports[reportPeriodLabel].map(
                      ({ unitName, unitId, economy, energyConsumption, energyCost }) => (
                        <div key={unitName} className="col-span-1 min-w-[230px]">
                          <SlideshowGadget
                            title={unitName}
                            gadgetContent={formatGadgetContent({ economy, energyConsumption, energyCost })}
                            actionButtons={[
                              {
                                buttonType: 'buttonInline',
                                label: 'Ver mais',
                                size: 'xSmall',
                                className: 'px-4 border-neutral-30',
                                onClick: () => {
                                  const monthlyEconomyReportUnitPath = `${MONTHLY_ECONOMY_REPORTS_PATH}/${unitId}/${reportPeriodLabel}`;
                                  window.open(monthlyEconomyReportUnitPath, '_blank');
                                  triggerButtonEvent('Ver mais', monthlyEconomyReportUnitPath);
                                },
                                kind: 'ghost',
                              },
                            ]}
                          />
                        </div>
                      ),
                    )}
                </div>
              </PageSectionGenerator>
            )}
          </React.Fragment>
        </div>
      </Layout>
    </div>
  );
};

export default MonthlyEconomyReportPage;
