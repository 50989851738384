import { TagProps } from '@clarke-energia/foton';
import { TaskStatus } from '@contexts/migration-report/types';

export const TaskStatusTagPropsSelector: Record<keyof typeof TaskStatus, TagProps> = {
  TO_DO: {
    color: 'gray',
    label: 'Não iniciado',
    icon: 'PauseCircleIcon',
    kind: 'icon',
  },
  IN_PROGRESS: {
    color: 'yellow',
    label: 'Em andamento',
    icon: 'ArrowPathIcon',
    kind: 'icon',
  },
  DONE: {
    color: 'green',
    label: 'Concluído',
    icon: 'CheckIcon',
    kind: 'icon',
  },
  OVERDUE: {
    color: 'red',
    label: 'Atrasado',
    icon: 'ExclamationTriangleIcon',
    kind: 'icon',
  },
};
