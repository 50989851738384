import React from 'react';
import moment from 'moment';
import { Button, ButtonIcon, Layout, NotificationCard, Skeleton } from '@clarke-energia/foton';
import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';
import { useNavigate } from 'react-router-dom';

import { INotification } from '@contexts/notifications/types';
import { useNotifications } from '@hooks/use-notifications';

import { HomeSkeleton } from '@components/molecules/skeleton/home-skeleton';

import { NotificationTopicsContents } from './helpers';
import { useAuth } from '@src/ApolloWrapper';

const NotificationsHub: React.FC = () => {
  const navigate = useNavigate();
  const { user, loading: isFetchingUser } = useAuth();
  const {
    notifications,
    loading,
    setUserId,
    setOpenNotificationSlideOver,
    updateBatchNotificationsReadStatusHandler,
    getNotificationsByUserIdHandler,
  } = useNotifications();

  const [readNotifications, setReadNotifications] = React.useState<Array<INotification>>();
  const [unreadNotifications, setUneadNotifications] = React.useState<Array<INotification>>();

  const setNotificationAsReadHandler = (notificationsIdsList: Array<string>) => {
    updateBatchNotificationsReadStatusHandler(notificationsIdsList, true).then(() => {
      getNotificationsByUserIdHandler();
    });
  };

  const NotificationItemGenerator = (notification: INotification) => (
    <NotificationCard
      label={notification.title}
      description={notification.description}
      notificationDate={moment(notification.createdAt).format('DD MMM').toUpperCase()}
      icon={NotificationTopicsContents[notification.topic].iconName}
      iconColor="primary"
      isNewNotification={!notification.read}
      cardButtons={[
        {
          kind: 'primary',
          label: 'Ver',
          icon: 'ArrowRightIcon',
          size: 'small',
          onClick: () => {
            !notification.read && setNotificationAsReadHandler([notification.id]);
            setOpenNotificationSlideOver(false);
            navigate(NotificationTopicsContents[notification.topic].actionButtonUrl);
          },
        },
      ]}
    />
  );

  React.useEffect(() => {
    if (user.id) setUserId(user.id);
  }, [user]);

  React.useEffect(() => {
    if (notifications) {
      setReadNotifications(notifications.filter((notification) => notification.read));
      setUneadNotifications(notifications.filter((notification) => !notification.read));
    }
  }, [notifications]);

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.PAGE_VIEWED, { page_name: 'Notification' });
  }, []);

  if (isFetchingUser)
    return (
      <Layout darkerBackground loading>
        <HomeSkeleton />
      </Layout>
    );

  return (
    <div title="Caixa de Entrada" className="flex flex-col pr-3 pl-5 h-full lg:pr-5 lg:pl-8">
      <ButtonIcon
        icon="ArrowLeftIcon"
        kind="ghost"
        className="hidden mt-6 mb-4 lg:block w-fit"
        onClick={() => setOpenNotificationSlideOver(false)}
      />
      <div className="flex flex-wrap justify-between items-center mt-8 mr-6 mb-7 lg:mt-0">
        <div className="mb-5 font-black lg:mb-0 text-heading-medium lg:text-heading-2xlarge">Caixa de Entrada</div>
        <Button
          kind="ghost"
          label="Limpar tudo"
          disabled={loading || unreadNotifications?.length === 0}
          size="small"
          onClick={() =>
            unreadNotifications &&
            setNotificationAsReadHandler(unreadNotifications?.map((notification) => notification.id))
          }
        />
      </div>
      <div className="flex overflow-y-auto flex-col gap-4 pr-5 w-full h-full">
        <div>
          <p className="mb-5 text-heading-small lg:text-heading-medium">Não Lidas</p>
          <div className="flex flex-col gap-6">
            {!loading ? (
              notifications ? (
                unreadNotifications && unreadNotifications.length > 0 ? (
                  unreadNotifications?.map((notification, index) => (
                    <NotificationItemGenerator key={index} {...notification} />
                  ))
                ) : (
                  <div className="text-paragraph-medium">
                    Você já conferiu todas as atualizações. Assim que tivermos novidades, você será informado por aqui.
                  </div>
                )
              ) : (
                <div className="text-paragraph-medium">
                  Por enquanto não temos nenhuma novidade. Assim que tivermos, você será informado por aqui.
                </div>
              )
            ) : (
              [...Array(2).keys()].map((_, index) => <Skeleton key={index} className="w-full h-52" />)
            )}
          </div>
        </div>
        {notifications && (
          <div>
            <p className="mt-8 mb-5 text-heading-small lg:text-heading-medium">Lidas</p>
            <div className="flex flex-col gap-6 mb-10">
              {!loading ? (
                readNotifications && readNotifications.length > 0 ? (
                  readNotifications.map((notification, index) => {
                    if (notification.read) {
                      return <NotificationItemGenerator key={index} {...notification} />;
                    }
                  })
                ) : (
                  <div className="text-paragraph-medium">Por enquanto não há nenhuma notificação lida.</div>
                )
              ) : (
                [...Array(2).keys()].map((_, index) => <Skeleton key={index} className="w-full h-52" />)
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsHub;
