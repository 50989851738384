import { IUnitsReport } from '@contexts/migration-report/types';
import { triggerAmplitudeButtonClickedEvent } from '@services/event-tracking/events-trigger';

export const getConcludedMigrationUnits = (migrationReportByUnits: IUnitsReport[]) => {
  const filteredUnitsByMigrationProgressValue =
    migrationReportByUnits && migrationReportByUnits.filter((item) => item.conclusionPercentage === 1);

  return filteredUnitsByMigrationProgressValue?.length > 0 ? filteredUnitsByMigrationProgressValue : null;
};

export const triggerButtonEvent = (buttonText: string, destinationPath?: string) => {
  return triggerAmplitudeButtonClickedEvent('Migração', buttonText, destinationPath);
};
