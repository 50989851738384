import React from 'react';

import KickOffWelcomeImage from '@assets/kick-off-welcome.png';

interface IKickOffWelcomeHeader {
  commercialGroup?: string;
}

const KickOffWelcomeHeader = ({ commercialGroup }: IKickOffWelcomeHeader) => (
  <div>
    <img className="w-full h-30 rounded-medium" src={KickOffWelcomeImage} alt="Renewable Energy" />
    <h1 className="mt-6 mb-4 w-full font-medium text-left md:mb-1 text-heading-small md:text-heading-large">
      <span className="font-bold">Olá {commercialGroup}!</span> Preparado para definir as características do seu
      contrato de energia?
    </h1>
    <p className="mb-6 font-medium md:mb-10 text-paragraph-small md:text-paragraph-medium">
      <span className="font-bold">
        São três perguntas simples mas super importantes para definirmos o melhor produto
      </span>{' '}
      de energia para a sua realidade. Leia atentamente e responda com honestidade, qualquer dúvida estamos à
      disposição. Lembrando que o não preenchimento desse formulário atrasa a contratação da sua energia!
    </p>
  </div>
);

export default KickOffWelcomeHeader;
