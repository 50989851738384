import React, { useEffect, useState } from 'react';
import { GeneralGadget, PopUpListProps } from '@clarke-energia/foton';
import { useConsumption } from '@hooks/use-consumption';
import { MonthlyControlMeasurementByUnitId, MonthlyGroupControlMeasurement } from '@contexts/consumption';
import PageSectionGenerator from '@components/molecules/general/page-section-generator';
import LineChartComponent from '@components/molecules/charts/line-chart';
import { filterAndOrderDateList } from '@pages/consumption/helper';
import { consumptionTootTipLineChart } from '@pages/consumption/consumption-static-data';
import { formatStringDate } from '@utils/text';
import { IConsumptionSecondPageSection } from './types';

export const ConsumptionSecondPageSection: React.FC<IConsumptionSecondPageSection> = ({
  setLabelDate,
  lineChartLabels,
  lineChartValues,
  labelDate,
}) => {
  const {
    monthlyControlMeasurementByUnitId,
    setConsolidatedMeasurementDocId,
    monthlyGroupControlMeasurement,
    setGroupConsolidatedMeasurementDocId,
    unitId,
  } = useConsumption();
  const [openConsumptionByDaySelector, setOpenConsumptionByDaySelector] = useState<boolean>(false);
  const [consumptionByDay, setConsumptionByDay] = useState<PopUpListProps['items']>();

  function generateUnitConsumptionByDayPopUpList(monthlyControlMeasurement: MonthlyControlMeasurementByUnitId[]) {
    const consumptionByDay = monthlyControlMeasurement?.map((measures) => ({
      label: formatStringDate(measures.date),
      onClick: () => {
        setOpenConsumptionByDaySelector(false);
        setLabelDate(formatStringDate(measures.date));
        setConsolidatedMeasurementDocId(measures.consolidatedMeasurementDocId);
      },
    }));
    setConsumptionByDay(consumptionByDay);
  }

  function generateGroupConsumptionByDayPopUpList(monthlyControlMeasurement: MonthlyGroupControlMeasurement[]) {
    const consumptionByDay = monthlyControlMeasurement?.map((measures) => ({
      label: formatStringDate(measures.date),
      onClick: () => {
        setOpenConsumptionByDaySelector(false);
        setLabelDate(formatStringDate(measures.date));
        setGroupConsolidatedMeasurementDocId(measures.groupConsolidatedMeasurementDocId);
      },
    }));
    setConsumptionByDay(consumptionByDay);
  }

  useEffect(() => {
    if (unitId && monthlyControlMeasurementByUnitId) {
      generateUnitConsumptionByDayPopUpList(filterAndOrderDateList(monthlyControlMeasurementByUnitId));
    } else if (!unitId && monthlyGroupControlMeasurement) {
      generateGroupConsumptionByDayPopUpList(filterAndOrderDateList(monthlyGroupControlMeasurement));
    }
  }, [monthlyControlMeasurementByUnitId, monthlyGroupControlMeasurement, unitId]);
  return (
    <PageSectionGenerator
      className="mb-10"
      title={`Consumo do dia ${labelDate}`}
      buttonIconProps={
        consumptionByDay && {
          openPopupList: openConsumptionByDaySelector,
          setOpenPopupList: () => setOpenConsumptionByDaySelector(!openConsumptionByDaySelector),
        }
      }
      popUpListProps={
        consumptionByDay && {
          items: consumptionByDay,
          show: openConsumptionByDaySelector,
        }
      }
    >
      <div className={`grid grid-cols-1 gap-6 justify-between w-full`}>
        <GeneralGadget
          title="Gráfico hora x consumo em kWh"
          tooltipContent={consumptionTootTipLineChart.content}
          className={'col-span-full xl:col-span-1'}
        >
          {lineChartValues && (
            <LineChartComponent
              chartLabels={lineChartLabels as string[]}
              chartValuesPrimary={lineChartValues as number[]}
              className="w-full"
              suffix="kWh"
            />
          )}
        </GeneralGadget>
      </div>
    </PageSectionGenerator>
  );
};
