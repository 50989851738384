import { useContextSelector } from 'use-context-selector';
import { GroupEconomyContext, GroupEconomyContextType } from '@contexts/group-economy';

export const useGroupEconomy = (): GroupEconomyContextType => {
  const setGroupId = useContextSelector(GroupEconomyContext, (groupEconomy) => groupEconomy.setGroupId);
  const groupId = useContextSelector(GroupEconomyContext, (groupEconomy) => groupEconomy.groupId);
  const groupEconomyReports = useContextSelector(
    GroupEconomyContext,
    (groupEconomy) => groupEconomy.groupEconomyReports,
  );
  const rawGroupEconomyReports = useContextSelector(
    GroupEconomyContext,
    (groupEconomy) => groupEconomy.rawGroupEconomyReports,
  );
  const rawSummaryEconomyReportsHistory = useContextSelector(
    GroupEconomyContext,
    (groupEconomy) => groupEconomy.rawSummaryEconomyReportsHistory,
  );
  const getGroupEconomyReportsHandler = useContextSelector(
    GroupEconomyContext,
    (groupEconomy) => groupEconomy.getGroupEconomyReportsHandler,
  );
  const groupAccumulatedEconomyReport = useContextSelector(
    GroupEconomyContext,
    (groupEconomy) => groupEconomy.groupAccumulatedEconomyReport,
  );
  const unitAccumulatedSummaryReportParsed = useContextSelector(
    GroupEconomyContext,
    (groupEconomy) => groupEconomy.unitAccumulatedSummaryReportParsed,
  );
  const groups = useContextSelector(GroupEconomyContext, (groupEconomy) => groupEconomy.groups);
  const loading = useContextSelector(GroupEconomyContext, (groupEconomy) => groupEconomy.loading);

  return {
    setGroupId,
    getGroupEconomyReportsHandler,
    groupId,
    groupEconomyReports,
    rawGroupEconomyReports,
    rawSummaryEconomyReportsHistory,
    groupAccumulatedEconomyReport,
    unitAccumulatedSummaryReportParsed,
    groups,
    loading,
  };
};
