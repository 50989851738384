import config from '@src/config';
import { amplitudeInit, amplitudeTrackEvent } from './amplitude';

class EventTracker {
  private initialized = false;
  private unitializedError = new Error('"initializeService" was not called.');

  async initializeService() {
    if (this.initialized) throw new Error('Initialization already called!.');

    if (config.IS_IN_PRODUCTION) this.initialized = await amplitudeInit(true);
  }

  async trackEvent(eventLabel: string, eventProperties?: Record<string, string | number>) {
    if (!this.initialized) throw this.unitializedError;

    if (config.IS_IN_PRODUCTION) amplitudeTrackEvent(eventLabel, eventProperties);
  }
}

const eventTracker = new EventTracker();
export default eventTracker;
