import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const UnitMigrationReportSkeleton: React.FC = () => (
  <div className="col-span-full w-full">
    <div className="grid mb-6 w-full md:w-1/3">
      <Skeleton className="rounded-medium" width="100%" height="100%" />
    </div>
    <div className="grid grid-cols-1 grid-rows-3 gap-y-1 mb-8 h-[4rem]">
      {Array(3)
        .fill({ w: '50%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton key={`proposal-skeleton-${index}`} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
    <div className="grid grid-cols-6 gap-6 mb-8 h-[10rem]">
      {Array(5)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton
            className="rounded-medium"
            key={`proposal-skeleton-${index}`}
            width={skeleton.w}
            height={skeleton.h}
          />
        ))}
    </div>
    <div className="grid mb-6 w-full md:w-1/3">
      <Skeleton className="rounded-medium" width="100%" height="100%" />
    </div>
    <div className="grid mb-4 w-full md:w-1/3">
      <Skeleton className="rounded-medium" width="20%" height="100%" />
    </div>
    <div className="grid grid-cols-1 grid-rows-3 gap-y-2 h-[6rem]">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton
            className="rounded-medium"
            key={`proposal-skeleton-${index}`}
            width={skeleton.w}
            height={skeleton.h}
          />
        ))}
    </div>
  </div>
);
