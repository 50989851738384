import '@wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

import eventTracker from '@services/event-tracking';
import '@utils/moment.ts';

import App from './App';
import './index.css';
import './reset.css';

eventTracker.initializeService();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
