import React, { useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { IconCardProps, Layout, PopUpListProps } from '@clarke-energia/foton';
import domtoimage from 'dom-to-image';

import { HOME_PATH, SUSTAINABILITY_PATH } from '@routers/constants';
import { IActionsButtons } from '@pages/economy-reports/helpers';
import { parseSustainabilityReportCharts } from '@contexts/group-economy/parser';
import { useUserInfo } from '@hooks/use-user-info';
import { useGroupEconomy } from '@hooks/use-group-economy';
import { useUnitsEconomy } from '@hooks/use-units-economy';
import { GENERAL_PANEL_PARAMS } from '@utils/constants/common';
import { HomeSkeleton } from '@components/molecules/skeleton/home-skeleton';
import SustainabilityCardsSection from '@components/organisms/sustainability/sustainability-cards';
import SustainabilityBarChartsSection from '@components/organisms/sustainability/sustainability-barcharts';
import {
  GroupAccumulatedEconomyReportByGroupIdParsed,
  ISustainabilityHistoryCharts,
} from '@contexts/group-economy/types';
import leafIcon from '../../assets/leaf.svg';
import treeIcon from '../../assets/tree.svg';
import { sustainabilityChartsInformation, triggerButtonEvent } from './helper';

const SustainabilityHub: React.FC = () => {
  const navigate = useNavigate();
  const { id: unitId } = useParams();
  const exportRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const { user, isFetchingUser } = useUserInfo();
  const {
    setGroupId: setGroupEconomyGroupId,
    groupAccumulatedEconomyReport,
    rawSummaryEconomyReportsHistory: rawGroupSummaryEconomyReportsHistory,
    loading: groupLoading,
  } = useGroupEconomy();
  const {
    setUnitId,
    unitAccumulatedEconomyReport,
    rawSummaryEconomyReportsHistory: rawUnitSummaryEconomyReportsHistory,
    loading: unitLoading,
  } = useUnitsEconomy();

  const [groupHasOnlyOneUnit, setGroupHasOnlyOneUnit] = useState<boolean>(false);
  const [openUnitOrGeneralSelector, setOpenByUnitOrGeneralSelector] = useState<boolean>(false);
  const [unitOrGeneralPopupList, setUnitOrGeneralPopupList] = useState<PopUpListProps['items']>([]);
  const [summaryEconomyHistoryCharts, setSummaryEconomyHistoryCharts] = useState<ISustainabilityHistoryCharts>();

  const generateHeaderButtonsProps = (): IActionsButtons[] => {
    return [
      {
        buttonType: 'buttonInline',
        className: 'px-4 border-neutral-30',
        kind: 'primary',
        label: 'Exportar',
        icon: 'InboxIcon',
        iconPosition: 'left',
        size: 'small',
        onClick: () => {
          exportSustainabilityReportToImg();
          triggerButtonEvent('Exportar');
        },
      },
    ];
  };

  const exportSustainabilityReportToImg = async () => {
    domtoimage
      .toPng(exportRef.current)
      .then((dataUrl: string) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `relatorio-acumulado-${
          unitId === GENERAL_PANEL_PARAMS ? 'Geral' : unitAccumulatedEconomyReport.unitName
        }.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error: string) => {
        // eslint-disable-next-line no-console
        console.error('Error capturing component as image:', error);
      });
  };

  const getSustainabilityIconCardProps = (): IconCardProps[] => {
    const isGeneralReport = unitId === GENERAL_PANEL_PARAMS;
    return [
      {
        alternativeIcon: leafIcon,
        title: 'Toneladas de CO2 evitados',
        label: isGeneralReport
          ? groupAccumulatedEconomyReport.totalCarbonDioxideEmissionAvoided ?? '0'
          : unitAccumulatedEconomyReport.totalCarbonDioxideEmissionAvoided ?? '0',
      },
      {
        alternativeIcon: treeIcon,
        title: 'Equivalente em árvores',
        label: isGeneralReport
          ? groupAccumulatedEconomyReport.totalTreesSaved ?? '0'
          : unitAccumulatedEconomyReport.totalTreesSaved ?? '0',
      },
    ];
  };

  const generatePageSectionButtonsProps = (): any | undefined => {
    if (!groupHasOnlyOneUnit) {
      return {
        openPopupList: openUnitOrGeneralSelector,
        setOpenPopupList: () => setOpenByUnitOrGeneralSelector(!openUnitOrGeneralSelector),
      };
    }
  };

  const generateUnitOrGeneralPopupList = (
    groupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupIdParsed,
  ) => {
    if (groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports) {
      const localUnitOrGeneralPopupList = groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports.map(
        (report) => ({
          label: report.unitName,
          onClick: () => {
            const sustainabilityUnitPath = `${SUSTAINABILITY_PATH}/${report.unitId}`;
            setOpenByUnitOrGeneralSelector(false);
            navigate(sustainabilityUnitPath);
            triggerButtonEvent(report.unitName, sustainabilityUnitPath);
          },
        }),
      );

      const generatePopupListIfGroupsHasMoreThanOneUnit = () => {
        return [
          {
            label: 'Geral',
            onClick: () => {
              const sustainabilityGeneralPath = `${SUSTAINABILITY_PATH}/${GENERAL_PANEL_PARAMS}`;
              setOpenByUnitOrGeneralSelector(false);
              navigate(sustainabilityGeneralPath);
              triggerButtonEvent('Geral', sustainabilityGeneralPath);
            },
          },
          ...localUnitOrGeneralPopupList,
        ];
      };

      setUnitOrGeneralPopupList(
        groupHasOnlyOneUnit ? localUnitOrGeneralPopupList : generatePopupListIfGroupsHasMoreThanOneUnit(),
      );
    }
  };

  useMemo(() => {
    if (user?.group) {
      setGroupEconomyGroupId(user.group.id);
    }
  }, [user]);

  useMemo(() => {
    if (groupAccumulatedEconomyReport) {
      const localGroupHasOnlyOneUnit =
        groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports &&
        groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports.length === 1;
      setGroupHasOnlyOneUnit(localGroupHasOnlyOneUnit);

      generateUnitOrGeneralPopupList(groupAccumulatedEconomyReport);
      setSummaryEconomyHistoryCharts(parseSustainabilityReportCharts(rawGroupSummaryEconomyReportsHistory ?? []));

      if (localGroupHasOnlyOneUnit && unitId === GENERAL_PANEL_PARAMS) {
        const sustainabilityUnitPath = `${SUSTAINABILITY_PATH}/${groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports[0].unitId}`;
        navigate(sustainabilityUnitPath);
      }
    }
  }, [groupAccumulatedEconomyReport]);

  useMemo(() => {
    if (unitId && unitId !== GENERAL_PANEL_PARAMS) {
      setUnitId(unitId);
    }
  }, [unitId]);

  useMemo(() => {
    if (rawUnitSummaryEconomyReportsHistory || rawGroupSummaryEconomyReportsHistory) {
      const isGeneralReport = unitId === GENERAL_PANEL_PARAMS;
      setSummaryEconomyHistoryCharts(
        parseSustainabilityReportCharts(
          isGeneralReport ? rawGroupSummaryEconomyReportsHistory ?? [] : rawUnitSummaryEconomyReportsHistory ?? [],
        ),
      );
    } else {
      setSummaryEconomyHistoryCharts(parseSustainabilityReportCharts(rawGroupSummaryEconomyReportsHistory ?? []));
    }
  }, [rawUnitSummaryEconomyReportsHistory, rawGroupSummaryEconomyReportsHistory]);

  if (isFetchingUser || groupLoading || unitLoading) {
    return (
      <Layout darkerBackground loading>
        <HomeSkeleton />
      </Layout>
    );
  }

  return (
    <div ref={exportRef}>
      <Layout
        title="Sustentabilidade"
        darkerBackground
        navigationHistoryLinks={[
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Sustentabilidade',
            url: SUSTAINABILITY_PATH,
          },
        ]}
        headerButtons={generateHeaderButtonsProps()}
      >
        <div className={'grid grid-cols-2 col-span-full gap-8'}>
          <SustainabilityCardsSection
            title={`Painel ${unitId === GENERAL_PANEL_PARAMS ? 'Geral' : unitAccumulatedEconomyReport.unitName}`}
            buttonIconProps={generatePageSectionButtonsProps()}
            popUpListProps={{
              items: unitOrGeneralPopupList,
              show: openUnitOrGeneralSelector,
            }}
            iconCardProps={getSustainabilityIconCardProps()}
          />
          {summaryEconomyHistoryCharts && (
            <SustainabilityBarChartsSection
              title={'Histórico dos Resultados'}
              summaryEconomyHistoryCharts={summaryEconomyHistoryCharts}
              sustainabilityChartsInformation={sustainabilityChartsInformation}
            />
          )}
        </div>
      </Layout>
    </div>
  );
};

export default SustainabilityHub;
